import {
    SEND_IMAGE_STRING_ARRAY,
    SEND_IMAGE_STRING_ARRAY_ERROR,
    GET_IMAGE_STRING_ARRAY,
    GET_IMAGE_STRING_ARRAY_ERROR
} from "../actions/Types";

//State type for defining the state of the reducer
interface Actions {
    payload: any;
    type: string;
}

//CameraInterface to define the State type for the state of the reducer
interface CameraInterface {
    imageArray: string[];
    videoArray: string[];
    imageArrayError: string;
}

//State type for defining the state of the reducer
export type State = CameraInterface;

//Initial state of the reducer of type State
export const initialState: State = {
    imageArray: [],
    videoArray: [],
    imageArrayError: ""
};

export const CameraReducer = (state: State = initialState, action: Actions) => {
    switch (action.type) {
        case SEND_IMAGE_STRING_ARRAY:
            return {
                ...state,
                cameraDetails: action.payload,
                cameraDetailsError: "",
            };
        case SEND_IMAGE_STRING_ARRAY_ERROR:
            return {
                ...state,
                cameraDetails: [],
                cameraDetailsError: action.payload,
            };
        case GET_IMAGE_STRING_ARRAY:
            return {
                ...state,
                cameraDetails: action.payload,
                cameraDetailsError: "",
            };
        case GET_IMAGE_STRING_ARRAY_ERROR:
            return {
                ...state,
                cameraDetails: [],
                cameraDetailsError: action.payload,
            };
        //return state as it is if action is not of any of the aforementioned types
        default:
            return state;
    }
};