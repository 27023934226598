// Steps to create your account in firebase
// Step 01- Go to console.firebase.google.com
// Step 02 - Create your project and add the sdk in this file

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAjdxhelPCaE2exJC3byiXx1Xuj5LRiKeM",
  authDomain: "bravenstoolimageupload.firebaseapp.com",
  projectId: "bravenstoolimageupload",
  storageBucket: "bravenstoolimageupload.appspot.com",
  messagingSenderId: "911697109306",
  appId: "1:911697109306:web:980efb311ff5b45fbaf003",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
