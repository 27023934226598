//Sachin Dhalani - [20/4/2023] Reducers for Assessments
import {
  ADD_LOCAL_QUESTION_ASSESSMENT,
  CREATE_ASSESSMENT,
  CREATE_ASSESSMENT_ERROR,
  CREATE_ASSESSMENT_QUESTION,
  CREATE_ASSESSMENT_QUESTION_ERROR,
  DELETE_QUESTION_COMPLETED,
  DISPLAY_ASSESSMENT,
  DISPLAY_ASSESSMENT_ERROR,
  DISPLAY_ASSESSMENT_EXAM,
  DISPLAY_ASSESSMENT_EXAM_ERROR,
  DISPLAY_ASSESSMENT_ORG,
  DISPLAY_ASSESSMENT_ORG_ERROR,
  DISPLAY_CANDIDATE_ASSESSMENT_SCORE_ERROR,
  DISPLAY_CANDIDATE_ASSESSMENT_SCORE_SUCCESS,
  DISPLAY_CANDIDATE_BY_STATUS_ERROR,
  DISPLAY_CANDIDATE_BY_STATUS_SUCCESS,
  DISPLAY_CANDIDATE_TRACKER_DETAIL_PAGE_ERROR,
  DISPLAY_CANDIDATE_TRACKER_DETAIL_PAGE_SUCCESS,
  RESET_CREATE_EXAM,
  RESET_CREATE_QUESTION,
  RESET_EXAM_CODE,
  SET_EXAM_CODE,
  SUBMIT_ANSWER_REQUEST_SUCCESS,
  UPDATE_CREATE_EXAM_STEPPER,
  UPDATE_QUESTION_REQUEST_SUCCESS,
  UPDATE_QUESTIOn_INDEX,
} from "../actions/Types";

import { cloneDeep, findIndex } from "lodash";
//AssessmentInterface to define the State type for the state of the reducer
interface AssessmentInterface {
  currentAssessment: any;
  createAssessmentError: string;
  displayAssessment: any;
  displayAssessmentError: string;
  displayAssessmentOrg: any;
  displayAssessmentOrgError: any;
  displayAssessmentExam: any;
  displayAssessmentExamError: any;
  createAssessmentQues: any;
  createAssessmentQuesError: any;
  create_exam_stepper: number;
  examCode: string;
  displayAssessmentScore: any;
  displayAssessmentScoreError: string;
  createCandidateTrackerDetails: any
  createCandidateTrackerDetailsError:string
  displayCandidateTrackerDetails:any
  displayCandidateTrackerDetailsError:""
  displayCandidateCompletedStatus: any;
  displayCandidateCompletedStatusError: string;
}
//State type for defining the state of the reducer
interface Actions {
  payload: any;
  type: string;
}
export type State = AssessmentInterface;
//Initial state of the reducer of type State
export const initialState: State = {
  currentAssessment: undefined,
  createAssessmentError: "",
  displayAssessment: undefined,
  displayAssessmentError: "",
  displayAssessmentOrg: undefined,
  displayAssessmentOrgError: "",
  displayAssessmentExam: undefined,
  displayAssessmentExamError: "",
  createAssessmentQues: undefined,
  createAssessmentQuesError: "",
  create_exam_stepper: 1,
  examCode: "",
  displayAssessmentScore: undefined,
  displayAssessmentScoreError: "",
  createCandidateTrackerDetails:undefined,
  createCandidateTrackerDetailsError:"",
  displayCandidateTrackerDetails:undefined,
  displayCandidateTrackerDetailsError:"",
  displayCandidateCompletedStatus: undefined,
  displayCandidateCompletedStatusError: "",
};

export const AssessmentReduce = (
  state: State = initialState,
  action: Actions
) => {
  switch (action.type) {
    case CREATE_ASSESSMENT:
      return {
        ...state,
        currentAssessment: action.payload,
        createAssessmentError: "",
      };

    case UPDATE_CREATE_EXAM_STEPPER:
      return {
        ...state,
        create_exam_stepper: action.payload,
      };
    case RESET_CREATE_EXAM: {
      return {
        ...state,
        currentAssessment: undefined,
      };
    }
    case RESET_CREATE_QUESTION: {
      return {
        ...state,
        createAssessmentQues: undefined,
      };
    }
    case ADD_LOCAL_QUESTION_ASSESSMENT:
      const assessment = { ...state.currentAssessment };
      assessment["qIndex"] = action.payload;
      if (action.payload === 0) {
        assessment["questions"] = [
          {
            questions: "",
            questionType: "",
            difficultyLevel: "",
            examId: action.payload.id,
            points: "",
            isDefault: true,
            requiredTime: 0,
          },
        ];
      } else {
        assessment["questions"].push({
          questions: "",
          questionType: "",
          difficultyLevel: "",
          examId: action.payload.id,
          points: "",
          isDefault: true,
          requiredTime: 0,
        });
      }
      return {
        ...state,
        currentAssessment: assessment,
        createAssessmentQues: undefined,
      };

    case UPDATE_QUESTIOn_INDEX:
      const assessmentObj: any = cloneDeep(state.currentAssessment);
      assessmentObj.qIndex = action.payload;
      return {
        ...state,
        currentAssessment: assessmentObj,
      };

    case CREATE_ASSESSMENT_ERROR:
      return {
        ...state,
        currentAssessment: [],
        createAssessmentError: action.payload,
      };
    case DISPLAY_ASSESSMENT:
      return {
        ...state,
        displayAssessment: action.payload,
        displayAssessmentError: "",
      };
    case DISPLAY_ASSESSMENT_ERROR:
      return {
        ...state,
        displayAssessment: "",
        displayAssessmentError: action.payload,
      };
    case DISPLAY_ASSESSMENT_ORG:
      return {
        ...state,
        displayAssessmentOrg: action.payload,
        displayAssessmentOrgError: "",
      };
    case DISPLAY_ASSESSMENT_ORG_ERROR:
      return {
        ...state,
        displayAssessmentOrg: [],
        displayAssessmentOrgError: action.payload,
      };
    case DISPLAY_ASSESSMENT_EXAM:
      return {
        ...state,
        displayAssessmentExam: action.payload,
        displayAssessmentExamError: "",
      };

    case SUBMIT_ANSWER_REQUEST_SUCCESS: {
      const assessExam = cloneDeep(state.displayAssessmentExam);
      const questionIndex = findIndex(
        assessExam.exam.questions,
        (obj: any) => obj.id === action.payload.body.questionId
      );


      if (questionIndex !== -1) {
        assessExam.exam.questions[questionIndex]["answer"] =
          action.payload.answer;
      }

      return {
        ...state,
        displayAssessmentExam: assessExam,
        displayAssessmentExamError: "",
      };
    }
    case DISPLAY_ASSESSMENT_EXAM_ERROR:
      return {
        ...state,
        displayAssessmentExam: [],
        displayAssessmentExamError: action.payload,
      };

    case CREATE_ASSESSMENT_QUESTION:
      const assement = cloneDeep(state.currentAssessment);
      assement.questions[assement.qIndex] = action.payload;
      return {
        ...state,
        currentAssessment: assement,
        createAssessmentQues: action.payload,
        createAssessmentQuesError: "",
      };
    case CREATE_ASSESSMENT_QUESTION_ERROR:
      return {
        ...state,
        createAssessmentQues: undefined,
        createAssessmentQuesError: action.payload,
      };
    case DELETE_QUESTION_COMPLETED:
      const currentAssessment = cloneDeep(state.currentAssessment);
      currentAssessment.questions.splice(action.payload.index, 1);
      if (currentAssessment.questions.length > currentAssessment.qIndex) {
        currentAssessment.qIndex = currentAssessment.qIndex + 1;
      } else {
        currentAssessment.qIndex = currentAssessment.qIndex - 1;
      }

      return {
        ...state,
        currentAssessment: currentAssessment,
      };

    case UPDATE_QUESTION_REQUEST_SUCCESS: {
      const currentAssessmentObj = cloneDeep(state.currentAssessment);
      currentAssessmentObj.questions[currentAssessmentObj.qIndex] =
        action.payload;
      return {
        ...state,
        currentAssessment: currentAssessmentObj,
      };
    }
    case SET_EXAM_CODE: {
      return {
        ...state,
        examCode: action.payload,
      };
    }
    case RESET_EXAM_CODE: {
      return {
        ...state,
        examCode: "",
      };
    }

    case DISPLAY_CANDIDATE_ASSESSMENT_SCORE_SUCCESS: {
      return {
        ...state,
        displayAssessmentScore: action.payload,
        displayAssessmentScoreError: "",
      };
    }

    case DISPLAY_CANDIDATE_ASSESSMENT_SCORE_ERROR: {
      return {
        ...state,
        displayAssessmentScore: [],
        displayAssessmentScoreError: action.payload,
      };
    }

    case DISPLAY_CANDIDATE_BY_STATUS_SUCCESS: {
      return {
        ...state,
        displayCandidateCompletedStatus: action.payload,
        displayCandidateCompletedStatusError: "",
      };
    }

    case DISPLAY_CANDIDATE_BY_STATUS_ERROR: {
      return {
        ...state,
        displayCandidateCompletedStatus: [],
        displayCandidateCompletedStatusError: action.payload,
      };
    }
    case DISPLAY_CANDIDATE_TRACKER_DETAIL_PAGE_SUCCESS:{
      return{
        ...state,
        displayCandidateTrackerDetails: action.payload,
        displayCandidateTrackerDetailsError:""
      }
    }
    case DISPLAY_CANDIDATE_TRACKER_DETAIL_PAGE_ERROR:{
      return{
        ...state,
        displayCandidateTrackerDetails: [],
        displayCandidateTrackerDetailsError:action.payload
      }
    }
    default:
      return state;
  }
};
