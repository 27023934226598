//Sachin Dhalani - [] Index page for Create Assessment page
import React, { useEffect, useState } from "react";
import { Stepper, StepLabel, Step } from "@mui/material";
import jwt_decode from "jwt-decode";
import { SecondStep } from "../CreateAssessment/SecondStep";
import { FirstStep } from "../CreateAssessment/FirstStep";
import { ThirdStep } from "../CreateAssessment/ThirdStep";
import "./style.css";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { AdminNavbar } from "../AdminDashboard/Navbar";

export const CreateAssessment: React.FC = () => {
  const token: any = localStorage.getItem("token");
  let decoded: any = jwt_decode(token);
  const assessment = useSelector((state: RootState) => state.assessment);
  const [role, setRole] = useState("");
  useEffect(() => {
    if (decoded) {
      setRole(decoded?.role);
    }
  }, []);
  const showStep = (step: any) => {
    switch (step) {
      case 1:
        return <FirstStep />;
      case 2:
        return <SecondStep />;
      case 3:
        return <ThirdStep />;
    }
  };
  return (
    <div className="create-assessment-index">
      <div className="center-stepper">
        <AdminNavbar />
        <Stepper
          activeStep={assessment.create_exam_stepper - 1}
          orientation="horizontal"
          className="create-assessment-stepper"
        >
          <Step>
            {/* font-family: 'Roboto', sans-serif; */}
            <StepLabel>
              <span className="step-label-span">Create Assessment</span>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <span className="step-label-span">Select Assessment Type</span>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <span className="step-label-span">Add Custom Question</span>
            </StepLabel>
          </Step>
        </Stepper>
      </div>
      {showStep(assessment.create_exam_stepper)}
    </div>
  );
};
