import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";

import { MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import {
  ASSESSMENT_HEADING,
  CREATE_ASSESSMENT_BUTTON,
  NAME,
} from "../Constants/constants";
import { Button } from "../../Common/Button/Button";
import "./style.css";
import { filter } from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Loading } from "../../Common/Loading/Loading";
import { Modal } from "../../Common/Modal/Modal";
import { CreateCandidate } from "./AddCandidate";
interface Props {
  candidates?: any;
}
export const DashboardPage: React.FC<Props> = ({ candidates }) => {
  console.log('candidates: ', candidates);
  const navigate = useNavigate();
  const [target, setTarget] = useState(null);
  const [show, setShow] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState<any>();


  const ref = useRef(null);
  const handleAssessment = () => { };
  const handleAssessmentDetails = async (data: any) => {
    navigate("/test-questions", { state: data });
  };

  const handleMouseEnter = (event: any) => {
    setShow(true);
    setTarget(event.target);
  };
  const handleMouseLeave = (event: any) => {
    setShow(false);
    setTarget(null);
  };

  const isExamEnabled = (exams: any) => {
    if (!exams) {
      return false;
    }
    if (exams.length === 0) {
      return false;
    } else {
      const data = filter(
        exams,
        (obj: any) =>
          obj.status === "NOT_STARTED" || obj.status === "IN_PROGRESS"
      );

      if (data.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleCloseModal = () => {
    setEditModal(false);
    window.location.reload();
  }

  const handleEdit = (result: any) => {
    // alert("update")
    setEditModal(true);
    setEditData(result);
  }

  let my = 0;

  return (
    <div className="dashboard">
      <div className="heading">
        <p className="primary-texts">{ASSESSMENT_HEADING}</p>
        {/* <div className="button-create-assessment">
          <Link to="/create-assessment">
            <Button
              value={CREATE_ASSESSMENT_BUTTON}
              className="create-assessment-btn"
              handleClick={handleAssessment}
            />

            <Button
              value="+"
              className="create-assessment-mobile-btn"
              handleClick={handleAssessment}
            />
          </Link>
        </div> */}
      </div>
      <div>
        <div className="assessment-record">
          {/* {loadingState.loading ? <Loading /> : null} */}
          <div className="recruiter-table">
            <div className="InsideData">
              <table className="table table-bordered table-display-candidate">
                <thead className="display-candidate-thead">
                  <tr >
                    <th scope="col">{NAME}</th>
                    <th scope="col">Email</th>
                    <th scope="col">UserName</th>
                    <th scope="col">Contact</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Action</th>
                    {/* <th scope="col">History</th> */}
                  </tr>
                </thead>
                <tbody>
                  {candidates?.length ? (
                    candidates?.map((result: any, index: any) => (
                      <tr className="display-candidate-tr" key={index}>
                        <td>
                          <p className="fw-normal mb-1">
                            {result?.firstName + " " + result?.lastName}
                          </p>
                        </td>
                        <td>
                          <p className="fw-normal mb-1">{result?.email}</p>
                        </td>
                        <td>{result?.username}</td>
                        <td>{result?.contact}</td>

                        <td>
                          {/* <MDBBtn color="link" rounded size="sm"> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                            onClick={() => handleEdit(result)}
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                          </svg>

                          {/* </MDBBtn> */}
                        </td>
                        <td>
                          <button
                            className="rec-dashboard-send-assessment-btn"
                            onClick={() => handleAssessmentDetails(result)}
                            disabled={isExamEnabled(result.exam)}
                            style={
                              isExamEnabled(result.exam)
                                ? { opacity: 0.3 }
                                : { opacity: 1 }
                            }
                          >
                            Send Assessment
                          </button>
                        </td>

                        {/* <td>
                          <div ref={ref}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              fill="currentColor"
                              className="bi bi-clock-history"
                              viewBox="0 0 16 16"
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                      
                            >
                              <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                              <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                              <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
                            </svg>

                            {result?.exam && (
                              <Overlay
                                show={result?.exam?.length > 0 && show}
                                target={target}
                                placement="left-start"
                                container={ref}
                                containerPadding={20}
                              >
                                <Popover id="popover-contained">
                                  <Popover.Header as="h3">
                                    Exam History
                                  </Popover.Header>

                                  {result?.exam?.map((obj: any, index: number) => (
                                    <Popover.Body key={index}>
                                      <strong>{obj?.exam?.name}</strong> is{" "}
                                
                                      <strong>{obj?.status}</strong>
                                     
                                    </Popover.Body>
                                  ))}
                                </Popover>
                              </Overlay>
                            )}
                          </div>
                        </td> */}
                      </tr>
                    ))
                  ) : (
                    <p className="no-records-found-error-message">
                      No Records Found
                    </p>
                  )}
                </tbody>
              </table>
            </div>
            {editModal ? (
              <Modal
                children={<CreateCandidate
                  editData={editData}
                  isUpdate={true}
                  onEdit={() => setEditModal(false)}
                  onSubmit={() => setEditModal(false)} />}
                showModalHeader={true}
                modalHeader={"Update Candidate"}
                isFlexible={true}
                topRightCloseButtonID={"x-  "}
                showModal={true}
                showBackButton={true}
                showBBPSLogo={true}
                handleBackClick={handleCloseModal}
              ></Modal>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
