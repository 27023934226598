import React from "react";
import { MDBCol, MDBRow, MDBTable, MDBTableBody } from "mdb-react-ui-kit";

interface Props {
  table: any;
}
export const CustomTable: React.FC<Props> = ({ table }) => {
  return (
    <MDBTable className="table-bordered">
      <MDBTableBody>
        {table.map((row: any, rowIndex: any) => (
          <tr key={rowIndex}>
            <td className="left-column">{row[0]}</td>
            <td>
              <MDBRow className="justify-content-end">
                <MDBCol className="align-right">{row[1]}</MDBCol>
              </MDBRow>
            </td>
          </tr>
        ))}
      </MDBTableBody>
    </MDBTable>
  );
};
