import React, { KeyboardEventHandler } from "react";
import "../../styles/text-field.css";

//Props interface. Defines all the required props by the component
interface Props {
  value: string | number | readonly string[] | undefined; //value of the input field
  placeholder: string; //placeholder text
  styles?: object; //additional styles object
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void; //onChange event handler
  label?: string; //Label Text
  type?: string; //Type of input field. By default, text
  className?: string; //Additional CSS classes for the component
  disabled?: boolean;
  required?: boolean;
}
//Common TextField Component
export const TextField: React.FC<Props> = ({
  label,
  placeholder,
  handleChange,
  type,
  className,
  styles,
  value,
  disabled,
  required,
}) => {
  // const handleKeyPress = (event:any) => {
  //   const charCode = event.which || event.keyCode;
  //   // Allow only letters (A-Z, a-z) by checking the charCode
  //   if (
  //     (charCode >= 65 && charCode <= 90) || // A-Z
  //     (charCode >= 97 && charCode <= 122) // a-z
  //   ) {
  //     // Call the onKeyPress prop if it exists and pass the event object
  //     if (onKeyPress) {
  //       onKeyPress(event);
  //     }
  //   } else {
  //     event.preventDefault(); // Prevent input of other characters
  //   }
  // };
  return (
    <div style={{ width: "100%", opacity: disabled ? 0.4 : 1 }}>
      <label className="label-text-filed" htmlFor={"_element"}>
        {required ? <label className="required-text">*</label> : null}
        {label}
      </label>
      <input
        value={value}
        required={required}
        placeholder={placeholder}
        type={type ? type : "text"}
        className={"input " + className}
        style={styles ? styles : {}}
        onChange={handleChange}
        disabled={disabled}
      />
    </div>
  );
};
