import React, { useEffect, useState } from "react";
import { Navbar } from "../RecruitersDashboard/Navbar";
import { Home } from "./Home";
import { useAppDispatch } from "../../hooks/app";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import jwt_decode from "jwt-decode";
import {
  displayCandidate,
  displayCandidateByOrganization,
} from "../../actions/admin";
import { displayAllCandidatesByCompletedStatus } from "../../actions/assessment";
import { AdminNavbar } from "../AdminDashboard/Navbar";
import { useLocation } from "react-router-dom";
export const Candidates: React.FC = () => {
  const [id, setID] = useState<number>();
  const [role, setRole] = useState("");
  const dispatch = useAppDispatch();
  const token: any = localStorage.getItem("token");
  let decoded: any = jwt_decode(token);
  useEffect(() => {
    if (decoded) {
      setRole(decoded?.role);
    }
  }, []);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const admins = useSelector((state: RootState) => state.admin.adminDetails);

  const location = useLocation();
  let recruiterCandidateData = location?.state?.newData;
  console.log("recruiterCandidateData: ", recruiterCandidateData);

  const OrganizationCandidate = useSelector(
    (state: RootState) => state.candidate?.candidateDetailsByOrganization
  );
  console.log("user: ", user);
  console.log("OrganizationCandidate: ", OrganizationCandidate);
  // console.log('admins from candidate score: ', admins);
  // console.log('user from score: ', user);
  const displayAdmins = async () => {
    if (recruiterCandidateData) {
      await dispatch(displayCandidate(recruiterCandidateData?.id));
      setID(recruiterCandidateData?.id);
    } else {
      await dispatch(displayCandidate(user?.id));
      setID(user?.id);
    }
  };
  const displayAdminsByOrganization = async () => {
    await dispatch(displayCandidateByOrganization(user?.orgnizationId));
  };
  const displayCandidateByStatus = async () => {
    await dispatch(displayAllCandidatesByCompletedStatus());
  };
  const candidates = useSelector(
    (state: RootState) =>
      state.assessment.displayCandidateCompletedStatus?.candidateList
  );
  const candidateQuestionAnswer = useSelector(
    (state: RootState) =>
      state.assessment.displayCandidateCompletedStatus?.candidateQuestionAnswer
  );
  console.log("candidateQuestionAnswer: ", candidateQuestionAnswer);
  console.log("candidates from index: ", candidates);
  useEffect(() => {
    displayAdmins();
    displayCandidateByStatus();
    displayAdminsByOrganization();
  }, []);
  return (
    <div>
      {role === "ADMIN" ? <AdminNavbar /> : <Navbar />}
      <Home
        candidates={candidates}
        userId={id}
        OrganizationCandidate={OrganizationCandidate}
        candidateQuestionAnswer={candidateQuestionAnswer}
        role={role}
        recruiterCandidateData={recruiterCandidateData}
      />
    </div>
  );
};
