import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { Button } from "../../Common/Button/Button";
import { useAppDispatch } from "../../hooks/app";
import { displayAllAssessmentByExamIdForAdmin } from "../../actions/assessment";
import java_icon from "../OrganizationTestQuestions/assets/java.png";
import javascript_icon from "../OrganizationTestQuestions/assets/javascript.png";
import python_icon from "../OrganizationTestQuestions/assets/python.png";
import android_icon from "../OrganizationTestQuestions/assets/android.png";
import swift_icon from "../OrganizationTestQuestions/assets/swift.png";
import dotnet_icon from "../OrganizationTestQuestions/assets/dotnet.png";
import claung_icon from "../OrganizationTestQuestions/assets/clang.png";
import react_icon from "../OrganizationTestQuestions/assets/react.png";
import { DropDown } from "../../Common/DropDown/DropDown";
import { examTypes } from "../../constants/constants";
import { Tooltip } from "@material-tailwind/react";
import { PlusIcon } from "@heroicons/react/24/outline";
interface Props {
  sampleTest?: any;
}
export const Home: React.FC<Props> = ({ sampleTest }) => {
  console.log("sampleTest from home: ", sampleTest);
  const [type, setType] = useState("All");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleViewTest = (data: any) => {
    navigate("/display-created-admin-assessment", { state: data });
    dispatch(displayAllAssessmentByExamIdForAdmin(data.id));
  };
  let tempOrgTest = sampleTest?.filter((element: { type: any }) => {
    if (element.type === type) return true;
  });
  sampleTest = sampleTest?.filter((element: { type: any }) => {
    if (element.type === type) {
      return true;
    }
    if (type === "All") {
      return true;
    }
  });
  const getTechnologyInfo = (type: any) => {
    switch (type) {
      case "Java":
        return java_icon;
      case "Javascript":
        return javascript_icon;
      case "ReactJs":
        return react_icon;
      case "Python":
        return python_icon;
      case "Android":
        return android_icon;
      case "Swift":
        return swift_icon;
      case "MySql":
        return claung_icon;
      case "C Launguage":
        return claung_icon;
      case ".Net":
        return dotnet_icon;
      default:
        return "test";
    }
  };
  return (
    <div className="test-questions-home">
      <div className="heading">
        <p className="primary-texts">Bravens Test</p>
      </div>
      <div className="item-container">
        <DropDown
          className="drop-down-menu-main"
          value={type}
          options={examTypes}
          noOptionsText={"No Exam Type found"}
          label="Assessment Type"
          handleChange={(e: any) => {
            setType(e.value);
          }}
          showLabel={true}
        />
        {sampleTest?.length > 0
          ? sampleTest?.map((data: any, index: any) =>
              !data.orgnizationId ? (
                <div className="card" key={index}>
                  <img
                    src={getTechnologyInfo(data.technology)}
                    style={{
                      height: 40,
                      width: 40,
                      display: "flex",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                  <p className="primary-text"> {data.name}</p>
                  <Button
                    value="View"
                    className="select-test-button"
                    handleClick={() => handleViewTest(data)}
                  />
                </div>
              ) : null
            )
          : sampleTest?.map((data: any, index: any) =>
              !data.orgnizationId ? (
                <div className="card" key={index}>
                  <img
                    src={getTechnologyInfo(data.technology)}
                    style={{
                      height: 40,
                      width: 40,
                      display: "flex",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                  <p className="primary-text"> {data.name}</p>
                  <Button
                    value="View"
                    className="select-test-button"
                    handleClick={() => handleViewTest(data)}
                  />
                </div>
              ) : null
            )}
      </div>
      <div
        className="rounded-full bg-[#173380] border border-[#0087cd] p-3 m-4 fixed bottom-3 right-1 mt-10 cursor-pointer"
        onClick={() => {
          navigate("/admin-create-assessment");
        }}
      >
        <Tooltip content="Create Assessment">
          <PlusIcon className="w-8 text-white font-semibold" />
        </Tooltip>
      </div>
    </div>
  );
};
