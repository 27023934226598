import Editor from "@monaco-editor/react";
import React, { useState } from "react";
import { defaultCode } from "./DefaultCode";
import "./style.css";
interface Props {
  onChange?: any;
  language?: any;
  code?: any;
  theme?: any;
}
export const CodeEditorWindow: React.FC<Props> = ({
  onChange,
  language,
  code,
  theme,
}) => {
  const [value, setValue] = useState(code || '');

  const handleEditorChange = (value: any) => {
    setValue(value);
    onChange("code", value);
  };

  return (
    <div className="">
      <Editor
        height="50vh"
        width={`98.5%`}
        language={language || "javascript"}
        value={code}
        className="code-editor"
        theme={theme}
        defaultValue="//Welcome to Bravens Tool!"
        onChange={handleEditorChange}
      />
    </div>
  );
};
