//Sachin Dhalani - [15/3/2023] - Sample question format
import React, { useEffect, useRef, useState } from "react";
import { QuizScreen } from "./QuizScreen";
import { VideoCapture } from "./VideoCapture";

export const Questions: React.FC = () => {
  //Julian DSa - [27/5/2023] - Camera picture capture every 50 seconds
  const [capturedPhotos, setCapturedPhotos] = useState<string[]>([]);
  const [capturedBase64Videos, setCapturedBase64Videos] = useState<string[]>([]);

  return (
    <div>
      <>
        <QuizScreen capturedPhotos={capturedPhotos} capturedBase64Videos={capturedBase64Videos} />
        <VideoCapture capturedPhotos={capturedPhotos} setCapturedPhotos={setCapturedPhotos} 
        capturedBase64Videos={capturedBase64Videos} setCapturedBase64Videos={setCapturedBase64Videos}/>
      </>
    </div>
  );
};

