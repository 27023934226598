//Sachin Dhalani -To create a new quiz for assessment
import React, { useContext, useEffect, useState } from "react";
//Internal imports
import { Button } from "../../Common/Button/Button";
import { TextArea } from "../../Common/TextArea/TextArea";
import { MultiStepContext } from "../../context/MultiStepContext";
import { isTextValid } from "../../helpers/validate";
import "./third_step.css";
import { DropDown } from "../../Common/DropDown/DropDown";
import { Button as MaterialButton } from "@material-tailwind/react";

import {
  approximateTime,
  difficultyLevels,
  getQuestionTypeCode,
  getQuestionTypeLocalCode,
  questionPoints,
  questionTypes,
} from "../../constants/constants";
import checkbox_selected from "./assets/checkbox_selected.png";
import checkbox_noselected from "./assets/checkbox-nonselected.png";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  addLocalQuestion,
  addQuestions,
  deleteQuestion,
  deleteQuestionLocally,
  updateQuestion,
  updateQuestionIndex,
  displayAllAssessmentByExamId,
  resetCreatedQuestion,
  resetCreateExam,
} from "../../actions/assessment";
import { useAppDispatch } from "../../hooks/app";
import Swal from "sweetalert2";
import { filter } from "lodash";
import { useNavigate } from "react-router-dom";
import { Modal } from "../../Common/Modal/Modal";
export const ThirdStep: React.FC = () => {
  const dispatch = useAppDispatch();
  const assessment = useSelector((state: RootState) => state.assessment);
  const navigate = useNavigate();
  // displayAssessmentExam
  //questionError variable in the state and setQuestionError method for updating the token value
  const [questionError, setQuestionError] = useState<string>("");
  //option1Error variable in the state and setOption1Error method for updating the token value
  const [option1Error, setOption1Error] = useState<string>("");
  //option2Error variable in the state and setOption2Error method for updating the token value
  const [option2Error, setOption2Error] = useState<string>("");
  //option3Error variable in the state and setOption3Error method for updating the token value
  const [option3Error, setOption3Error] = useState<string>("");
  //option4Error variable in the state and setOption4Error method for updating the token value
  const [option4Error, setOption4Error] = useState<string>("");
  const [AnswerError, setAnswerError] = useState<string>("");
  //calling context to store & get values of text filed globally
  const context: any = useContext(MultiStepContext);
  //to submit data in backend
  const submitData = context?.submitData;
  //Set steps to update the step value in context
  const setStep = context?.setStep;
  //To get user data in context
  const userData = context?.userData;
  //To set user data in context
  const setUserData = context?.setUserData;
  //Submits data on button click
  const [selectedType, setSelectedType] = useState("");
  //to set and the get the question
  const [question, setQuestion] = useState<any>();
  //to set the option of question
  const admins = useSelector((state: RootState) => state.admin.adminDetails);
  const [option_1, setOption_1] = useState<any>();
  const [option_2, setOption_2] = useState<any>();
  const [option_3, setOption_3] = useState<any>();
  const [option_4, setOption_4] = useState<any>();

  const [testCase_1, setTestCase_1] = useState<any>({
    input: null,
    output: null,
  });
  const [testCase_2, setTestCase_2] = useState<any>({
    input: null,
    output: null,
  });
  const [testCase_3, setTestCase_3] = useState<any>({
    input: null,
    output: null,
  });
  const [testCase_4, setTestCase_4] = useState<any>({
    input: null,
    output: null,
  });
  const [testCase_5, setTestCase_5] = useState<any>({
    input: null,
    output: null,
  });
  const [testCase_6, setTestCase_6] = useState<any>({
    input: null,
    output: null,
  });
  const [testCase_7, setTestCase_7] = useState<any>({
    input: null,
    output: null,
  });
  const [testCase_8, setTestCase_8] = useState<any>({
    input: null,
    output: null,
  });
  const [testCase_9, setTestCase_9] = useState<any>({
    input: null,
    output: null,
  });
  const [testCase_10, setTestCase_10] = useState<any>({
    input: null,
    output: null,
  });
  const [testCase_11, setTestCase_11] = useState<any>({
    input: null,
    output: null,
  });

  const [sampleInput1, setSampleInput1] = useState<string>();
  const [sampleOutput1, setSampleOutput1] = useState<string>();
  const [sampleInput2, setSampleInput2] = useState<string>();
  const [sampleOutput2, setSampleOutput2] = useState<string>();
  const [sampleInput3, setSampleInput3] = useState<string>();
  const [sampleOutput3, setSampleOutput3] = useState<string>();
  const [sampleInput4, setSampleInput4] = useState<string>();
  const [sampleOutput4, setSampleOutput4] = useState<string>();
  const [sampleInput5, setSampleInput5] = useState<string>();
  const [sampleOutput5, setSampleOutput5] = useState<string>();
  const [sampleInput6, setSampleInput6] = useState<string>();
  const [sampleOutput6, setSampleOutput6] = useState<string>();
  const [sampleInput7, setSampleInput7] = useState<string>();
  const [sampleOutput7, setSampleOutput7] = useState<string>();
  const [sampleInput8, setSampleInput8] = useState<string>();
  const [sampleOutput8, setSampleOutput8] = useState<string>();
  const [sampleInput9, setSampleInput9] = useState<string>();
  const [sampleOutput9, setSampleOutput9] = useState<string>();
  const [sampleInput10, setSampleInput10] = useState<string>();
  const [sampleOutput10, setSampleOutput10] = useState<string>();
  const [sampleInput11, setSampleInput11] = useState<string>();
  const [sampleOutput11, setSampleOutput11] = useState<string>();

  const [textAnswer1, settextAnswer1] = useState<string>();

  let testcases = [
    testCase_1,
    testCase_2,
    testCase_3,
    testCase_4,
    // testCase_5,
    // testCase_6,
    // testCase_7,
    // testCase_8,
    // testCase_9,
    // testCase_10,
    // testCase_11,
  ];

  const click = () => {
    
  };

  const [answer, setAnswer] = useState([]);
  const [selectedOption, setSelectedOption] = useState<any>();
  const [selectedCheckBox, setSelectedCheckBox] = useState<any>({
    option_1: false,
    option_2: false,
    option_3: false,
    option_4: false,
  });
  const [qDifficultyLevel, setQDifficultyLevel] = useState("");
  const [qPoints, setQPoints] = useState("");
  const [qAppTime, setQAppTime] = useState("");
  let temp = 0;
  const [selectedTypeError, setSelectedTypeError] = useState("");
  const [qDifficultyLevelError, setQDifficultyLevelError] = useState("");
  const [qPointsError, setQPointsError] = useState("");
  const [examDetailModel, SetExamDetailModel] = useState(false);
  const [questionId, setQuestionId] = useState("");
  const user = useSelector((state: RootState) => state.user.userDetails);
  const redirectDashboard = () => {
    if (user.role === "BRAVEN_TOOL_ADMIN") {
      navigate("/organization-dashboard");
      dispatch(resetCreateExam());
    } else {
      navigate("/admin-dashboard");
      dispatch(resetCreateExam());
    }
  };

  useEffect(() => {}, []);

  useEffect(() => {
    const assObj = assessment.currentAssessment;
    setQDifficultyLevel(assObj.questions[assObj.qIndex].difficultyLevel);
    setQPoints(assObj.questions[assObj.qIndex].points);
    setQPoints(assObj.questions[assObj.qIndex].points);
    const types = [
      "Single Choice Question",
      "Multiple Choice Question",
      "Coding Question",
      "Text Input/Free Text",
    ];
    let typeValue = "";
    if (
      assObj.questions[assObj.qIndex].questionType &&
      !types.includes(assObj.questions[assObj.qIndex].questionType)
    ) {
      typeValue = getQuestionTypeLocalCode(
        assObj.questions[assObj.qIndex].questionType
      );
      setSelectedType(typeValue);
    } else {
      typeValue = assObj.questions[assObj.qIndex].questionType;
      setSelectedType(typeValue);
    }
    setQuestion(assObj.questions[assObj.qIndex].questions);
    setQAppTime(assObj.questions[assObj.qIndex].requiredTime);
    setQuestionId(
      assObj.questions[assObj.qIndex].id
        ? assObj.questions[assObj.qIndex].id
        : ""
    );

    if (
      typeValue === "Single Choice Question" ||
      typeValue === "Multiple Choice Question"
    ) {
      if (assObj.questions[assObj.qIndex].options.length > 0) {
        const option1: any = filter(
          assObj.questions[assObj.qIndex].options,
          (obj: any) => obj.order === 0
        );
        const option2: any = filter(
          assObj.questions[assObj.qIndex].options,
          (obj: any) => obj.order === 1
        );
        const option3: any = filter(
          assObj.questions[assObj.qIndex].options,
          (obj: any) => obj.order === 2
        );
        const option4: any = filter(
          assObj.questions[assObj.qIndex].options,
          (obj: any) => obj.order === 3
        );

        setOption_1(option1.length > 0 ? option1[0].options : "");
        setOption_2(option2.length > 0 ? option2[0].options : "");
        setOption_3(option3.length > 0 ? option3[0].options : "");
        setOption_4(option4.length > 0 ? option4[0].options : "");

        const checkBox = {
          option_1: false,
          option_2: false,
          option_3: false,
          option_4: false,
        };

        if (option1.length > 0 && option1[0].isCorrect) {
          if (typeValue === "Single Choice Question") {
            setSelectedOption("option_1");
          } else {
            checkBox.option_1 = true;
          }
        }

        if (option2.length > 0 && option2[0].isCorrect) {
          if (typeValue === "Single Choice Question") {
            setSelectedOption("option_2");
          } else {
            checkBox.option_2 = true;
          }
        }

        if (option3.length > 0 && option3[0].isCorrect) {
          if (typeValue === "Single Choice Question") {
            setSelectedOption("option_3");
          } else {
            checkBox.option_3 = true;
          }
        }

        if (option4.length > 0 && option4[0].isCorrect) {
          if (typeValue === "Single Choice Question") {
            setSelectedOption("option_4");
          } else {
            checkBox.option_4 = true;
          }
        }

        setSelectedCheckBox(checkBox);
      }
    }
  }, [assessment && assessment.currentAssessment?.qIndex]);

  useEffect(() => {
    if (assessment.createAssessmentQues) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          title: "swal-deny-button",
        },
        buttonsStyling: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Do you want to add next question?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes, Add new question ",
          denyButtonText: `No, Complete Test `,
          customClass: {
            denyButton: "swal-deny-button",
          },
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          setSelectedCheckBox({
            option_1: false,
            option_2: false,
            option_3: false,
            option_4: false,
          });
          setSelectedOption("");
          setOption_1("");
          setOption_2("");
          setOption_3("");
          setOption_4("");
          setOption1Error("");
          setOption2Error("");
          setOption3Error("");
          setOption4Error("");
          setQuestionError("");
          setQDifficultyLevelError("");
          setQPointsError("");
          setSelectedTypeError("");
          dispatch(resetCreatedQuestion());
          if (result.isConfirmed) {
            dispatch(addLocalQuestion(assessment.currentAssessment.qIndex + 1));
          } else if (result.isDenied) {
            submitTest();
          }
        });
    }
  }, [assessment.createAssessmentQues]);

  const getTotalInfo = (questions: any) => {
    let points = 0;
    let totalDuration = 0;
    let EASY = 0;
    let MODERATE = 0;
    let CHALLENGING = 0;
    let HARD = 0;
    for (let i = 0; i < questions.length; i++) {
      points = questions[i].points ? points + parseInt(questions[i].points) : 0;
      totalDuration = questions[i].requiredTime
        ? totalDuration + parseInt(questions[i].requiredTime)
        : 0;
      //totalDuration = totalDuration + question[i].totalDuration;
      if (
        questions[i].difficultyLevel &&
        questions[i].difficultyLevel === "EASY"
      ) {
        EASY = EASY + 1;
      } else if (
        questions[i].difficultyLevel &&
        questions[i].difficultyLevel === "MODERATE"
      ) {
        MODERATE = MODERATE + 1;
      } else if (
        questions[i].difficultyLevel &&
        questions[i].difficultyLevel === "CHALLENGING"
      ) {
        CHALLENGING = CHALLENGING + 1;
      } else if (
        questions[i].difficultyLevel &&
        questions[i].difficultyLevel === "HARD"
      ) {
        HARD = HARD + 1;
      }
    }
    return { points, totalDuration, EASY, MODERATE, CHALLENGING, HARD };
  };
  const handleSubmit = () => {
    //check if question & options fields are valid
    const questionValid = isTextValid(question);
    const option1Valid = isTextValid(option_1);
    
    const option2Valid = isTextValid(option_2);
    
    const option3Valid = isTextValid(option_3);
    
    const option4Valid = isTextValid(option_4);
    
    const questTypeValid = isTextValid(selectedType);
    
    const questionDifficultyLevel = isTextValid(qDifficultyLevel);
    
    const questionPointsValid = isTextValid(qPoints);
    

    //if question & options are null set error as null and navigates to submit data
    if (
      (questTypeValid &&
        questionDifficultyLevel &&
        questionPointsValid &&
        questionValid &&
        option1Valid &&
        option2Valid &&
        option3Valid &&
        option4Valid) ||
      (questTypeValid &&
        questionDifficultyLevel &&
        questionPointsValid &&
        questionValid &&
        sampleInput1 &&
        sampleInput2 &&
        sampleInput3 &&
        sampleInput4) ||
      (questTypeValid &&
        questionDifficultyLevel &&
        questionPointsValid &&
        questionValid &&
        textAnswer1)
    ) {
      const body: any = {
        questions: question,
        questionType: getQuestionTypeCode(selectedType),
        difficultyLevel: qDifficultyLevel,
        examId: assessment.currentAssessment.id,
        points: qPoints,
        requiredTime: qAppTime ? parseInt(qAppTime) : null,
        isDefault: true,
      };

      if (selectedType === "Single Choice Question") {
        body["options"] = [
          {
            options: option_1,
            order: 0,
            isCorrect: selectedOption === "option_1" ? true : false,
          },
          {
            options: option_2,
            order: 1,
            isCorrect: selectedOption === "option_2" ? true : false,
          },
          {
            options: option_3,
            order: 2,
            isCorrect: selectedOption === "option_3" ? true : false,
          },
          {
            options: option_4,
            order: 3,
            isCorrect: selectedOption === "option_4" ? true : false,
          },
        ];
      } else if (selectedType === "Multiple Choice Question") {
        body["options"] = [
          {
            options: option_1,
            order: 0,
            isCorrect: selectedCheckBox.option_1,
          },
          {
            options: option_2,
            order: 1,
            isCorrect: selectedCheckBox.option_2,
          },
          {
            options: option_3,
            order: 2,
            isCorrect: selectedCheckBox.option_3,
          },
          {
            options: option_4,
            order: 3,
            isCorrect: selectedCheckBox.option_4,
          },
        ];
      } else if (selectedType == "Coding Question") {
        body["options"] = [
          {
            testCaseInput: sampleInput1,
            testCaseOutput: sampleOutput1,
            order: 0,
            isCorrect: true,
          },

          {
            testCaseInput: sampleInput2,
            testCaseOutput: sampleOutput2,
            order: 1,
            isCorrect: true,
          },

          // {
          //   testCaseInput: sampleInput3,
          //   testCaseOutput: sampleOutput3,
          //   order: 2,
          //   isCorrect: true,
          // },

          // {
          //   testCaseInput: sampleInput4,
          //   testCaseOutput: sampleOutput4,
          //   order: 3,
          //   isCorrect: true,
          // },

          // {
          //   testCaseInput: sampleInput5,
          //   testCaseOutput: sampleOutput5,
          //   order: 4,
          //   isCorrect: true,
          // },

          // {
          //   testCaseInput: sampleInput6,
          //   testCaseOutput: sampleOutput6,
          //   order: 5,
          //   isCorrect: true,
          // },

          // {
          //   testCaseInput: sampleInput7,
          //   testCaseOutput: sampleOutput7,
          //   order: 6,
          //   isCorrect: true,
          // },

          // {
          //   testCaseInput: sampleInput8,
          //   testCaseOutput: sampleOutput8,
          //   order: 7,
          //   isCorrect: true,
          // },

          // {
          //   testCaseInput: sampleInput9,
          //   testCaseOutput: sampleOutput9,
          //   order: 8,
          //   isCorrect: true,
          // },

          // {
          //   testCaseInput: sampleInput10,
          //   testCaseOutput: sampleOutput10,
          //   order: 9,
          //   isCorrect: true,
          // },

          // {
          //   testCaseInput: sampleInput11,
          //   testCaseOutput: sampleOutput11,
          //   order: 10,
          //   isCorrect: true,
          // },
        ];
      } else if (selectedType === "Text Input/Free Text") {
        body["options"] = [
          {
            order: 0,
            isCorrect: true,
            sampleAnswer: textAnswer1,
          },
        ];
        // if (
        //   questTypeValid &&
        //   questionDifficultyLevel &&
        //   questionPointsValid &&
        //   questionValid &&
        //   textAnswer1
        // ) {
        //   const body: any = {
        //     questions: question,
        //     questionType: getQuestionTypeCode(selectedType),
        //     difficultyLevel: qDifficultyLevel,
        //     examId: assessment.currentAssessment.id,
        //     points: qPoints,
        //     requiredTime: qAppTime ? parseInt(qAppTime) : null,
        //     isDefault: true,
        //     sampleAnswer: { textAnswer1 },
        //   };
        //   if (
        //     assessment.currentAssessment.questions[
        //       assessment.currentAssessment.qIndex
        //     ].id
        //   ) {
        //     body["id"] =
        //       assessment.currentAssessment.questions[
        //         assessment.currentAssessment.qIndex
        //       ].id;
        //     dispatch(updateQuestion(body));
        //   } else {
        //     dispatch(addQuestions(body));
        //   }
        // }
      }
      if (
        assessment.currentAssessment.questions[
          assessment.currentAssessment.qIndex
        ].id
      ) {
        body["id"] =
          assessment.currentAssessment.questions[
            assessment.currentAssessment.qIndex
          ].id;
        dispatch(updateQuestion(body));
      } else {
        dispatch(addQuestions(body));
      }
    }

    //else set error if fields are null
    else {
      if (!questTypeValid) {
        setSelectedTypeError("Please Select the type of question");
      }

      if (!questionDifficultyLevel) {
        setQDifficultyLevelError("Please select the Difficulty Level");
      }

      if (!questionPointsValid) {
        setQPointsError("Please select the Points for this question");
      }

      if (!questionValid) {
        setQuestionError("Question name is required");
      }

      if (!option1Valid) {
        setOption1Error("Options can't be null");
      }

      if (!option2Valid) {
        setOption2Error("Options can't be null");
      }

      if (!option3Valid) {
        setOption3Error("Options can't be null");
      }

      if (!option4Valid) {
        setOption4Error("Options can't be null");
      }

      if (!answer) {
        setAnswerError("please select answer");
      }
    }
  };
  //function to navigate to second/previous step
  const handlePreviousStep = () => {
    if (assessment.currentAssessment?.qIndex > 0) {
      dispatch(updateQuestionIndex(assessment.currentAssessment?.qIndex - 1));
    }
  };

  const handleNexStep = () => {
    if (
      assessment.currentAssessment?.qIndex + 1 <
      assessment.currentAssessment.questions.length
    ) {
      dispatch(updateQuestionIndex(assessment.currentAssessment?.qIndex + 1));
    }
  };

  const handleChange = (e: any) => {
    setSelectedOption(e.target.value);
    setAnswer(selectedOption);
  };

  const onCheckboxClick = (value: string) => {
    const checkbox = { ...selectedCheckBox };
    checkbox[value] = !selectedCheckBox[value];
    setSelectedCheckBox(checkbox);
  };

  const submitTest = () => {
    dispatch(displayAllAssessmentByExamId(assessment.currentAssessment.id));
    SetExamDetailModel(true);
  };

  const onDeleteQuestion = () => {
    if (assessment.currentAssessment.questions.length > 1) {
      Swal.fire({
        title: "Are you sure? You want to delete this question",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Yes, Delete It ",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (
            !assessment.currentAssessment.questions[
              assessment.currentAssessment.qIndex
            ].id
          ) {
            dispatch(
              deleteQuestionLocally(assessment.currentAssessment.qIndex)
            );
          } else {
            dispatch(
              deleteQuestion(
                assessment.currentAssessment.questions[
                  assessment.currentAssessment.qIndex
                ].id,
                assessment.currentAssessment.qIndex
              )
            );
          }

          // dispatch(addLocalQuestion(assessment.currentAssessment.qIndex + 1));
        } else if (result.isDenied) {
        }
      });
    }
  };

  const totalInfo = getTotalInfo(
    assessment.currentAssessment && assessment.currentAssessment.questions
      ? assessment.currentAssessment.questions
      : []
  );
  // assessment.displayAssessmentExam.questions[0].points
  return (
    <div className="add-question-box">
      <p className="add-question-heading">Please select question meta info</p>
      <div className="third-step-main">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className="thirdstep-dropdown">
            <DropDown
              className="selected-option-menu-thirdstep"
              label={"Plese select Question Type"}
              options={questionTypes}
              noOptionsText={"Please select question type"}
              value={selectedType}
              handleChange={(e: any) => {
                setUserData({ ...userData, selectedType: e.value });
                setSelectedType(e.value);
              }}
              showLabel={true}
            />
            <p className="error-message">{selectedTypeError}</p>
          </div>
          <div style={{ width: 40 }} />

          <div className="thirdstep-dropdown">
            <DropDown
              className="selected-option-menu-thirdstep"
              label={"Difficulty Level"}
              options={difficultyLevels}
              noOptionsText={"Please select question type"}
              value={qDifficultyLevel}
              handleChange={(e: any) => {
                setUserData({ ...userData });
                setQDifficultyLevel(e.value);
              }}
              showLabel={true}
            />
            <p className="error-message">{qDifficultyLevelError}</p>
          </div>
          <div style={{ width: 40 }} />

          <div className="thirdstep-dropdown">
            <DropDown
              className="selected-option-menu-thirdstep"
              label={"Points/Time"}
              options={questionPoints}
              noOptionsText={"Please select question type"}
              value={qPoints}
              handleChange={(e: any) => {
                setUserData({ ...userData });
                setQPoints(e.value);
              }}
              showLabel={true}
            />
            <p className="error-message">{qPointsError}</p>
          </div>
          <div style={{ width: 40 }} />

          <div className="thirdstep-dropdown">
            <DropDown
              className="selected-option-menu-thirdstep"
              label={"Required time"}
              options={approximateTime}
              noOptionsText={"Please select question type"}
              value={qAppTime}
              handleChange={(e: any) => {
                setUserData({ ...userData });
                setQAppTime(e.value);
              }}
              showLabel={true}
            />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            backgroundColor: "grey",
            height: 1,
            marginTop: 10,
          }}
        />
        <p className="add-question-info-heading">Please fill question info</p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: 360,
          }}
        >
          <div
            style={{
              width: "70%",
              paddingRight: 10,
              display: "flex",
            }}
          >
            <TextArea
              value={question}
              placeholder="Enter Question here"
              className="third-step-enter-question"
              handleChange={(e: any) => {
                setQuestion(e.target.value);
              }}
            />
          </div>
          {/* Display Error Message if question field is empty */}
          <div style={{ width: "30%", height: "100%" }}>
            {/* radio button to select if option is correct */}
            {selectedType ? (
              <>
                {selectedType === "Single Choice Question" ||
                selectedType === "Multiple Choice Question" ? (
                  <div>
                    <div className="radio-btns">
                      {/* This radio button for option 1 */}

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          flex: 1,
                        }}
                      >
                        {selectedType === "Single Choice Question" ? (
                          <input
                            id="1"
                            type="radio"
                            value="option_1"
                            checked={selectedOption === "option_1"}
                            onChange={handleChange}
                            style={{
                              width: 30,
                              height: 30,
                              backgroundColor: "pink",
                              marginRight: 16,
                            }}
                          ></input>
                        ) : (
                          <div
                            style={{
                              width: 32,
                              height: 32,
                              marginRight: 16,
                            }}
                            onClick={() => onCheckboxClick("option_1")}
                          >
                            <img
                              src={
                                selectedCheckBox.option_1
                                  ? checkbox_selected
                                  : checkbox_noselected
                              }
                              alt={"checkbox"}
                            ></img>
                          </div>
                        )}
                        <TextArea
                          value={option_1}
                          placeholder="Option 1"
                          className="third-step-enter-answer"
                          handleChange={(e: any) => {
                            setOption_1(e.target.value);
                          }}
                        />
                      </div>
                      <p className="error-message-create-assessment">
                        {option1Error}
                      </p>
                    </div>
                    {/* Display Error Message if option field is empty */}

                    {/* radio button to select if option is correct */}
                    {/* This radio button for option 2 */}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flex: 1,
                      }}
                    >
                      {selectedType === "Single Choice Question" ? (
                        <input
                          type="radio"
                          value="option_2"
                          checked={selectedOption === "option_2"}
                          onChange={handleChange}
                          style={{
                            width: 30,
                            height: 30,
                            marginRight: 16,
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            width: 32,
                            height: 32,
                            marginRight: 16,
                          }}
                          onClick={() => onCheckboxClick("option_2")}
                        >
                          <img
                            src={
                              selectedCheckBox.option_2
                                ? checkbox_selected
                                : checkbox_noselected
                            }
                            alt={"checkbox"}
                          ></img>
                        </div>
                      )}
                      {/* text area to store option 2 */}
                      <TextArea
                        value={option_2}
                        placeholder="Option 2"
                        className="third-step-enter-answer"
                        handleChange={(e: any) => {
                          setOption_2(e.target.value);
                        }}
                        disabled={!selectedType}
                      />
                    </div>
                    {/* Display Error Message if option field is empty */}
                    <p className="error-message-create-assessment">
                      {option2Error}
                    </p>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flex: 1,
                      }}
                    >
                      {selectedType === "Single Choice Question" ? (
                        <input
                          type="radio"
                          value="option_3"
                          checked={selectedOption === "option_3"}
                          onChange={handleChange}
                          style={{
                            width: 30,
                            height: 30,
                            backgroundColor: "pink",
                            marginRight: 16,
                          }}
                        ></input>
                      ) : (
                        <div
                          style={{
                            width: 32,
                            height: 32,
                            marginRight: 16,
                          }}
                          onClick={() => onCheckboxClick("option_3")}
                        >
                          <img
                            src={
                              selectedCheckBox.option_3
                                ? checkbox_selected
                                : checkbox_noselected
                            }
                            alt={"checkbox"}
                          ></img>
                        </div>
                      )}
                      {/* text area to store option 3 */}
                      <TextArea
                        value={option_3}
                        placeholder="Option 3"
                        className="third-step-enter-answer"
                        handleChange={(e: any) => {
                          setOption_3(e.target.value);
                        }}
                        disabled={!selectedType}
                      />
                    </div>

                    <p className="error-message-create-assessment">
                      {option3Error}
                    </p>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flex: 1,
                      }}
                    >
                      {selectedType === "Single Choice Question" ? (
                        <input
                          type="radio"
                          checked={selectedOption === "option_4"}
                          value="option_4"
                          onChange={handleChange}
                          style={{
                            width: 30,
                            height: 30,
                            backgroundColor: "pink",
                            marginRight: 16,
                          }}
                        ></input>
                      ) : (
                        <div
                          style={{
                            width: 32,
                            height: 32,
                            marginRight: 16,
                          }}
                          onClick={() => onCheckboxClick("option_4")}
                        >
                          <img
                            src={
                              selectedCheckBox.option_4
                                ? checkbox_selected
                                : checkbox_noselected
                            }
                            alt={"checkbox"}
                          ></img>
                        </div>
                      )}

                      <TextArea
                        value={option_4}
                        placeholder="Option 4"
                        className="third-step-enter-answer"
                        handleChange={(e: any) => {
                          setOption_4(e.target.value);
                        }}
                        disabled={!selectedType}
                      />
                    </div>

                    <p className="error-message-create-assessment">
                      {option4Error}
                    </p>
                  </div>
                ) : selectedType === "Coding Question" ? (
                  <div
                    style={{
                      scrollBehavior: "smooth",
                      overflowY: "scroll",
                      height: "45vh",
                    }}
                  >
                    <h5>Test Case 1</h5>
                    <TextArea
                      styles={{ height: "5vh" }}
                      value={sampleInput1}
                      placeholder="sample input"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleInput1(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />
                    <TextArea
                      styles={{ marginBottom: "5px", height: "5vh" }}
                      value={sampleOutput1}
                      placeholder="sample output"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleOutput1(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />

                    <h5>Test Case 2</h5>
                    <TextArea
                      styles={{ height: "5vh" }}
                      value={sampleInput2}
                      placeholder="sample input"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleInput2(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />
                    <TextArea
                      styles={{ marginBottom: "5px", height: "5vh" }}
                      value={sampleOutput2}
                      placeholder="sample output"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleOutput2(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />

                    <h5>Test Case 3</h5>
                    <TextArea
                      styles={{ height: "5vh" }}
                      value={sampleInput3}
                      placeholder="sample input"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleInput3(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />
                    <TextArea
                      styles={{ marginBottom: "5px", height: "5vh" }}
                      value={sampleOutput3}
                      placeholder="sample output"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleOutput3(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />

                    <h5>Test Case 4</h5>
                    <TextArea
                      styles={{ height: "5vh" }}
                      value={sampleInput4}
                      placeholder="sample input"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleInput4(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />
                    <TextArea
                      styles={{ marginBottom: "5px", height: "5vh" }}
                      value={sampleOutput4}
                      placeholder="sample output"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleOutput4(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />

                    {/* <h5>Test Case 5</h5>
                    <TextArea
                      styles={{ height: "5vh" }}
                      value={sampleInput5}
                      placeholder="sample input"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleInput5(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />
                    <TextArea
                      styles={{ marginBottom: "5px", height: "5vh" }}
                      value={sampleOutput5}
                      placeholder="sample output"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleOutput5(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />

                    <h5>Test Case 6</h5>
                    <TextArea
                      styles={{ height: "5vh" }}
                      value={sampleInput6}
                      placeholder="sample input"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleInput6(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />
                    <TextArea
                      styles={{ marginBottom: "5px", height: "5vh" }}
                      value={sampleOutput6}
                      placeholder="sample output"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleOutput6(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />

                    <h5>Test Case 7</h5>
                    <TextArea
                      styles={{ height: "5vh" }}
                      value={sampleInput7}
                      placeholder="sample input"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleInput7(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />
                    <TextArea
                      styles={{ marginBottom: "5px", height: "5vh" }}
                      value={sampleOutput7}
                      placeholder="sample output"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleOutput7(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />

                    <h5>Test Case 8</h5>
                    <TextArea
                      styles={{ height: "5vh" }}
                      value={sampleInput8}
                      placeholder="sample input"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleInput8(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />
                    <TextArea
                      styles={{ marginBottom: "5px", height: "5vh" }}
                      value={sampleOutput8}
                      placeholder="sample output"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleOutput8(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />

                    <h5>Test Case 9</h5>
                    <TextArea
                      styles={{ height: "5vh" }}
                      value={sampleInput9}
                      placeholder="sample input"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleInput9(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />
                    <TextArea
                      styles={{ marginBottom: "5px", height: "5vh" }}
                      value={sampleOutput9}
                      placeholder="sample output"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleOutput9(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />

                    <h5>Test Case 10</h5>
                    <TextArea
                      styles={{ height: "5vh" }}
                      value={sampleInput10}
                      placeholder="sample input"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleInput10(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />
                    <TextArea
                      styles={{ marginBottom: "5px", height: "5vh" }}
                      value={sampleOutput10}
                      placeholder="sample output"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleOutput10(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />

                    <h5>Test Case 11</h5>
                    <TextArea
                      styles={{ height: "5vh" }}
                      value={sampleInput11}
                      placeholder="sample input"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleInput11(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />
                    <TextArea
                      styles={{ marginBottom: "5px", height: "5vh" }}
                      value={sampleOutput11}
                      placeholder="sample output"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        setSampleOutput11(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    /> */}
                  </div>
                ) : (
                  <>
                    <h5>Sample Answer:</h5>
                    <TextArea
                      styles={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        height: "20vh",
                      }}
                      value={textAnswer1}
                      placeholder="Sample Answer"
                      className="third-step-enter-answer"
                      handleChange={(e: any) => {
                        settextAnswer1(e.target.value);
                        click();
                      }}
                      disabled={!selectedType}
                    />
                  </>
                )}
              </>
            ) : (
              <div
                style={{
                  fontFamily: "Roboto",
                  fontSize: 24,
                  fontWeight: "800",
                  opacity: 0.7,
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  height: 360,
                  marginLeft: 10,
                  borderStyle: "solid",
                  borderWidth: 1,
                  padding: 16,
                }}
              >
                <label>
                  Please select question types to start adding new questions
                </label>
              </div>
            )}
          </div>
        </div>
        <p className="error-message">{questionError}</p>
        {/* Button to navigate to previous Step */}
        <div className="third-tep-buttons">
          <div style={{ width: 432 }}>
            <button
              id={"b_element"}
              style={{
                height: 50,
                width: 200,
                borderWidth: 0,
                borderRadius: 5,
                backgroundColor: "#94041a",
                color: "#ffff",
                fontFamily: "Roboto",
                fontWeight: "700",
                fontSize: 18,
                cursor: "pointer",
                letterSpacing: 0.3,
                opacity:
                  assessment.currentAssessment &&
                  assessment.currentAssessment.questions &&
                  assessment.currentAssessment.questions.length === 1
                    ? 0.5
                    : 1,
              }}
              onClick={() => onDeleteQuestion()}
              disabled={
                assessment.currentAssessment &&
                assessment.currentAssessment.questions &&
                assessment.currentAssessment.questions.length === 1
              }
            >
              Delete Question
            </button>
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <button
              id={"b_element"}
              style={{
                height: 50,
                width: 200,
                borderWidth: 0,
                borderRadius: 5,
                backgroundColor: "#1f7ebc",
                color: "#ffff",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: 18,
                cursor: "pointer",
                letterSpacing: 0.3,
                opacity: assessment.currentAssessment.qIndex === 0 ? 0.5 : 1,
              }}
              onClick={() => handlePreviousStep()}
              disabled={assessment.currentAssessment.qIndex === 0}
            >
              Previous
            </button>

            <div style={{ width: 32 }} />
            {/* Button to store data in database */}
            <button
              id={"b_element"}
              style={{
                height: 50,
                width: 200,
                borderWidth: 0,
                borderRadius: 5,
                backgroundColor: "#1f7ebc",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: 18,
                color: "#ffff",
                letterSpacing: 0.3,
                marginBottom: 40,
                cursor: "pointer",
                opacity:
                  assessment.currentAssessment &&
                  assessment.currentAssessment.questions &&
                  assessment.currentAssessment.questions.length === 1 &&
                  !assessment.currentAssessment.questions[0].id &&
                  assessment.currentAssessment.qIndex ===
                    assessment.currentAssessment.questions.length - 1
                    ? 0.5
                    : 1,
              }}
              onClick={() =>
                assessment.currentAssessment.questions.length === 1 &&
                assessment.currentAssessment.questions[0].id
                  ? dispatch(
                      addLocalQuestion(assessment.currentAssessment.qIndex + 1)
                    )
                  : handleNexStep()
              }
              disabled={
                assessment.currentAssessment.questions.length === 1 &&
                !assessment.currentAssessment.questions[0].id &&
                assessment.currentAssessment.qIndex ===
                  assessment.currentAssessment.questions.length - 1
              }
            >
              {assessment.currentAssessment.questions.length === 1 &&
              assessment.currentAssessment.questions[0].id
                ? "Add New Question"
                : "Next"}
            </button>
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <button
              id={"b_element"}
              style={{
                height: 50,
                width: 200,
                borderWidth: 0,
                borderRadius: 5,
                // backgroundColor: "#6e757c",
                backgroundColor: "#009900",
                color: "white",
                fontFamily: "Roboto",
                fontWeight: "700",
                fontSize: 18,
                letterSpacing: 0.3,
              }}
              onClick={() => handleSubmit()}
            >
              {assessment.currentAssessment.questions[
                assessment.currentAssessment.qIndex
              ].id
                ? "Update Question"
                : "Submit Question"}
            </button>

            <div style={{ width: 32 }} />
            <button
              id={"b_element"}
              style={{
                height: 50,
                width: 200,
                borderWidth: 0,
                borderRadius: 5,
                // backgroundColor: "#6e757c",
                backgroundColor: "#009900",
                color: "white",
                fontFamily: "Roboto",
                fontWeight: "700",
                fontSize: 18,
                letterSpacing: 0.3,
              }}
              onClick={() => submitTest()}
            >
              Submit Test
            </button>
          </div>
        </div>
      </div>

      {examDetailModel && assessment.displayAssessmentExam ? (
        <Modal
          children={
            <>
              <div className="test-question-model-details">
                <h3 style={{ fontFamily: "Roboto", opacity: 0.7 }}>
                  Exam Details
                </h3>
                <div className="test-question-details-div">
                  <div className="test-question-details-label">
                    <label className="exam-details-label-text">
                      Exam Name:
                    </label>
                    <label className="exam-details-value-text">
                      {assessment.displayAssessmentExam.name}
                    </label>
                  </div>
                  <div className="test-question-details-label">
                    <label className="exam-details-label-text">
                      Exam Type:
                    </label>
                    <label className="exam-details-value-text">
                      {assessment.displayAssessmentExam.type}
                    </label>
                  </div>
                </div>
                <div className="test-question-details-div">
                  <div className="test-question-details-label">
                    <label className="exam-details-label-text">
                      Technology:
                    </label>
                    <label className="exam-details-value-text">
                      {assessment.displayAssessmentExam.technology}
                    </label>
                  </div>
                  <div className="test-question-details-label">
                    <label className="exam-details-label-text">Category:</label>
                    <label className="exam-details-value-text">
                      {assessment.displayAssessmentExam.category}
                    </label>
                  </div>
                </div>
                <div className="test-question-details-div">
                  <div className="test-question-details-label">
                    <label className="exam-details-label-text">
                      Required Time:
                    </label>
                    <label className="exam-details-value-text">
                      {assessment.displayAssessmentExam.requiredTime}
                    </label>
                  </div>
                  <div className="test-question-details-label">
                    <label className="exam-details-label-text">
                      Difficulty:
                    </label>
                    <label className="exam-details-value-text">
                      {assessment.displayAssessmentExam.difficultyLevel}
                    </label>
                  </div>
                </div>

                <div
                  style={{
                    height: 2,
                    width: "100%",
                    backgroundColor: "gray",
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                />
                <h3 style={{ fontFamily: "Roboto", opacity: 0.7 }}>
                  Question Details
                </h3>

                <div className="test-question-details-div">
                  <div className="test-question-details-label">
                    <label className="exam-details-label-text">
                      Total Points:
                    </label>
                    <label className="exam-details-value-text">
                      {" "}
                      {totalInfo.points}
                    </label>
                  </div>
                </div>
                <div className="test-question-details-div">
                  <div className="test-question-details-label">
                    <label className="exam-details-label-text">
                      Total Approximate Duration:
                    </label>
                    <label className="exam-details-value-text">
                      {" "}
                      {totalInfo.totalDuration}
                    </label>
                  </div>
                </div>

                <div className="test-question-details-div">
                  <div className="test-question-details-label">
                    <label className="exam-details-label-text">
                      Easy Questions:
                    </label>
                    <label className="exam-details-value-text">
                      {" "}
                      {totalInfo.EASY}
                    </label>
                  </div>
                </div>

                <div className="test-question-details-div">
                  <div className="test-question-details-label">
                    <label className="exam-details-label-text">
                      Moderate Questions:
                    </label>
                    <label className="exam-details-value-text">
                      {" "}
                      {totalInfo.MODERATE}
                    </label>
                  </div>
                </div>

                <div className="test-question-details-div">
                  <div className="test-question-details-label">
                    <label className="exam-details-label-text">
                      Challenging Questions:
                    </label>
                    <label className="exam-details-value-text">
                      {" "}
                      {totalInfo.CHALLENGING}
                    </label>
                  </div>
                </div>

                <div className="test-question-details-div">
                  <div className="test-question-details-label">
                    <label className="exam-details-label-text">
                      Hard Questions:
                    </label>
                    <label className="exam-details-value-text">
                      {" "}
                      {totalInfo.HARD}
                    </label>
                  </div>
                </div>

                <div style={{ width: 20 }} />

                <Button
                  className="org-test-question-done"
                  value="Done"
                  handleClick={redirectDashboard}
                />

                <Button
                  className="org-test-question-cancel"
                  value="Cancel"
                  handleClick={() => SetExamDetailModel(false)}
                />
              </div>
            </>
          }
          // showModalHeader={true}
          modalHeader={"Assessment Details"}
          isFlexible={true}
          // topRightCloseButtonID={"x-  "}
          showModal={true}
          // showBackButton={true}
          // showBBPSLogo={true}
          handleBackClick={() => SetExamDetailModel(false)}
        ></Modal>
      ) : null}
    </div>
  );
};
