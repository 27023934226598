import { createContext, useState } from "react";

export const MultiStepContext = createContext({});

interface Props {
  children: React.ReactNode;
}

const StepProvider: React.FC<Props> = ({ children }) => {
  const [currentStep, setStep] = useState(1);
  const [userData, setUserData] = useState("");
  const [finalData, setFinalData] = useState([]);
  const submitData = () => {
    setFinalData((finalData) => [...finalData, userData] as any);
    setUserData("");
    setStep(1);
  };
  return (
    <MultiStepContext.Provider
      value={{
        currentStep,
        setStep,
        userData,
        setUserData,
        finalData,
        setFinalData,
        submitData,
      }}
    >
      {children}
    </MultiStepContext.Provider>
  );
};
export default StepProvider;
