import React, { useEffect, useState } from "react";
import { Navbar } from "../RecruitersDashboard/Navbar";
import { Home } from "./Home";
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks/app";
import { RootState } from "../../redux/store";
import { displayAllAssessments, displayAllOrganizationAssessments } from "../../actions/assessment";
import { AdminNavbar } from "../AdminDashboard/Navbar";
export const OrganizationTestQuestions: React.FC = () => {
  const [role, setRole] = useState("");
  const dispatch = useAppDispatch();
  const orgTest = useSelector(
    (state: RootState) => state.assessment.displayAssessmentOrg
    );
    const sampleTest = useSelector(
      (state: RootState) => state.assessment.displayAssessment
      );
    const token: any = localStorage.getItem("token");
    let decoded: any = jwt_decode(token);
    useEffect(() => {
      if (decoded) {
        setRole(decoded?.role);
        console.log('orgTest: ', orgTest);
    }
  }, []);
  const displayAdmins = async () => {
    await dispatch(
      displayAllOrganizationAssessments(decoded?.organization?.id)
    );
  };
  useEffect(() => {
    displayAdmins();
    dispatch(displayAllAssessments());
  }, []);

  return (
    <div>
      {role==="ADMIN"?<AdminNavbar/>:<Navbar/>}
      {/* <Navbar /> */}
      <Home orgTest={orgTest}
      sampleTest = {sampleTest}
      />
    </div>
  );
};
