//Sachin Dhalani - [15/3/2023] - Sample question format
import { Tooltip } from "@mui/material";
import React from "react";
interface Props {
  questionList: any;
  currentIndex: number;
  selectedOption: any;
  onClickItem: any;
}
export const ProgressStepper: React.FC<Props> = ({
  questionList,
  currentIndex,
  selectedOption,
  onClickItem,
}: Props) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {questionList.map((obj: any, index: number) => (
          <>
            {index === currentIndex ? (
              <Tooltip title={obj.questions} placement="bottom" key={index}>
                <div
                  style={{
                    height: 35,
                    width: 35,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#00A36C",
                    borderRadius: 17.5,
                    borderWidth: 2,
                    borderColor: "#b6c4ca",
                    borderStyle: "solid",
                    fontFamily: "Roboto",
                    fontWeight: "800",
                    color: "white",
                  }}
                  onClick={() => onClickItem(index)}
                >
                  {index + 1}
                </div>
              </Tooltip>
            ) : (
              <Tooltip title={obj.questions} placement="bottom">
                <div
                  style={{
                    height: 35,
                    width: 35,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#b6c4ca",
                    borderRadius: 17.5,
                    borderWidth: 1,
                    borderStyle: "solid",
                    fontFamily: "Roboto",
                    fontWeight: "600",
                  }}
                  onClick={() => onClickItem(index)}
                >
                  {index + 1}
                </div>
              </Tooltip>
            )}

            {index < questionList.length - 1 && (
              <div
                style={{
                  height: 1,
                  flex: 1,
                  display: "flex",
                  backgroundColor: "green",
                  // overflowX: "auto",
                }}
              />
            )}
          </>
        ))}
        {/* <div
          style={{
            height: 50,
            width: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "red",
            borderRadius: 25,
          }}
        ></div>

        <div
          style={{
            height: 1,
            flex: 1,
            display: "flex",
            backgroundColor: "green",
            // overflowX: "auto",
          }}
        />

        <div
          style={{
            height: 50,
            width: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "red",
            borderRadius: 25,
          }}
        ></div>

        <div
          style={{
            height: 1,
            flex: 1,
            display: "flex",
            backgroundColor: "green",
            // overflowX: "auto",
          }}
        />

        <div
          style={{
            height: 50,
            width: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "red",
            borderRadius: 25,
          }}
        ></div> */}
      </div>
    </div>
  );
};
