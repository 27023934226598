//Sachin Dhalani - [9/3/2023] - Index page for Landing page

import React, { useContext, useEffect, useState } from "react";
import { BsPersonCircle, BsPower } from "react-icons/bs";
import { HiOutlineBars3 } from "react-icons/hi2";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import "../Navbar/navbar.css";
import { logout } from "../../actions/user";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useAppDispatch } from "../../hooks/app";
import { Home } from "./Home";
import { About } from "./About";
import { Work } from "./Work";
import { Testimonial } from "./Testimonial";
import { Footer } from "./Footer";

interface DashboardProps {
  title?: string;
}

const LandingScreen: React.FC<DashboardProps> = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const dispatch = useAppDispatch();

  //Method to handle logout operation
  const handleLogout = () => {
    //Calls the logout action creator with the dispatch for userReducer
    dispatch(logout());
  };

  const menuOptions = [
    {
      text: "Home",
      icon: <HomeIcon />,
    },
    {
      text: "About",
      icon: <InfoIcon />,
    },
    {
      text: "Testimonials",
      icon: <CommentRoundedIcon />,
    },
    {
      text: "Contact",
      icon: <PhoneRoundedIcon />,
    },
    {
      text: "Account",
      icon: <AccountCircleOutlinedIcon />,
    },
  ];

  return (
    <>
      {/* -----------------------navbar-------------------------------- */}

      <nav className="nav-underline-container">
        <div className="nav-logo-container">
          <h2 className="hiring-logo">BravensTool</h2>
        </div>
        <div className="navbar-links-container">
          <a className="nav-pages-section" href="#landing-home-sec">
            Home
          </a>
          <a className="nav-pages-section" href="#landing-about-sec">
            About
          </a>
          <a className="nav-pages-section" href="#landing-testimonials-sec">
            Testimonials
          </a>
          <a className="nav-pages-section" href="#landing-contact-sec">
            Contact
          </a>
          <a href="/demo">Demo</a>
          <a className="nav-last-section" href="/upgrade">
            Upgrade
          </a>
          {/* If token is set in headers display Logout icon */}

          {/* <Home></Home> */}

          {user ? (
            <a
              className="logout-navbar-option  nav-last-section"
              onClick={handleLogout}
            >
              {/* <BsPower className="navbar-account-icon" /> */}Logout
            </a>
          ) : (
            <Link to="/sign-in">
              {/* <BsPersonCircle className="navbar-account-icon nav-last-section" /> */}
              Login
            </Link>
          )}
        </div>
        <div className="navbar-menu-container">
          <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
        </div>
        <Drawer
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          anchor="right"
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={() => setOpenMenu(false)}
            onKeyDown={() => setOpenMenu(false)}
          >
            <List>
              {menuOptions.map((item, index) => (
                <ListItem key={item.text} disablePadding>
                  {index === 0 ? (
                    <ListItemButton>
                      <a href="#landing-home-sec">
                        <ListItemIcon>{item.icon}</ListItemIcon>
                      </a>
                      <ListItemText primary={item.text} />
                    </ListItemButton>
                  ) : null}
                  {index === 1 ? (
                    <ListItemButton>
                      <a href="#landing-about-sec">
                        <ListItemIcon>{item.icon}</ListItemIcon>
                      </a>
                      <ListItemText primary={item.text} />
                    </ListItemButton>
                  ) : null}
                  {index === 2 ? (
                    <ListItemButton>
                      <a href="#landing-testimonials-sec">
                        <ListItemIcon>{item.icon}</ListItemIcon>
                      </a>
                      <ListItemText primary={item.text} />
                    </ListItemButton>
                  ) : null}
                  {index === 3 ? (
                    <ListItemButton>
                      <a href="#landing-contact-sec">
                        <ListItemIcon>{item.icon}</ListItemIcon>
                      </a>
                      <ListItemText primary={item.text} />
                    </ListItemButton>
                  ) : null}
                  {index === 4 ? (
                    <ListItemButton>
                      {user ? (
                        <a
                          className="logout-navbar-option"
                          onClick={handleLogout}
                          style={{ display: "flex", textDecoration: "none" }}
                        >
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <ListItemText primary={"Logout"} />
                        </a>
                      ) : (
                        <Link
                          to="/sign-in"
                          style={{
                            display: "flex",
                            textDecoration: "none",
                            color: "black",
                          }}
                        >
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <ListItemText primary={"Login"} />
                        </Link>
                      )}
                    </ListItemButton>
                  ) : null}
                </ListItem>
              ))}
            </List>
            <Divider />
          </Box>
        </Drawer>
      </nav>

      {/* -----------------------Home-------------------------------- */}

      <section className="landing-home-sec" id="landing-home-sec">
        <Home />
      </section>

      {/* -----------------------About-------------------------------- */}

      <section className="landing-about-sec" id="landing-about-sec">
        <About />
      </section>

      {/* -----------------------work-------------------------------- */}

      <section className="landing-work-sec" id="landing-work-sec">
        <Work />
      </section>

      {/* -----------------------testimonials-------------------------------- */}

      <section
        className="landing-testimonials-sec"
        id="landing-testimonials-sec"
      >
        <Testimonial />
      </section>

      {/* -----------------------contact-------------------------------- */}

      <section className="landing-contact-sec" id="landing-contact-sec">
        <Footer />
      </section>
    </>
  );
};

export default LandingScreen;
