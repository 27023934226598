import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import jwt_decode from "jwt-decode";
import { Navbar } from "../RecruitersDashboard/Navbar";
import { AdminNavbar } from "../AdminDashboard/Navbar";
import { DropDown } from "../../Common/DropDown/DropDown";
import { Typography } from "@material-tailwind/react";
import { NoSymbolIcon } from "@heroicons/react/24/outline";
import { Button } from "../../Common/Button/Button";

interface Props {}
export const DisplayCreatedAssessment: React.FC<Props> = () => {
  const assessment = useSelector((state: RootState) => state.assessment);
  console.log("assessment from display create assesment : ", assessment);

  const [role, setRole] = useState("");
  const token: any = localStorage.getItem("token");
  let decoded: any = jwt_decode(token);
  useEffect(() => {
    if (decoded) {
      setRole(decoded?.role);
    }
  }, []);
  console.log(
    "assessment?.displayAssessmentExam?.questions: ",
    assessment?.displayAssessmentExam?.questions
  );
  return (
    <div className="row">
      {role === "ADMIN" ? <AdminNavbar /> : <Navbar />}
      {assessment?.displayAssessmentExam?.questions.length > 0 ? (
        <div className="display-created-assessment">
          <p className="created-assessment-heading">
            {assessment.displayAssessmentExam?.name}
          </p>

          <div className="col-sm-12 table-parent-div">
            <table className="table table-bordered table-display-candidate">
              {assessment?.displayAssessmentExam?.questions[0]?.questionType ===
              "CODE" ? (
                <>
                  <thead className="display-candidate-thead">
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Questions</th>
                      <th scope="col">Type</th>
                      <th scope="col">Difficulty Level</th>
                      <th scope="col">Points</th>
                      <th scope="col">Test Case Input</th>
                      <th scope="col">Test Case Output</th>
                      <th scope="col">Time</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {assessment?.displayAssessmentExam?.questions ? (
                      assessment?.displayAssessmentExam?.questions.map(
                        (question: any, index: any) =>
                          question.questionType === "CODE" ? (
                            <tr className="display-candidate-tr" key={index}>
                              <th scope="row">{index + 1}</th>
                              <td className="text-overflow: ellipsis overflow-scroll">
                                {question.questions}
                              </td>
                              <td>{question.questionType}</td>
                              <td>{question.difficultyLevel}</td>
                              <td>{question.points}</td>
                              <td className="text-overflow: ellipsis overflow-scroll">
                                {question.options[0]?.testCaseInput}
                              </td>
                              <td className="text-overflow: ellipsis overflow-scroll">
                                {question.options[0]?.testCaseOutput}
                              </td>
                              <td>
                                <span className="">
                                  {question.requiredTime} min
                                </span>
                              </td>
                            </tr>
                          ) : null
                      )
                    ) : (
                      <tr>
                        <td>No questions Found.</td>
                      </tr>
                    )}
                  </tbody>
                </>
              ) : null}
              {/* )} */}

              {/* For single choice table */}
              {assessment?.displayAssessmentExam?.questions[0].questionType ===
                "SINGLE_CHOICE" ||
              assessment?.displayAssessmentExam?.questions[0].questionType ===
                "MULTIPLE_CHOICE" ? (
                <>
                  <thead className="display-candidate-thead">
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Questions</th>
                      <th scope="col">Type</th>
                      <th scope="col">Difficulty</th>
                      <th scope="col">Points</th>
                      <th scope="col">A</th>
                      <th scope="col">B</th>
                      <th scope="col">C</th>
                      <th scope="col">D</th>
                      <th scope="col">Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assessment?.displayAssessmentExam?.questions ? (
                      assessment?.displayAssessmentExam?.questions.map(
                        (question: any, index: any) =>
                          question.questionType === "SINGLE_CHOICE" ||
                          question.questionType === "MULTIPLE_CHOICE" ? (
                            <tr className="display-candidate-tr" key={index}>
                              <th scope="row">{index + 1}</th>
                              <td className="text-overflow: ellipsis overflow-scroll">
                                {question.questions}
                              </td>
                              <td>{question.questionType}</td>
                              <td>{question.difficultyLevel}</td>
                              <td>{question.points}</td>
                              <td
                                className="text-overflow: ellipsis overflow-scroll"
                                style={{
                                  color: question.options[0].isCorrect
                                    ? "green"
                                    : "black",
                                  fontWeight: question.options[0].isCorrect
                                    ? "bold"
                                    : "normal",
                                  fontFamily: "Roboto",
                                }}
                              >
                                {question.options[0].options}
                              </td>
                              <td
                                className="text-overflow: ellipsis overflow-scroll"
                                style={{
                                  color: question.options[1].isCorrect
                                    ? "green"
                                    : "black",
                                  fontWeight: question.options[1].isCorrect
                                    ? "bold"
                                    : "normal",
                                  fontFamily: "Roboto",
                                }}
                              >
                                {question.options[1].options}
                              </td>
                              <td
                                className="text-overflow: ellipsis overflow-scroll"
                                style={{
                                  color: question.options[2].isCorrect
                                    ? "green"
                                    : "black",
                                  fontWeight: question.options[2].isCorrect
                                    ? "bold"
                                    : "normal",
                                  fontFamily: "Roboto",
                                }}
                              >
                                {question.options[2].options}
                              </td>
                              <td
                                className="text-overflow: ellipsis overflow-scroll"
                                style={{
                                  color: question.options[3].isCorrect
                                    ? "green"
                                    : "black",
                                  fontWeight: question.options[3].isCorrect
                                    ? "bold"
                                    : "normal",
                                  fontFamily: "Roboto",
                                }}
                              >
                                {question.options[3].options}
                              </td>
                              <td>
                                <span className="">
                                  {question.requiredTime} min
                                </span>
                              </td>
                            </tr>
                          ) : null
                      )
                    ) : (
                      <tr>
                        <td>No questions Found.</td>
                      </tr>
                    )}
                  </tbody>
                </>
              ) : null}
              {/* )} */}
              {/* For text Area model */}
              {assessment?.displayAssessmentExam?.questions[0].questionType ===
              "TEXT_AREA" ? (
                <>
                  <thead className="display-candidate-thead">
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Questions</th>
                      <th scope="col">Type</th>
                      <th scope="col">Difficulty</th>
                      <th scope="col">Points</th>
                      <th scope="col">Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assessment?.displayAssessmentExam?.questions ? (
                      assessment?.displayAssessmentExam?.questions.map(
                        (question: any, index: any) =>
                          question.questionType === "TEXT_AREA" ? (
                            <tr className="display-candidate-tr" key={index}>
                              <th scope="row">{index + 1}</th>
                              <td className="text-overflow: ellipsis overflow-scroll">
                                {question.questions}
                              </td>
                              <td>{question.questionType}</td>
                              <td>{question.difficultyLevel}</td>
                              <td>{question.points}</td>
                              <td>
                                <span className="">
                                  {question.requiredTime} min
                                </span>
                              </td>
                            </tr>
                          ) : null
                      )
                    ) : (
                      <tr>
                        <td>No questions Found.</td>
                      </tr>
                    )}
                  </tbody>
                </>
              ) : null}
            </table>
          </div>
        </div>
      ) : (
        <div className="w-full h-[62vh] flex flex-row justify-center items-center">
          {/* <NoSymbolIcon
            strokeWidth={4}
            className="h-5 w-5 mr-4 fill-blue-gray-100"
            color={"fill-blue-gray-100"}
          /> */}
          <Typography className="text-2xl text-blue-gray-100 font-semibold">
            No Questions Found
          </Typography>
        </div>
      )}
      <div>
        <Button
          className="test-question-back-btn"
          value="Back"
          // className="bg-blue-gray-50"
          handleClick={() => window.history.back()}
        />
      </div>
    </div>
  );
};
