//Sachin Dhalani - [13/4/2023] - Action for organization
import axios from "axios";
import { API_URL } from "./serverConnection";
import { AppDispatch, RootState } from "../redux/store";

import {
  ADD_ADMIN,
  ADD_ADMIN_ERROR,
  FETCH_ADMIN,
  FETCH_ADMIN_ERROR,
  FETCH_CANDIDATE_FROM_ORGANIZATION,
  FETCH_CANDIDATE_FROM_ORGANIZATION_ERROR,
  START_LOADING,
  STOP_LOADING,
  UPDATE_RECRUITERS,
  UPDATE_RECRUITERS_ERROR,
} from "./Types";
import Swal from "sweetalert2";

//To fetch list of organization by id where role is ADMIN
export const displayOrgAdmin =
  (orgId: number) => async (dispatch: AppDispatch) => {
    try {
      const token: any = localStorage.getItem("token");
      const result = await axios.get(`${API_URL}/user/org/user/${orgId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      dispatch({
        type: FETCH_ADMIN,
        payload: result?.data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_ADMIN_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
    }
  };

//To fetch list of organization by id where role is USER
export const displayAdmin =
  (orgId: number) => async (dispatch: AppDispatch) => {
    try {
      const token: any = localStorage.getItem("token");
      const result = await axios.get(`${API_URL}/user/org-user/${orgId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      dispatch({
        type: FETCH_ADMIN,
        payload: result?.data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_ADMIN_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
    }
  };

//To fetch list of organization by id where role is Candidate
export const displayCandidate =
  (recId: number) => async (dispatch: AppDispatch) => {
    try {
      const result = await axios.get(`${API_URL}/user/can/${recId}`);

      dispatch({
        type: FETCH_ADMIN,
        payload: result?.data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_ADMIN_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
    }
  };

  export const displayCandidateByOrganization =
  (orgnizationId: number) => async (dispatch: AppDispatch) => {
    try {
      const result = await axios.get(`${API_URL}/user/candidate/${orgnizationId}`);

      dispatch({
        type: FETCH_CANDIDATE_FROM_ORGANIZATION,
        payload: result?.data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_CANDIDATE_FROM_ORGANIZATION_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
    }
  };

//To add new organization
export const addUser =
  (
    firstName: string,
    middleName: string,
    lastName: string,
    email: string,
    username: string,
    contact: string,
    birthDate:Date,
    contact_country_code: any,
    role: string,
    orgnizationId?: any,
    recruiter_id?: number,
    nextReq: string = ""
  ) =>
  async (dispatch: AppDispatch, getState: RootState) => {
    const { userDetails } = getState().user;
    try {
      dispatch({ type: START_LOADING });

      const result = await axios.post(`${API_URL}/user`, {
        firstName,
        middleName,
        lastName,
        email,
        username,
        contact,
        birthDate,
        contact_country_code,
        role,
        orgnizationId,
        recruiter_id,
      });
      dispatch({
        type: ADD_ADMIN,
        payload: result?.data,
      });
      Swal.fire({
        icon: "success",
        title: "Success",
        timer: 1500,
      });
      if (nextReq === "candidate") {
        dispatch(displayCandidate(userDetails?.id));
      }
      if (nextReq === "admin") {
        dispatch(displayAdmin(userDetails?.organization?.id));
      }
      dispatch({ type: STOP_LOADING });
    } catch (err) {
      dispatch({
        type: ADD_ADMIN_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
      Swal.fire({
        icon: "error",
        title: "Error",
        text: (err as any).response?.data?.message,
      });
    }
  };

//To update new organization
export const updateUser =
  (
    id: any,
    firstName: string,
    middleName: string,
    lastName: string,
    email: string,
    username: string,
    contact: string,
    birthDate: Date,
    nextReq: string
  ) =>
  async (dispatch: AppDispatch, getState: RootState) => {
    const { userDetails } = getState().user;
    try {
      dispatch({ type: START_LOADING });
      const { token } = getState().user;
      const result = await axios.patch(
        `${API_URL}/user`,
        {
          id,
          firstName,
          middleName,
          lastName,
          email,
          username,
          contact,
          birthDate,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch({
        type: UPDATE_RECRUITERS,
        payload: result?.data,
      });
      dispatch({ type: STOP_LOADING });
      Swal.fire({
        icon: "success",
        title: `Updated Successfully`,
        timer: 1500,
      });
      if (nextReq === "candidate") {
        dispatch(displayCandidate(userDetails?.id));
      }
      if (nextReq === "admin") {
        dispatch(displayAdmin(userDetails?.organization?.id));
      }
    } catch (err) {
      dispatch({
        type: UPDATE_RECRUITERS_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
      Swal.fire({
        icon: "error",
        title: "Error",
        text: (err as any).response?.data?.message,
      });
    }
  };
