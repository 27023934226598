//Sachin Dhalani - [9/3/2023] - testimonial page with customers reviews

import React from "react";
import ProfilePic from "../../Assets/john-doe-image.png";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import {
  SAMPLE_TEXT,
  TESTIMONIAL,
  TESTIMONIAL_HEADING,
} from "../Constants/constants";
import "./style.css";
export const Testimonial: React.FC = () => {
  return (
    <div>
      <div className="work-section-parent">
        <div className="work-section-wrapper">
          <div className="work-section-top">
            <p className="primary-subheading">{TESTIMONIAL}</p>
            <h1 className="primary-heading">{TESTIMONIAL_HEADING}</h1>
            <p className="primary-text">{SAMPLE_TEXT}</p>
          </div>
          <div id="carouselExampleCaptions" className="mydiv carousel slide">
            <div className="carousel-indicators">
              {/* Chetan Patil - [14/3/2023]  - added box shadow and slideshow */}
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="testimonial-section-bottom">
                  <img  src={ProfilePic} alt="" />
                  <p>{SAMPLE_TEXT}</p>
                  <div className="testimonials-stars-container">
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiOutlineStar />
                  </div>
                  <h2>John Doe</h2>
                </div>
              </div>
              <div className="carousel-item">
                <div className="testimonial-section-bottom">
                  <img src={ProfilePic} alt="" />
                  <p>{SAMPLE_TEXT}</p>
                  <div className="testimonials-stars-container">
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiOutlineStar />
                  </div>
                  <h2>John Doe</h2>
                </div>
              </div>
              <div className="carousel-item">
                <div className="testimonial-section-bottom">
                  <img src={ProfilePic} alt="" />
                  <p>{SAMPLE_TEXT}</p>
                  <div className="testimonials-stars-container">
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiOutlineStar />
                  </div>
                  <h2>John Doe</h2>
                </div>
              </div>
            </div>

            {/* following comment is about sliding left right buttons   */}

            {/* <button className="carousel-control-prev my-button1" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
              <span className="carousel-control-prev-icon my-span" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-prev my-button2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
              <span className="carousel-control-next-icon my-span" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};
