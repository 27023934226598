//Sachin Dhalani - [18/4/2023]
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { read, utils, writeFile } from "xlsx";
import {
  updateCreateExamStepper,
  uploadQuestionFile,
} from "../../actions/assessment";
import { Button } from "../../Common/Button/Button";
import { useAppDispatch } from "../../hooks/app";
import { RootState } from "../../redux/store";
import "./style.css";

export const Home: React.FC = () => {
  const [data, setData] = useState([]);
  const [result, setResult] = useState("");
  const [fileObj, setFileObj] = useState("");
  const dispatch = useAppDispatch();
  const assessment = useSelector((state: RootState) => state.assessment);
  const navigate = useNavigate();

  const handleSaveAssessment = () => {
    if (!fileObj) {
      alert("Please select File");
    }

    if (!assessment.currentAssessment) {
      alert("Exam not valid");
    }
    dispatch(uploadQuestionFile(assessment.currentAssessment.id, fileObj));
    navigate("/test-questions");
  };
  const handleImport = ($event: any) => {
    const files: any = $event.target.files;
    if (files.length) {
      const file = files[0];
      setFileObj(file);
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows: any = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          setData(rows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };
  const handleExport = () => {
    const headings = [["Question", "A", "B", "C", "D", "Answer"]];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Report");
    writeFile(wb, "Assessment Report.xlsx");
  };

  return (
    <>
      <div className="row mb-2 mt-5">
        <div className="col-sm-6">
          <div className="row">
            <div className="col-md-6">
              <div className="input-group">
                <div className="custom-file">
                  <input
                    type="file"
                    name="file"
                    className="custom-file-input"
                    id="inputGroupFile"
                    required
                    onChange={handleImport}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    disabled={
                      !assessment.currentAssessment ||
                      (assessment.currentAssessment.questions &&
                        assessment.currentAssessment.questions.length > 0)
                    }
                  />
                  <label className="custom-file-label" htmlFor="inputGroupFile">
                    Choose file
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 table-parent-div">
          <table className="table table-bordered table-display-candidate">
            <thead className="display-candidate-thead">
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Questions</th>
                <th scope="col">Type</th>
                <th scope="col">Difficulty Level</th>
                <th scope="col">Points</th>
                <th scope="col">A</th>
                <th scope="col">B</th>
                <th scope="col">C</th>
                <th scope="col">D</th>
                <th scope="col">Reduired time In Min</th>
              </tr>
            </thead>
            <tbody>
              {data.length ? (
                data.map((question: any, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{question.questions}</td>
                    <td>{question.questionType}</td>
                    <td>{question.difficultyLevel}</td>
                    <td>{question.points}</td>
                    <td
                      style={{
                        color: question.options1_correct ? "green" : "black",
                        fontWeight: question.options1_correct
                          ? "bold"
                          : "normal",
                        fontFamily: "Roboto",
                      }}
                    >
                      {question.options1}
                    </td>
                    <td
                      style={{
                        color: question.options2_correct ? "green" : "black",
                        fontWeight: question.options2_correct
                          ? "bold"
                          : "normal",
                        fontFamily: "Roboto",
                      }}
                    >
                      {question.options2}
                    </td>
                    <td
                      style={{
                        color: question.options3_correct ? "green" : "black",
                        fontWeight: question.options3_correct
                          ? "bold"
                          : "normal",
                        fontFamily: "Roboto",
                      }}
                    >
                      {question.options3}
                    </td>
                    <td
                      style={{
                        color: question.options4_correct ? "green" : "black",
                        fontWeight: question.options4_correct
                          ? "bold"
                          : "normal",
                        fontFamily: "Roboto",
                      }}
                    >
                      {question.options4}
                    </td>
                    <td>
                      <span className="badge bg-warning text-dark">
                        {question.requiredTime}
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No questions Found.</td>
                </tr>
              )}
            </tbody>
          </table>

          {fileObj && (
            <>
              <div>
                <button
                  id={"b_element"}
                  style={{
                    height: 50,
                    width: 200,
                    borderWidth: 0,
                    borderRadius: 5,
                    backgroundColor: "#1f7ebc",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: 18,
                    color: "#ffff",
                    letterSpacing: 0.3,
                    marginBottom: 40,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (
                      assessment.currentAssessment.questions &&
                      assessment.currentAssessment.questions.length > 0
                    ) {
                      navigate(-2);
                      dispatch(updateCreateExamStepper(1));
                    } else {
                      handleSaveAssessment();
                    }
                  }}
                >
                  {assessment.currentAssessment.questions &&
                  assessment.currentAssessment.questions.length > 0
                    ? "Done"
                    : "Upload Question"}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
