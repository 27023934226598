//Sachin Dhalani - [3/14/2023] - Index page for Test taker (person who is taking the assessment)
import React, { useEffect } from "react";
import { TestInstructions } from "./TestInstructions";
import { History } from "history";
import { useAppDispatch } from "../../hooks/app";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { displayCandidatesPreviousAssessments } from "../../actions/candidate";
interface Props {}
export const TestTaker: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const candidateDetails = useSelector(
    (state: RootState) => state.candidate.candidatePreviousAssessment
  );
  useEffect(() => {
    dispatch(displayCandidatesPreviousAssessments());
  }, []);
  return (
    <div className="test-taker-index">
      <TestInstructions />
    </div>
  );
};
