//Sachin Dhalani - [31/3/2023] - Api call for user
import { History, createBrowserHistory } from "history"; //Import History interface
import axios from "axios";
import Swal from "sweetalert2";
import {
  // SIGN_IN,
  SIGN_UP,
  SIGN_IN_ERROR,
  SIGN_UP_ERROR,
  CLEAR_ERRORS,
  SIGN_IN_SUCCESS,
  LOGOUT,
  START_LOADING,
  STOP_LOADING,
  RESET_USER_DETAILS,
} from "./Types";
import { API_URL } from "./serverConnection";
import { AppDispatch } from "../redux/store";

//Action Creator for Sign In
export const signIn = (username: string, password: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });

      const result = await axios.post(`${API_URL}/auth/login`, {
        username,
        password,
      });
      //Get the token from the result
      const { access_token } = result.data;
      localStorage.setItem("token", access_token);
      const { user } = result.data;
      localStorage.setItem("user", JSON.stringify(user));
      dispatch({
        type: SIGN_IN_SUCCESS,
        payload: user,
        token: access_token,
      });

      dispatch({ type: STOP_LOADING });

      //Clear all errors in the reducer
      clearErrors(dispatch);
    } catch (err) {
      //dispatch the error data
      dispatch({
        type: SIGN_IN_ERROR,
        payload: (err as any).response?.data?.message,
      });
      dispatch({ type: STOP_LOADING });
      Swal.fire({
        icon: "error",
        title: "Error",
        text: (err as any).response?.data?.message,
      });
    }
  };
};

//Action Creator for Sign Up
export const signUp =
  (
    firstName: string,
    middleName: string,
    lastName: string,
    email: string,
    username: string,
    password: string,
    role: string,
    contact: any,
    history: History | undefined
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    try {
      //dispatch start loading
      Swal.fire({
        background: "transparent",
      });
      Swal.showLoading();
      //fetch the results from the sign in API
      const result = await axios.post(`${API_URL}/user`, {
        firstName,
        middleName,
        lastName,
        email,
        username,
        password,
        role,
        contact,
      });
      //Stop loading
      //Dispatch the result token with SIGN_IN type action
      dispatch({
        type: SIGN_UP,
        payload: result?.data,
      });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: result?.data?.message,
        timer: 1500,
      });
      //Clear all errors in the reducer
      clearErrors(dispatch);
      const history = createBrowserHistory();
      history?.push(`/sign-in`);
      window.location.reload();
    } catch (err) {
      //dispatch the error data
      dispatch({
        type: SIGN_UP_ERROR,
        payload: (err as any).response?.data?.message,
      });
      Swal.fire({
        icon: "error",
        title: "Error",
        text: (err as any).response?.data?.message,
      });
    }
  };

//Action Creator for Logout
export const logout = () => {
  return async (dispatch: AppDispatch) => {
    //Remove token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    //Dispatch the logout action
    dispatch({ type: LOGOUT });
    
    window.location.reload();
  };
};

//Action creator for clearing the user reducer from errors
export const clearErrors = (dispatch: React.Dispatch<any>) => {
  //Dispatch CLEAR_ERRORS type
  dispatch({
    type: CLEAR_ERRORS,
  });
};

export const setUserDetails = (user: any, token: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: SIGN_IN_SUCCESS,
      payload: user,
      token: token,
    });
  };
};

export const resetUserDetails = (user: any, token: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: RESET_USER_DETAILS,
    });
  };
};
