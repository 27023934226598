import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { setUserDetails } from "../actions/user";
import { useAppDispatch } from "../hooks/app";
import { AdminDashboard } from "../screens/AdminDashboard";
import { CreateAssessment } from "../screens/CreateAssessment";
import LandingScreen from "../screens/Landing";
import LoginScreen from "../screens/Login";
import { SignIn } from "../screens/Login/SignIn";
import OrganizationDashboard from "../screens/OrganizationDashboard";
import { RecruiterDashboard } from "../screens/RecruitersDashboard";

import SettingsScreen from "../screens/Settings";
import { TestTaker } from "../screens/CandidateDashboard";
import { ProtectedRoute } from "./ProtectedRoute";
import { Questions } from "../screens/Questions";
import { ExcelUpload } from "../screens/ExcelUpload";
import { CodeEditor } from "../screens/CodeEditor";
import { Candidates } from "../screens/Candidates";
import { OrganizationTestQuestions } from "../screens/OrganizationTestQuestions";
import { BravensTestQuestions } from "../screens/BravensTestQuestion";
import { CandidateDashboardPage } from "../screens/CandidateDashboard/CandidateDashboardPage";
import { TestError } from "../screens/CandidateDashboard/TestError";
import { DisplayCreatedAssessment } from "../screens/OrganizationTestQuestions/DisplayCreatedAssessment";
import { CandidateScore } from "../screens/Candidates/CandidateScore";
import { AdminCreateAssessment } from "../screens/AdminCreateAssessment";
import { DisplayCreatedAdminAssessment } from "../screens/BravensTestQuestion/DisplayCreatedAdminAssessment";
import { Demo } from "../screens/Landing/Demo";
import { Upgrade } from "../screens/Landing/Upgrade";
import { DownloadScorePdf } from "../screens/Candidates/DownloadScorePdf";
export default function Router() {
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");

    if (user && token) {
      dispatch(setUserDetails(JSON.parse(user), token));
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <div />;
  } else {
    return (
      <Routes>
        <Route path="/" element={<LandingScreen />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-in/:id" element={<SignIn />} />

        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <SettingsScreen title="Login" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/recruiter-dashboard"
          element={
            <ProtectedRoute>
              <RecruiterDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/candidate-dashboard-test-error"
          element={
            <ProtectedRoute>
              <TestError />
            </ProtectedRoute>
          }
        />
        <Route
          path="/candidate-dashboard"
          element={
            <ProtectedRoute>
              <CandidateDashboardPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/display-created-assessment"
          element={
            <ProtectedRoute>
              <DisplayCreatedAssessment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/display-created-admin-assessment"
          element={
            <ProtectedRoute>
              <DisplayCreatedAdminAssessment />
            </ProtectedRoute>
          }
        />

        <Route
          path="/create-assessment"
          element={
            <ProtectedRoute>
              <CreateAssessment />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin-create-assessment"
          element={
            <ProtectedRoute>
              <AdminCreateAssessment />
            </ProtectedRoute>
          }
        />

        <Route
          path="/test-taker"
          element={
            <ProtectedRoute>
              <TestTaker />
            </ProtectedRoute>
          }
        />
        <Route
          path="/test-questions"
          element={
            <ProtectedRoute>
              <OrganizationTestQuestions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-test-questions"
          element={
            <ProtectedRoute>
              <BravensTestQuestions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/questions"
          element={
            <ProtectedRoute>
              <Questions />
            </ProtectedRoute>
          }
        />

        <Route
          path="/organization-dashboard"
          element={
            <ProtectedRoute>
              <OrganizationDashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin-dashboard"
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/candidates"
          element={
            <ProtectedRoute>
              <Candidates />
            </ProtectedRoute>
          }
        />
        <Route
          path="/code-editor"
          element={
            <ProtectedRoute>
              <CodeEditor />
            </ProtectedRoute>
          }
        />
        <Route
          path="/candidate-details"
          element={
            <ProtectedRoute>
              <CandidateScore />
            </ProtectedRoute>
          }
        />
        <Route path="/demo" element={<Demo />} />

        <Route
          path="/file"
          element={
            <ProtectedRoute>
              <ExcelUpload />
            </ProtectedRoute>
          }
        />

        <Route
          path="/downloadPdf"
          element={
            <ProtectedRoute>
              <DownloadScorePdf />
            </ProtectedRoute>
          }
        />

        <Route path="/upgrade" element={<Upgrade />} />
      </Routes>
    );
  }
}
