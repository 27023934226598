import React, { useState } from "react";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import { CustomTable } from "./CustomTable";
import { MDBTable, MDBTableBody, MDBRow, MDBCol } from "mdb-react-ui-kit";
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
interface Props {
  id: number;
  title: string;
  table?: any;
  customTable?: any;
  expanded: any;
  handleChange: any;
}
const CustomAccordion: React.FC<Props> = ({
  id,
  title,
  table,
  customTable,
  expanded,
  handleChange,
}) => {
  const isExpanded = id === expanded;

  const handleAccordionChange = () => {
    handleChange(id);
  };

  return (
    <Accordion expanded={isExpanded} onChange={handleAccordionChange}>
      <AccordionSummary
        aria-controls={`panel-${id}-content`}
        id={`panel-${id}-header`}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {table ? (
          <CustomTable table={table} />
        ) : (
          <>
            {customTable && (
              <MDBTable className="table-bordered">
                <MDBTableBody>
                  {customTable.map((row: any, rowIndex: any) => (
                    <tr key={rowIndex}>
                      <td className="left-column">{row[0]}</td>
                      <td>
                        <MDBRow className="justify-content-end">
                          <MDBCol className="align-right">{row[1]}</MDBCol>
                          <MDBCol className="align-right">{row[2]}</MDBCol>
                        </MDBRow>
                      </td>
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
