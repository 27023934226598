import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "../../Common/Button/Button";
import "./style.css";
import { TextArea } from "../../Common/TextArea/TextArea";
import { useAppDispatch } from "../../hooks/app";
import {
  createCandidateTrackerDetails,
  getExamByCode,
  submitAnswer,
  updateCandidateStatus,
} from "../../actions/assessment";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { QuestionTypes } from "../../constants/constants";
import checkbox_selected from "../CreateAssessment/assets/checkbox_selected.png";
import checkbox_noselected from "../CreateAssessment/assets/checkbox-nonselected.png";
import { ProgressStepper } from "./ProgressStepper";
import ResultImage from "../../Assets/result-gif.gif";
import { sendImageArray } from "../../actions/camera";
import Swal from "sweetalert2";
import axios from "axios";

interface Props {
  capturedPhotos: string[];
  capturedBase64Videos: string[];
}

export const QuizScreen: React.FC<Props> = ({
  capturedPhotos,
  capturedBase64Videos,
}) => {
  
  const handle = useFullScreenHandle();
  const [showResults, setShowResults] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [extraTimeAllowed, setExtraTimeAllowed] = useState(false);
  const [extraTime, setExtraTime] = useState("");
  const [requiredTime, setRequiredTime] = useState("");
  const [selectedOption, setSelectedOption] = useState<any>(-1);
  const [feedback, setFeedback] = useState("");
  const [status, setStatus] = useState("");
  const [selectedCheckBoxOptions, setSelectedCheckBoxOption] = useState<any>(
    []
  );
  const [type, setType] = useState();
  const [textAnswer, setTextAnswer] = useState<string>("");

  // chetan patil [9-6-2023] = following variables are taken for anti cheating monitor and assessment timer

  const [timer, setTimer] = useState<any>(0);
  const [sec, setSec] = useState<any>();
  const [min, setMin] = useState<any>();
  // const webcamRef: any = useRef(null);
  // 
  const [answerBody, setAnswerBody] = useState<any>();

  const Ref = useRef<any>(null);
  const [device, setDevice] = useState<number>();

  let [mouseMovedOutside, setMouseMovedOutside] = useState<boolean>(false);
  let [countMouseMovedOutside, setCountMouseMovedOutside] = useState<any>(0);
  let [switchingTab, setSwitchingTab] = useState<boolean>(false);
  let [countSwitchingTab, setCountSwitchingTab] = useState<any>(0);
  let [fullScreenMode, setFullScreenMode] = useState<boolean>(true);
  let [ipAddress, setIp] = useState<any>();

  //this minutes comes from backend to set test timing.

  const { state } = useLocation();
  const exam_candidate_id = state.trackerData.exam_candidate_id;
  const deviceUsed = state.trackerData.deviceUsed;
  const latitude = state.trackerData.latitude;
  const longitude = state.trackerData.longitude;
  const webCamEnabled = state.trackerData.webCamEnabled;
  // const ipAddress = state.trackerData.ipAddress;

  
  

  const [isTabActive, setIsTabActive] = useState(true);
  // let [count, setCount] = useState<any>(0);
  // let [countOutsideWindow, setCountOutsideWindow] = useState<any>(0);

  const assessmentObj = useSelector((state: RootState) => state.assessment);
  const cameraEnabled =
    assessmentObj?.displayAssessmentExam?.candidateExam?.cameraEnabled;
  // 
  const tracker =
    assessmentObj?.displayAssessmentExam?.candidateExam?.activityTrackerEnabled;
  // 
  // 
  let testInstructionIP = assessmentObj?.iPAdressDetailsSuccess;

  //fetchin Ip address from quizScreen page

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (assessmentObj.examCode) {
      dispatch(getExamByCode(assessmentObj.examCode));
    }
    // alert(window.innerWidth)
  }, []);

  const examDetails = assessmentObj.displayAssessmentExam?.exam;
  const candidateExamDetails =
    assessmentObj?.displayAssessmentExam?.candidateExam;

  const submitQuestionAnswer = () => {
    let body = undefined;
    if (
      examDetails?.questions[currentQuestion].questionType ===
        QuestionTypes.SINGLE_CHOICE &&
      selectedOption &&
      selectedOption !== -1
    ) {
      body = {
        examId: examDetails.id,
        candidateExamId: assessmentObj.displayAssessmentExam?.candidateExam.id,
        questionId: examDetails?.questions[currentQuestion].id,
        questionType: examDetails?.questions[currentQuestion].questionType,
        questionOptionId: [selectedOption],
      };
    } else if (
      examDetails?.questions[currentQuestion].questionType ===
        QuestionTypes.MULTIPLE_CHOICE &&
      selectedCheckBoxOptions.length > 0
    ) {
      body = {
        examId: examDetails.id,
        candidateExamId: assessmentObj.displayAssessmentExam?.candidateExam.id,
        questionId: examDetails?.questions[currentQuestion].id,
        questionType: examDetails?.questions[currentQuestion].questionType,
        questionOptionId: [...selectedCheckBoxOptions],
      };
    } else if (
      examDetails?.questions[currentQuestion].questionType ===
      QuestionTypes.TEXT_AREA
    ) {
      body = {
        examId: examDetails.id,
        candidateExamId: assessmentObj.displayAssessmentExam?.candidateExam.id,
        questionId: examDetails?.questions[currentQuestion].id,
        questionType: examDetails?.questions[currentQuestion].questionType,
        questionOptionId: [0],
        textAnswer: textAnswer,
      };
    }
    if (body) {
      dispatch(submitAnswer(body));
    } else {
      
    }
  };

  const behaviourScoreCalculation = () => {
    const maxScore = 100;
    const fullScreenPoints = tracker && !fullScreenMode ? 5 : 0;
    const webcamPoints = cameraEnabled && !webCamEnabled ? 5 : 0;
    const mousePoints =
      countMouseMovedOutside <= 20 ? countMouseMovedOutside * 1 : 20;
    const tabPoints = countSwitchingTab <= 3 ? countSwitchingTab * 10 : 30;
    let behaviorScore =
      maxScore - fullScreenPoints - webcamPoints - mousePoints - tabPoints;
    if (behaviorScore < 0) behaviorScore = 0;
    return behaviorScore;
  };

  const handleNext = () => {
    submitQuestionAnswer();

    if (currentQuestion + 1 < examDetails?.questions.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedCheckBoxOption([]);
      setSelectedOption(-1);
      setStatus("IN_PROGRESS");
    } else if (countSwitchingTab > 3 && tracker) {
      setStatus("ABORTED");
    } else {
      setShowResults(true);
      setStatus("COMPLETED");
    }
  };

  const handlePrevious = () => {
    submitQuestionAnswer();
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      setSelectedOption(-1);
    }
  };

  const [isOutsideScreen, setIsOutsideScreen] = useState(false);
  const [timeOutsideScreen, setTimeOutsideScreen] = useState(0);
  const [startTime, setStartTime] = useState(0);

  useEffect(() => {
    if (!tracker) {
      return;
    } else {
      const handleMouseMovement = (event: { clientX: any; clientY: any }) => {
        const mouseX = event.clientX;
        const mouseY = event.clientY;

        if (
          mouseX < 2 ||
          mouseY < 5 ||
          mouseX >= window.innerWidth - 10 ||
          mouseY >= window.innerHeight - 10
        ) {
          setIsOutsideScreen(true);
        } else {
          setIsOutsideScreen(false);
        }
      };
      document.addEventListener("mouseleave", handleMouseMovement);
      document.addEventListener("mouseenter", handleMouseMovement);

      return () => {
        document.removeEventListener("mouseleave", handleMouseMovement);
        document.removeEventListener("mouseenter", handleMouseMovement);
      };
    }
  }, [tracker]);

  useEffect(() => {
    if (!tracker) {
      return;
    } else {
      let timerId: string | number | NodeJS.Timeout | undefined;
      // setTimeOutsideScreen(0)
      if (isOutsideScreen) {
        if (startTime === 0) {
          setStartTime(Date.now());
        }
        timerId = setInterval(() => {
          setTimeOutsideScreen(
            (prevTime) => prevTime + Math.round((Date.now() - startTime) / 1000)
          );
          setStartTime(Date.now());
        }, 1000);
      } else {
        if (startTime !== 0) {
          clearInterval(timerId);
          // setTimeOutsideScreen((prevTime) => prevTime + (Date.now() - startTime));
          setStartTime(0);
        }
      }

      return () => {
        clearInterval(timerId);
      };
    }
  }, [isOutsideScreen, startTime, tracker]);

  

  const handleSubmitTrackerDetails = async () => {
    // if (sec < 1 && min < 1) {
    //   setStatus("COMPLETED");
    // }
    let behaviourScore: number = behaviourScoreCalculation();
    if (countSwitchingTab > 3 && tracker) {
      handleStatusOnLoad();
    } else {
      dispatch(
        updateCandidateStatus(
          assessmentObj.displayAssessmentExam?.candidateExam.id,
          "COMPLETED",
          behaviourScore,
          feedback
        )
      );
    }
    dispatch(
      createCandidateTrackerDetails(
        exam_candidate_id,
        deviceUsed,
        latitude,
        longitude,
        webCamEnabled,
        ipAddress,
        mouseMovedOutside,
        countMouseMovedOutside,
        switchingTab,
        countSwitchingTab,
        fullScreenMode,
        timeOutsideScreen
      )
    );
    

    
    

    let exam_candidate: number =
      assessmentObj?.displayAssessmentExam?.candidateExam?.id;

    capturedPhotos.push(state.firstImage);

    const databaseURL =
      "https://bravenstoolimageupload-default-rtdb.firebaseio.com//users.json?auth=AIzaSyCi1tuVZ9XCt46GzUhbcqonI6XlAG-sSKo";
    // const endpoint = 'users.json'; // Replace 'data' with the appropriate path
    // https://bravens-2-default-rtdb.firebaseio.com/

    const requestBody = {
      exam_candidate_ids: exam_candidate_id,
      images: capturedPhotos,
      videos: capturedBase64Videos,
    };

    const response = await fetch(`${databaseURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      
      
    } else {
      
    }

    dispatch(
      sendImageArray(capturedPhotos, capturedBase64Videos, exam_candidate)
    );
    document.exitFullscreen();
  };

  const onRadioAnswer = (obj: any) => {
    setSelectedOption(obj.target.value);
  };

  const getSelectedStatus = (isSingleChoice: boolean, option_id: number) => {
    if (isSingleChoice) {
      return selectedOption == option_id + "" ? true : false;
    } else {
      if (selectedCheckBoxOptions.includes(option_id)) {
        return true;
      } else {
        return false;
      }
    }
  };
  const isCorrectAnswer = (question: any, option: any) => {
    
    if (question.questionType === QuestionTypes.SINGLE_CHOICE) {
      if (selectedOption && selectedOption == option.id) {
        return true;
      } else if (question.answer && question.answer.length === 1) {
        if (question.answer[0].questionOptionId == option.id) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if (question.questionType === QuestionTypes.MULTIPLE_CHOICE) {
      if (
        selectedCheckBoxOptions.length > 0 &&
        selectedCheckBoxOptions.includes(option.id)
      ) {
        if (selectedCheckBoxOptions.includes(option.id)) {
          return true;
        } else {
          return false;
        }
      } else if (question.answer && question.answer.length > 0) {
        const index = question.answer.findIndex(
          (obj: any) => obj.questionOptionId == option.id
        );
        if (index !== -1) {
          return true;
        }
      }
    } else if (question.questionType === QuestionTypes.TEXT_AREA) {
      if (question?.textAnswer?.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };
  useEffect(() => {
    if (candidateExamDetails) {
      setExtraTimeAllowed(candidateExamDetails?.extraTimeAllowed);
      setExtraTime(candidateExamDetails?.extraTime);
    }
    setRequiredTime(examDetails?.requiredTime);
  }, []);
  const onCheckBoxClick = (obj: any, index: number) => {
    const selectedOption: any = [...selectedCheckBoxOptions];
    if (selectedOption.includes(obj)) {
      const index = selectedOption.findIndex((cObj: any) => cObj === obj);
      if (index !== -1) {
        selectedOption.splice(index, 1);
      }
    } else {
      selectedOption.push(obj);
    }
    setSelectedCheckBoxOption(selectedOption);
  };

  const handleStatusOnLoad = () => {
    setStatus("ABORTED");
    // setCountMouseMovedOutside(0);
    // setCountSwitchingTab(0);
    let behaviourScore: number = behaviourScoreCalculation();
    dispatch(
      updateCandidateStatus(
        assessmentObj.displayAssessmentExam?.candidateExam.id,
        "ABORTED",
        behaviourScore
        // feedback
      )
    );
    let exam_candidate_id: number =
      assessmentObj?.displayAssessmentExam?.candidateExam?.id;

    dispatch(
      sendImageArray(capturedPhotos, capturedBase64Videos, exam_candidate_id)
    );
  };
  const min2 = examDetails?.requiredTime;

  const getTimeRemaining = (e: any) => {
    const total = Date.parse(e) - Date.now();
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);

    setSec(seconds);
    setMin(minutes);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };
  const startTimer = (e: any) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };
  const clearTimer = (e: any) => {
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    });
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + min2 * 60);

    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  const videoConstraints = {
    width: 420,
    height: 420,
    facingMode: "user",
  };

  useEffect(() => {
    setDevice(window.innerWidth);
  }, []);

  const element = document.documentElement;

  useEffect(() => {
    if (!tracker) {
      return;
    } else {
      const handleVisibilityChange = () => {
        setIsTabActive(!document.hidden);
        
        setSwitchingTab(true);
        setCountSwitchingTab(countSwitchingTab++);
        Swal.fire(
          "WARNING!",
          "Leaving exam multiple times may be flagged as cheating, if you change tab more than 3 times, then test will be disbanded"
          // 'You changed tab more than 3 times, so test is ended',
        );
      };
      if (countSwitchingTab > 0) {
        element.requestFullscreen();
      }
      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    }
  }, [tracker]);

  useEffect(() => {
    if (!tracker) {
      return;
    } else {
      let timerId: string | number | NodeJS.Timeout | undefined;

      const handleMouseMove = (event: { clientX: any; clientY: any }) => {
        const mouseX = event.clientX;
        const mouseY = event.clientY;

        if (
          mouseX < 2 ||
          mouseY < 5 ||
          mouseX >= window.innerWidth - 10 ||
          mouseY >= window.innerHeight - 10
        ) {
          clearTimeout(timerId);
          timerId = setTimeout(() => {
            if (!mouseMovedOutside) {
              setMouseMovedOutside(true);
            }
            setCountMouseMovedOutside((prevCount: number) => prevCount + 1);

            if (countMouseMovedOutside >= 1) {
              Swal.fire(
                "WARNING!",
                "Do not move your cursor multiple times outside window"
              );
            }
          }, 500);
        } else {
          clearTimeout(timerId);
        }
      };

      document.addEventListener("mousemove", handleMouseMove);

      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        clearTimeout(timerId);
      };
    }
  }, [mouseMovedOutside, countMouseMovedOutside, tracker]);

  const handleFullscreenChange = () => {
    if (tracker) {
      setFullScreenMode(!!document.fullscreenElement);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (!tracker) {
      return;
    } else {
      document.addEventListener("fullscreenchange", handleFullscreenChange);

      return () => {
        document.removeEventListener(
          "fullscreenchange",
          handleFullscreenChange
        );
      };
    }
  }, []);

  // 
  // 
  

  useEffect(() => {
    setType(examDetails?.questions[currentQuestion]?.questionType);
    
  });

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        const { ip } = response.data;
        setIp(ip);
        
      } catch (error) {
        
      }
    };

    fetchIpAddress();
  }, []);

  return (
    <div className="question-container">
      {(sec < 1 && min < 1) ||
      showResults ||
      (countSwitchingTab > 3 && tracker) ? (
        <div className="final-result">
          {countSwitchingTab > 3 && tracker ? (
            <h1 style={{ color: "red" }} className="welcome-text">
              Your assessment has been disbanded due to multiple tab switch.
            </h1>
          ) : sec < 1 && min < 1 ? (
            <h1 className="welcome-text" style={{ color: "red" }}>
              Your assessment has been ended due to end of time limit
            </h1>
          ) : (
            <h1 className="welcome-text">
              Thanks for completing this assessment
            </h1>
          )}
          {/* <h1 className="welcome-text">
          Thanks for completing this assessment
        </h1> */}
          <p className="partition"></p>
          <div className="side-gif-main" style={{ columnGap: "50px" }}>
            <div className="" style={{ width: "1500px" }}>
              {countSwitchingTab <= 3 || !tracker || (sec >= 1 && min >= 1) ? (
                <div>
                  <h1 className="welcome-text">Feedback</h1>
                  <TextArea
                    className="quiz-feedback-form"
                    value={feedback}
                    handleChange={(e: any) => {
                      setFeedback(e.target.value);
                    }}
                    placeholder={"Help us to improve this assessment"}
                  />
                </div>
              ) : null}

              <Link to="/">
                <Button
                  value="Continue"
                  handleClick={() => {
                    setStatus("COMPLETED");
                    handleSubmitTrackerDetails();
                  }}
                  className="test-taker-next-btn"
                />
              </Link>
            </div>
            <div className="" style={{ marginTop: "100px", width: "1500px" }}>
              <img style={{}} src={ResultImage} alt="" className="" />
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <div>
            <div className="quiz-countdown">
              <div
                className="updated-timer"
                style={
                  min < min2 * 0.5
                    ? min < min2 * 0.25
                      ? {
                          borderRadius: "50%",
                          border: "6px solid #FF0000",
                          margin: "auto",
                          marginTop: "10px",
                          width: "100px",
                          height: "100px",
                        }
                      : {
                          borderRadius: "50%",
                          border: "6px solid orange",
                          margin: "auto",
                          marginTop: "10px",
                          width: "100px",
                          height: "100px",
                        }
                    : min < min2 * 0.75
                    ? {
                        borderRadius: "50%",
                        border: "6px solid yellow",
                        margin: "auto",
                        marginTop: "10px",
                        width: "100px",
                        height: "100px",
                      }
                    : {
                        borderRadius: "50%",
                        border: "6px solid #1f7ebc",
                        margin: "auto",
                        marginTop: "10px",
                        width: "100px",
                        height: "100px",
                      }
                }
              >
                <center>
                  <h5>{timer}</h5>
                </center>
              </div>
            </div>

            <ProgressStepper
              questionList={
                examDetails?.questions ? examDetails?.questions : []
              }
              currentIndex={currentQuestion}
              selectedOption={
                examDetails?.questions[currentQuestion].questionType ===
                QuestionTypes.SINGLE_CHOICE
                  ? selectedOption
                  : selectedCheckBoxOptions
              }
              onClickItem={(clickedIndex: any) =>
                setCurrentQuestion(clickedIndex)
              }
            />

            {examDetails &&
            examDetails?.questions &&
            examDetails.questions.length > 0 ? (
              <>
                <h3 className="welcome-text">
                  {examDetails && examDetails?.questions
                    ? examDetails?.questions[currentQuestion].questions
                    : null}
                </h3>

                {type == "TEXT_AREA" ? (
                  <>
                    <TextArea
                      value={textAnswer}
                      placeholder={"Enter your answer"}
                      handleChange={(e) => setTextAnswer(e.target.value)}
                    />
                  </>
                ) : (
                  <ul className="option-list">
                    {examDetails && examDetails?.questions
                      ? examDetails?.questions[currentQuestion]?.options.map(
                          (option: any, index: any) => {
                            return (
                              <>
                                <div key={index}>
                                  <div className="label-option">
                                    {examDetails?.questions[currentQuestion]
                                      ?.questionType === "SINGLE_CHOICE" ? (
                                      <input
                                        type="radio"
                                        value={option.id}
                                        checked={isCorrectAnswer(
                                          examDetails?.questions[
                                            currentQuestion
                                          ],
                                          option
                                        )}
                                        onChange={onRadioAnswer}
                                        style={{
                                          width: 30,
                                          height: 30,
                                        }}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          width: 32,
                                          height: 32,
                                          marginRight: 0,
                                        }}
                                        onClick={() =>
                                          onCheckBoxClick(option.id, index)
                                        }
                                      >
                                        <img
                                          src={
                                            isCorrectAnswer(
                                              examDetails?.questions[
                                                currentQuestion
                                              ],
                                              option
                                            )
                                              ? checkbox_selected
                                              : checkbox_noselected
                                          }
                                          alt={"checkbox"}
                                        ></img>
                                      </div>
                                    )}
                                    <li
                                      className="question-list"
                                      key={option.id}
                                      onClick={() =>
                                        examDetails?.questions[currentQuestion]
                                          ?.questionType ===
                                        QuestionTypes.SINGLE_CHOICE
                                          ? onRadioAnswer(option.id)
                                          : onCheckBoxClick(option.id, index)
                                      }
                                      style={{
                                        backgroundColor: getSelectedStatus(
                                          examDetails?.questions[
                                            currentQuestion
                                          ]?.questionType ===
                                            QuestionTypes.SINGLE_CHOICE,
                                          option.id
                                        )
                                          ? // selectedOption == option.id + ""
                                            // selectedCheckBoxOptions == index
                                            "#1f7ebc"
                                          : "#DCDCDC",

                                        color: getSelectedStatus(
                                          examDetails?.questions[
                                            currentQuestion
                                          ]?.questionType ===
                                            QuestionTypes.SINGLE_CHOICE,
                                          option.id
                                        )
                                          ? // selectedOption === option.id + ""
                                            // selectedOptionText === currentQuestion
                                            "#fff"
                                          : "black",
                                      }}
                                    >
                                      {option.options}
                                    </li>
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )
                      : null}
                  </ul>
                )}
              </>
            ) : null}
            {/*  */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              paddingRight: 100,
              marginTop: 10,
            }}
          >
            {currentQuestion >= 1 ? (
              <>
                <button
                  id={"b_element"}
                  className="test-taker-next-btn"
                  onClick={() => handlePrevious()}
                >
                  Previous
                </button>
              </>
            ) : null}
            <div style={{ width: 50 }} />
            <button
              id={"b_element"}
              className="test-taker-next-btn"
              onClick={() => handleNext()}
            >
              {currentQuestion === examDetails?.questions.length - 1
                ? "Submit Test"
                : "Next"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
