import { CandidateReducer } from "./candidate";
import { AppReducers } from "./app";
import { UserReducer } from "./user";
import { OrganizationReducer } from "./organization";
import { AdminReducer } from "./admin";
import { AssessmentReduce } from "./assessment";
import { LoadingReducer } from "./loading";
import { CameraReducer } from "./camera";

const appReducer: any = {
  user: UserReducer,
  organization: OrganizationReducer,
  admin: AdminReducer,
  app: AppReducers,
  assessment: AssessmentReduce,
  loading: LoadingReducer,
  candidate: CandidateReducer,
  camera: CameraReducer
};

export default appReducer;
