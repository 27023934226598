// All languages
export const languages = [
  { value: "cpp", label: "C++" },
  { value: "c", label: "C" },
  { value: "java", label: "Java" },
  { value: "python", label: "Python" },
  { value: "webd", label: "Web Development" },
];

// All languages
export const UserRoles = {
  ADMIN: "ADMIN",
  USER: "USER",
  CANDIDATE: "CANDIDATE",
  BRAVEN_TOOL_ADMIN: "BRAVEN_TOOL_ADMIN",
};

export const ExamTypes = {
  MCQ: "MCQ",
  TEXT: "TEXT",
  CODE: "CODE",
};

export const QuestionTypes = {
  SINGLE_CHOICE: "SINGLE_CHOICE",
  MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
  TEXT_INPUT: "TEXT_INPUT",
  FILE_UPLOAD: "FILE_UPLOAD",
  DATE_TYPE: "DATE_TYPE",
  TEXT_AREA: "TEXT_AREA",
  CODE: "CODE",
  IMAGE: "IMAGE",
  IMAGE_QUESTION: "IMAGE_QUESTION",
};

// All themes
const themesList = [
  "dracula",
  "cobalt",
  "monokai",
  "tomorrow_night",
  "tomorrow_night_blue",
  "nord_dark",
  "tomorrow_night_eighties",
  "vibrant_ink",
];
export const themes = themesList.map((theme) => {
  const label = theme.split("_").join(" ");
  return { value: theme, label };
});

// All fonts
const fontsList = [
  "Consolas",
  "Jetbrains Mono",
  "Hack",
  "Monaco",
  "IBM Plex Mono",
  "Cascadia Code",
  "Source Code Pro",
  "Fira Code",
  "Menlo",
  "Courier",
  "Inconsolata",
];
export const fonts = fontsList.map((font) => ({ value: font, label: font }));

// HTML preview
export const htmlPreview =
  '<h1 class="heading1">Heading tag</h1>\n<p>Paragraph tag</p>\n\n<ol id="list1">\n\t<li>Item1</li>\n\t<li>Item 2</li>\n\t<li>Item 3</li>\n</ol>';

// CSS preview
export const cssPreview =
  "html,\nbody {\n\tmargin: 0;\n\tpadding: 0;\n\tbox-sizing: border-box;\n}\n\nh1 {\n\tfont-family: serif\n}";

// JavaScript preview
export const jsPreview =
  'const btn1 = document.querySelector(".btn");\nbtn1.addEventListener(\'click\', () => {\n\t  console.log("Buttonclicked");\n})';

  export const examTypes = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "MCQ",
      value: "MCQ",
    },
    {
      label: "Code",
      value: "Code",
    },
    {
      label: "Text",
      value: "Text",
    }
  ];

export const questionTypes = [
  {
    label: "Single Choice Question",
    value: "Single Choice Question",
  },
  {
    label: "Multiple Choice Question",
    value: "Multiple Choice Question",
  },
  {
    label: "Text Input/Free Text",
    value: "Text Input/Free Text",
  },
  {
    label: "Coding Question",
    value: "Coding Question",
  },
];

export const difficultyLevels = [
  {
    label: "EASY",
    value: "EASY",
  },
  {
    label: "MODERATE",
    value: "MODERATE",
  },
  {
    label: "CHALLENGING",
    value: "CHALLENGING",
  },
  {
    label: "HARD",
    value: "HARD",
  },
];

export const questionPoints = [
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
  {
    label: "3",
    value: "3",
  },
  {
    label: "4",
    value: "4",
  },
];
export const testCategory = [
  {
    label: "IT",
    value: "IT",
  },
  {
    label: "Finance",
    value: "Finance",
  },
  {
    label: "HR",
    value: "HR",
  },
  {
    label: "Salesforce",
    value: "Salesforce",
  },
];

export const technology_list = [
  {
    label: "Java",
    value: "Java",
  },
  {
    label: "Javascript",
    value: "Javascript",
  },
  {
    label: "ReactJs",
    value: "ReactJs",
  },
  {
    label: "Python",
    value: "Python",
  },
  {
    label: "Android",
    value: "Android",
  },
  {
    label: "Swift",
    value: "Swift",
  },
  {
    label: "MySql",
    value: "MySql",
  },
  {
    label: "C Launguage",
    value: "C Launguage",
  },
  {
    label: ".Net",
    value: ".Net",
  },
];

export const approximateTime = [
  {
    label: "1 Min",
    value: "1",
  },
  {
    label: "2 Min",
    value: "2",
  },
  {
    label: "3 Min",
    value: "3",
  },
  {
    label: "4 Min",
    value: "4",
  },
];

export const getQuestionTypeCode = (type: string) => {
  switch (type) {
    case "Single Choice Question":
      return "SINGLE_CHOICE";

    case "Multiple Choice Question":
      return "MULTIPLE_CHOICE";

    case "Coding Question":
      return "CODE";

    case "Text Input/Free Text":
      return "TEXT_AREA";

    default:
      break;
  }
};

export const getQuestionTypeLocalCode = (type: string) => {
  switch (type) {
    case "SINGLE_CHOICE":
      return "Single Choice Question";

    case "MULTIPLE_CHOICE":
      return "Multiple Choice Question";

    case "CODE":
      return "Coding Question";

    case "TEXT_AREA":
      return "Text Input/Free Text";

    default:
      return "";
  }
};
