//Sachin Dhalani - [14/4/2023] To add Recruiters
import React, { useEffect, useState } from "react";
import { Button } from "../../Common/Button/Button";
import { TextField } from "../../Common/TextField/TextField";
import { History } from "history"; //import History interface
import {
  isEmailValid,
  isMobileNumberValid,
  isNameValid,
  isNumberValid,
  isTextValid,
} from "../../helpers/validate";
import jwt_decode from "jwt-decode";
import { addUser, updateUser } from "../../actions/admin";
import { useAppDispatch } from "../../hooks/app";

interface Props {
  history?: History; //Optional history parameter which is passed by the Router
  editedData?: any;
  isUpdate?: boolean;
  onSubmit?: any;
  onEdit?: any;
}
export const CreateRecruiter: React.FC<Props> = ({
  editedData,
  isUpdate,
  onSubmit,
  onEdit,
}) => {
  const [id, setId] = useState<any>();
  //firstName variable in the state and setFirstName method for updating the token value
  const [firstName, setFirstName] = useState("");
  //LastName variable in the state and setLastName method for updating the token value
  const [lastName, setLastName] = useState("");
  //MiddleName variable in the state and setMiddleName method for updating the token value
  const [middleName, setMiddleName] = useState("");
  //userName in the state and setUserName method for updating the token value
  const [username, setUsername] = useState("");
  //birthDate in the state and setBirthDate method for updating the token value
  const [birthDate, setBirthDate] = useState<any>();
  //mobileNumber in the state and setNumber method for updating the token value
  const [contact, setContact] = useState("");
  //countryCode in the state and setCountryCode method for updating the token value
  const [contact_country_code, setContact_country_code] = useState("+91");
  //email variable in the state and setEmail method for updating the token value
  const [email, setEmail] = useState<string>("");
  //role in the state and setRole method for updating the token value
  const [role, setRole] = useState<string>("USER");
  //emailError variable in the state and setEmailError method for updating the token value
  const [emailError, setEmailError] = useState<string>("");
  //firstNameError variable in the state and setFirstNameError method for updating the token value
  const [firstNameError, setFirstNameError] = useState<string>("");
  //firstNameError variable in the state and setFirstNameError method for updating the token value
  const [middleNameError, setMiddleNameError] = useState<string>("");
  //lastNameError variable in the state and setLastNameError method for updating the token value
  const [lastNameError, setLastNameError] = useState<string>("");
  //userNameError variable in the state and setUserNameError method for updating the token value
  const [userNameError, setUserNameError] = useState<string>("");
  //contactError variable in the state and setContactError method for updating the token value
  const [contactError, setContactError] = useState<string>("");
  //token variable in the state and setToken method for updating the token value
  const [organizationId, setOrganizationId] = useState();
  const [recruiter_id, setRecruiter_id] = useState();
  //Use Effect hook that runs every time there is a change in the user reducer's state
  useEffect(() => {
    if (editedData) {
      setId(editedData.id);
      setFirstName(editedData.firstName);
      setLastName(editedData.lastName);
      setMiddleName(editedData.middleName);
      setContact(editedData.contact);
      setEmail(editedData.email);
      setUsername(editedData.username);
      setBirthDate(editedData.birthDate);
    }
  }, []);

  useEffect(() => {
    const token: any = localStorage.getItem("token");
    let decoded: any = jwt_decode(token);
    setOrganizationId(decoded?.organization?.id);
    setRecruiter_id(decoded?.id);
    //Tried to fetch the token from the local storage and set it in the state variable called token
  }, []);

  const dispatch = useAppDispatch();
  const handleClick = async () => {
    //check if email is valid
    const emailValid = isEmailValid(email);
    //check if first name is valid
    const firstNameValid = isTextValid(firstName);
    //check if last name is valid
    const lastNameValid = isTextValid(lastName);
    //check if user name is valid
    const userNameValid = isTextValid(username);
    //check if middle name is valid
    // const middleNameValid = isTextValid(middleName);
    //check if middle contact is valid
    const contactValid = isMobileNumberValid(contact);
    //If valid, clear error states and execute the signUp action creator
    setRole("USER");
    if (
      emailValid &&
      firstNameValid &&
      lastNameValid &&
      userNameValid &&
      contactValid
    ) {
      setEmailError("");
      setFirstNameError("");
      setLastNameError("");
      setUserNameError("");
      // setMiddleNameError("");
      setContactError("");
      dispatch(
        addUser(
          firstName,
          middleName,
          lastName,
          email,
          username,
          contact,
          birthDate,
          contact_country_code,
          role,
          organizationId,
          recruiter_id,
          "admin"
        )
      );
      onSubmit();
    }
    //if email,firstName,lastName or password is invalid, set errors in the state for whatever is invalid
    else {
      if (!emailValid) {
        setEmailError("Email is invalid");
      }
      if (!firstNameValid) {
        setFirstNameError("First Name cannot be empty!");
      }
      if (!lastNameValid) {
        setLastNameError("Last Name cannot be empty!");
      }
      if (!userNameValid) {
        setUserNameError("User Name cannot be empty!");
      }
      // if (!middleNameValid) {
      //   setMiddleNameError("Middle Name cannot be empty!");
      // }
      if (!contactValid) {
        setContactError("contact cannot be empty!");
      }
    }
  };
  const handleEditRecruiter = () => {
    //  call api in this dispatch

    //check if first name is valid
    const firstNameValid = isTextValid(firstName);
    //check if last name is valid
    const lastNameValid = isTextValid(lastName);
    //check if user name is valid
    const userNameValid = isTextValid(username);
    //check if middle name is valid
    // const middleNameValid = isTextValid(middleName);
    //check if middle contact is valid
    const contactValid = isMobileNumberValid(contact);
    //If valid, clear error states and execute the signUp action creator
    setRole("USER");
    if (firstNameValid && lastNameValid && userNameValid && contactValid) {
      setFirstNameError("");
      setLastNameError("");
      setUserNameError("");
      // setMiddleNameError("");
      setContactError("");
      dispatch(
        updateUser(
          id,
          firstName,
          middleName,
          lastName,
          email,
          username,
          contact,
          birthDate,
          "admin"
        )
      );
      onEdit();
    }
    //if email,firstName,lastName or password is invalid, set errors in the state for whatever is invalid
    else {
      if (!firstNameValid) {
        setFirstNameError("First Name cannot be empty!");
      }
      if (!lastNameValid) {
        setLastNameError("Last Name cannot be empty!");
      }
      if (!userNameValid) {
        setUserNameError("User Name cannot be empty!");
      }
      // if (!middleNameValid) {
      //   setMiddleNameError("Middle Name cannot be empty!");
      // }
      if (!contactValid) {
        setContactError("contact cannot be empty!");
      }
    }
  };

  return (
    <div className="add-recruiter-container">
      <div className="add-recruiter-parent">
        <div className="add-recruiter-left">
          <TextField
            className="h-9 "
            value={firstName}
            placeholder="Enter First Name"
            label="First Name"
            type="text"
            required={true}         
            handleChange={(e) => setFirstName(e.target.value)}
          />
          <p className="validation-error">{firstNameError}</p>
        </div>
        <div style={{ width: "335px" }} className="add-recruiter-right">
          <TextField
            className="h-9"
            value={middleName}
            placeholder="Enter Middle Name"
            label="Middle Name"
            type="text"
            handleChange={(e) => setMiddleName(e.target.value)}
          />
          {/* <p className="validation-error">{middleNameError}</p> */}
        </div>
      </div>

      <div className="add-recruiter-parent">
        <div className="add-recruiter-left">
          <TextField
            className="h-9"
            value={lastName}
            placeholder="Enter Last Name"
            label="Last Name"
            type="text"
            required={true}
            handleChange={(e) => setLastName(e.target.value)}
          />
          <p className="validation-error">{lastNameError}</p>
        </div>
        <div className="add-recruiter-right">
          <TextField
            className="h-9"
            value={contact}
            placeholder="93239*****"
            label="Contact Number"
            // type="number"
            required={true}
            handleChange={(e) =>
              setContact(e.target.value.replace(/[^0-9]/gi, ""))
            }
          />
          <p className="validation-error">{contactError}</p>
        </div>
      </div>
      <div className="add-recruiter-parent">
        <div className="add-recruiter-left">
          <TextField
            className="h-9"
            value={email}
            placeholder="Enter Email Address"
            label="Email"
            type="email"
            required={true} 
            handleChange={(e) => setEmail(e.target.value.replace(/\s/g, ""))}
            disabled={isUpdate ? true : false}
          />
          <p className="validation-error">{emailError}</p>
        </div>

        <div className="add-recruiter-right">
          <TextField
          // border-none border-b-2 border-red-500
            className="h-9 "
            value={username}
            placeholder="Enter UserName"
            label="User Name"
            type="text"
            required={true}
            handleChange={(e) => setUsername(e.target.value)}
          />
          <p className="validation-error">{userNameError}</p>
        </div>
      </div>

      <div className="">
        <div className="add-recruiter-btn">
          <Button
            value={"SAVE"}
            handleClick={() => {
              isUpdate ? handleEditRecruiter() : handleClick();
            }}
            className="add-candidate-submit-btn"
          />
        </div>
      </div>
    </div>
  );
};
