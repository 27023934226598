//Sachin Dhalani - [11/4/2023] - Home page for Candidates details page
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import { Navbar } from "../RecruitersDashboard/Navbar";
import { useAppDispatch } from "../../hooks/app";
import {
  displayAllAssessmentByExamIdForAdmin,
  displayAssessmentSore,
  displayCandidateTrackerDetails,
} from "../../actions/assessment";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import { CaptureSlider } from "./CaptureSlider";
import jwt_decode from "jwt-decode";
import { AdminNavbar } from "../AdminDashboard/Navbar";
import { MDBTable, MDBTableBody, MDBRow, MDBCol } from "mdb-react-ui-kit";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import CustomAccordion from "./CustomAccordion";
import { CustomTable } from "./CustomTable";
// import html2pdf from './html2pdf.js';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { DropDown } from "../../Common/DropDown/DropDown";

interface props {
  isDevice?: number;
}
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
export const CandidateScore: React.FC<props> = ({ isDevice }) => {
  //hiringStage in the state and setHiringStage method for updating the token value
  const [hiringStage, setHiringStage] = useState<string>("");
  const [candidateRating, setCandidateRating] = useState("");
  // const [deviceScreen, setDeviceScreen] = useState<any>(isDevice);
  const [id, setID] = useState<any>();
  const [type, setType] = useState<any>();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const candidateScoreData = location.state.data;
  //  const assesmentAllQuestions = location.state.questions;
  //  console.log('assesmentAllQuestions: ', assesmentAllQuestions);

  console.log("candidateScoreData: ", candidateScoreData);

  const allTypeQuestionsData = location.state.allTypeQuestionsData;
  const candidateName = location.state.data.candidateFirstName;
  console.log("allTypeQuestionsData: ", allTypeQuestionsData);

  const [examCandidateId, setExamCandidateId] = useState("");

  const [switchingTab, setSwitchingTab] = useState<boolean>(false);
  const [role, setRole] = useState("");
  const token: any = localStorage.getItem("token");
  let decoded: any = jwt_decode(token);
  const navigate = useNavigate();

  useEffect(() => {
    if (decoded) {
      setRole(decoded?.role);
    }
    dispatch(displayAllAssessmentByExamIdForAdmin(candidateScoreData.examId));
  }, []);
  // console.log("decoded: ", decoded);

  const assessmentObj = useSelector((state: RootState) => state.assessment);

  // let candidateAnswerArray:Array<any> = []
  // candidateAnswerArray.push(candidateQuestionAnswerCheck)
  // console.log('candidateAnswerArray: ', candidateAnswerArray);

  // console.log("assessmentObj: ", assessmentObj);

  // console.log("candidateScoreData.candidateId: ", candidateScoreData.id);

  const displayCandidateScore = async () => {
    if (decoded.role === "USER") {
      await dispatch(displayAssessmentSore(candidateScoreData?.candidateId));
    } else if (decoded.role === "ADMIN") {
      await dispatch(displayAssessmentSore(candidateScoreData?.candidateId));
    }
  };
  useEffect(() => {
    const exam_candidate_id =
      assessmentObj?.displayAssessmentExam?.candidateExam?.id;
    setExamCandidateId(candidateScoreData.id);
    // console.log("useeffectid: ", candidateScoreData.id);
    displayCandidateScore();
    if (candidateScoreData.id) {
      dispatch(displayCandidateTrackerDetails(candidateScoreData.id));
    } else {
      // console.log("null");
    }
  }, []);

  // console.log("id: ", id);
  useEffect(() => {
    setID(candidateScoreData.id);
  }, []);
  const assessmentDetails = useSelector(
    (state: RootState) => state.assessment.displayAssessmentScore
  );
  // console.log("assessmentObj: ", assessmentObj);
  console.log("assessmentDetails: ", assessmentDetails);

  //To het tracker details from backend
  const candidateTracker = useSelector(
    (state: RootState) => state.assessment.displayCandidateTrackerDetails
  );
  // console.log("TrackerDetails", candidateTracker);
  const [expanded, setExpanded] = useState(null);

  const handleAccordionChange = (id: React.SetStateAction<null>) => {
    setExpanded(id === expanded ? null : id);
  };
  // console.log("assessmentDetails: ", assessmentDetails);

  const renderColorfulLegendText = (value: string, entry: any) => {
    return (
      <span style={{ color: "#596579", fontWeight: 500, padding: "10px" }}>
        {value}
      </span>
    );
  };
  let candidateIndex = 0;
  assessmentDetails?.map((data: any, index: number) => {
    if (data?.candidateExam?.id == examCandidateId) {
      // console.log("id of examCanididate and index", examCandidateId, index);
      candidateIndex = index;
    }
  });

  let trackerIndex = 0;
  candidateTracker?.map((data: any, index: number) => {
    if (data?.candidateExam?.exam_candidate_id == examCandidateId) {
      // console.log(examCandidateId, index);
      trackerIndex = index;
      // console.log(data);
    }
  });
  //find index of current assessment by filtering out examcandidateid from assessment details
  // console.log(examCandidateId, candidateIndex, trackerIndex);
  const currentQuestion = assessmentDetails?.[candidateIndex];
  console.log("currentQuestion: ", currentQuestion);
  const currentAssessment = [currentQuestion];
  // console.log(currentAssessment);

  // const assessment = useSelector((state: RootState) => state.assessment);
  // console.log('assessment: from excel ', assessment);

  const exportToExcel = (data: any[], fileName: string) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(excelData, fileName + ".xlsx");
  };

  //fetching candidate answer of the question
  const candidateQuestionAnswerCheck = useSelector(
    (state: RootState) => state.candidate.candidateQuestionAnswer
  );
  // console.log("candidateQuestionAnswerCheck: ", candidateQuestionAnswerCheck);

  //Fetching question and options of candidate exam
  const questionOptionArray = assessmentObj?.displayAssessmentExam?.questions;
  console.log("questionOptionArray: ", questionOptionArray);

  let filterQuestionData = allTypeQuestionsData?.filter(
    (ele: any) =>
      ele?.examId === candidateScoreData?.examId &&
      ele?.candidateExamId === candidateScoreData?.id
  );
  console.log("filterQuestionData: ", filterQuestionData);

  const finalJsonArray: any[] = [];

  const textAreaAnswers: any[] = [];

  for (let i = 0; i < questionOptionArray?.length; i++) {
    let arr = [];
    let answer = [];
    for (let j = 0; j < questionOptionArray[i]?.options?.length; j++) {
      if (questionOptionArray[i]?.options[j]?.isCorrect === true) {
        arr.push(questionOptionArray[i]?.options[j]?.options);
      }
    }
    for (let k = i; k <= candidateQuestionAnswerCheck?.length; k++) {
      if (
        questionOptionArray[i]?.id ===
        candidateQuestionAnswerCheck[k]?.questionsId
      ) {
        answer.push(candidateQuestionAnswerCheck[k]?.options);
      }
    }

    // console.log("answer: ", answer);

    if (
      questionOptionArray[i]?.questionType === "SINGLE_CHOICE" ||
      questionOptionArray[i]?.questionType === "MULTIPLE_CHOICE"
    ) {
      let obj = {
        questions: questionOptionArray[i]?.questions,
        questionType: questionOptionArray[i]?.questionType,
        option1: questionOptionArray[i]?.options[0].options,
        option2: questionOptionArray[i]?.options[1].options,
        option3: questionOptionArray[i]?.options[2].options,
        option4: questionOptionArray[i]?.options[3].options,
        difficultyLevel: questionOptionArray[i]?.difficultyLevel,
        requiredTime: questionOptionArray[i]?.requiredTime,
        points: questionOptionArray[i]?.points,
        correctAnswer: arr.toString(),
        candidateAnswer: answer.toString(),
        // candidateAnswer2: candidateQuestionAnswerCheck[i].options,
      };
      finalJsonArray.push(obj);
    } else if (questionOptionArray[i]?.questionType === "TEXT_AREA") {
      let obj = {
        questions: questionOptionArray[i]?.questions,
        questionType: questionOptionArray[i]?.questionType,
        difficultyLevel: questionOptionArray[i]?.difficultyLevel,
        requiredTime: questionOptionArray[i]?.requiredTime,
        points: questionOptionArray[i]?.points,
        candidateAnswer: filterQuestionData[i]?.textAnswer,
      };
      console.log("text obj: ", obj);
      // textAreaAnswers.push(obj);
      finalJsonArray.push(obj);
    } else if (questionOptionArray[i]?.questionType === "CODE") {
      let obj = {
        questions: questionOptionArray[i]?.questions,
        questionType: questionOptionArray[i]?.questionType,
        difficultyLevel: questionOptionArray[i]?.difficultyLevel,
        requiredTime: questionOptionArray[i]?.requiredTime,
        points: questionOptionArray[i]?.points,
        candidateAnswer: filterQuestionData[i]?.textAnswer,
      };
      finalJsonArray.push(obj);
    }
  }

  console.log("finalJsonArray: ", finalJsonArray);

  const handleExport = async () => {
    exportToExcel(finalJsonArray, `${candidateName} Exam Report`);
  };

  const printDocument = async () => {
    const input: any = document.getElementsByClassName("cScoreMain");
    // input.innerHTML = <>My Content</>
    //for the following code, you can append it anywhere then just delete it once you're done
    // document.body.appendChild(input)
    // console.log("input:::::::::::::::::::", input)
    const pdf: any = new jsPDF({ unit: "mm", format: [210, 297] });
    const canvas: any = await html2canvas(input[0]);
    // console.log("canvas::::::::", canvas);
    // const imgData: any = canvas.toDataURL('image/jpeg');
    const imgData = canvas.toDataURL("image/png");
    pdf.addImage(imgData, "JPEG", 0, 0, 210, 297);
    pdf.save("document.pdf");
    // if (input) {
    // html2canvas(input)
    //   .then((canvas) => {
    //     // document.body.appendChild(canvas)

    //     console.log('pdf: ', pdf);
    //   })
    //   .catch((error) => {
    //     console.error('Error generating PDF:', error);
    //   });
    // // }
  };

  const pageRef = useRef<HTMLDivElement>(null);

  // const downloadUI = () => {
  //   if (pageRef.current) {
  //     html2canvas(pageRef.current).then((canvas) => {
  //       canvas.toBlob((blob) => {
  //         if (blob) {
  //           saveAs(blob, "ui.png");
  //         }
  //       });
  //     });
  //   }
  // };
  console.log("currentAssessment: ", currentAssessment);
  console.log("candidateTracker: ", candidateTracker);

  const ExamOption = [
    {
      label: "MCQ",
      value: "MCQ",
    },
    {
      label: "Code",
      value: "Code",
    },
    {
      label: "Text",
      value: "Text",
    },
  ];

  let textEasySolved = 0;
  let textEasyUnSolved = 0;
  let textModerateSolved = 0;
  let textModerateUnSolved = 0;
  let textChallengingSolved = 0;
  let textChallengingUnSolved = 0;
  let textHardSolved = 0;
  let textHardUnSolved = 0;

  console.log("textAreaAnswers: ", textAreaAnswers);
  for (let i = 0; i < questionOptionArray?.length; i++) {
    if (questionOptionArray[i]?.difficultyLevel === "EASY") {
      if (
        finalJsonArray[i]?.candidateAnswer == undefined ||
        finalJsonArray[i]?.candidateAnswer == ""
      ) {
        textEasyUnSolved = textEasyUnSolved + 1;
      } else {
        textEasySolved = textEasySolved + 1;
      }
    } else if (questionOptionArray[i]?.difficultyLevel === "MODERATE") {
      if (
        finalJsonArray[i]?.candidateAnswer == undefined ||
        finalJsonArray[i]?.candidateAnswer == ""
      ) {
        textModerateUnSolved = textModerateUnSolved + 1;
      } else {
        textModerateSolved = textModerateSolved + 1;
      }
    } else if (questionOptionArray[i]?.difficultyLevel === "CHALLENGING") {
      if (
        finalJsonArray[i]?.candidateAnswer == undefined ||
        finalJsonArray[i]?.candidateAnswer == ""
      ) {
        textChallengingUnSolved = textChallengingUnSolved + 1;
      } else {
        textChallengingSolved = textChallengingSolved + 1;
      }
    } else if (questionOptionArray[i]?.difficultyLevel === "HARD") {
      if (
        finalJsonArray[i]?.candidateAnswer == undefined ||
        finalJsonArray[i]?.candidateAnswer == ""
      ) {
        textHardUnSolved = textHardUnSolved + 1;
      } else {
        textHardSolved = textHardSolved + 1;
      }
    }
  }

  return (
    <>
      {role === "ADMIN" ? <AdminNavbar /> : <Navbar />}
      <div className="candidate-details-home">
        <div className="Admin-exam-type-dropdown"></div>
        {currentAssessment?.map((data: any, index: number) => {
          const cameraEnabled = data?.candidateExam?.cameraEnabled;
          console.log("camera", cameraEnabled);
          const tracker = data?.candidateExam?.activityTrackerEnabled;
          console.log("tracker", tracker);
          const fullScreenMode =
            candidateTracker?.[trackerIndex]?.candidateExam?.fullScreenMode;
          const deviceUsed =
            candidateTracker?.[trackerIndex]?.candidateExam?.deviceUsed;
          const ipAddress = `${candidateTracker?.[trackerIndex]?.candidateExam?.ipAddress}`;
          const webCamEnabled =
            candidateTracker?.[trackerIndex]?.candidateExam?.webCamEnabled;
          const countMouseMovedOutside =
            candidateTracker?.[trackerIndex]?.candidateExam
              ?.countMouseMovedOutside;
          const countSwitchingTab =
            candidateTracker?.[trackerIndex]?.candidateExam?.countSwitchingTab;
          const timeOutsideScreen =
            candidateTracker?.[trackerIndex]?.candidateExam?.timeOutsideScreen;
          const candidateLocation = `${candidateTracker?.[trackerIndex]?.candidateExam?.latitude}, ${candidateTracker?.[trackerIndex]?.candidateExam?.longitude}`;

          const examStatus = data?.candidateExam?.status;
          console.log("examStatus: ", examStatus);

          const totalCorrectPoints = data?.totalCorrectPoints;
          const totalIncorrectpoints = data?.totalIncorrectpoints;
          const unansweredPoints = data?.unanswerd;
          const pieChartData = [
            { name: "Correct", value: totalCorrectPoints, fill: "#A5D6A7" },
            { name: "Incorrect", value: totalIncorrectpoints, fill: "#EF9A9A" },
            { name: "Unanswered", value: unansweredPoints, fill: "#E0E0E0" },
          ];

          const textPieChartData = [
            {
              name: "Solved",
              value:
                textEasySolved +
                textModerateSolved +
                textChallengingSolved +
                textHardSolved,
              fill: "#A5D6A7",
            },
            {
              name: "Unsolved",
              value:
                textEasyUnSolved +
                textModerateUnSolved +
                textChallengingUnSolved +
                textHardUnSolved,
              fill: "#EF9A9A",
            },
          ];

          const easyCorrect = data?.easyCorrect;
          console.log("easyCorrect: ", easyCorrect);
          const easyInCorrect = data?.easyInCorrect;
          console.log("easyInCorrect: ", easyInCorrect);
          const moderateCorrect = data?.moderateCorrect;
          const moderateInCorrect = data?.moderateInCorrect;
          const hardCorrect = data?.hardCorrect;
          const hardInCorrect = data?.hardInCorrect;
          const challengingCorrect = data?.challengingCorrect;
          const challengingInCorrect = data?.challengingInCorrect;

          const questionsTable = [
            ["Type", "Correct", "Incorrect"],
            ["Easy", `${easyCorrect}`, `${easyInCorrect}`],
            ["Moderate", `${moderateCorrect}`, `${moderateInCorrect}`],
            ["Hard", `${hardCorrect}`, `${hardInCorrect}`],
            ["Challenging", `${challengingCorrect}`, `${challengingInCorrect}`],
          ];

          const textQuestionsTable = [
            ["Type", "Solved", "Unsolved"],
            ["Easy", `${textEasySolved}`, `${textEasyUnSolved}`],
            ["Moderate", `${textModerateSolved}`, `${textModerateUnSolved}`],
            ["Hard", `${textHardSolved}`, `${textHardUnSolved}`],
            [
              "Challenging",
              `${textChallengingSolved}`,
              `${textChallengingUnSolved}`,
            ],
          ];

          const barChartData = [
            {
              catagory: "Correct",
              easy: { value: easyCorrect, desc: "easy correct" },
              moderate: { value: moderateCorrect, desc: "moderate correct" },
              hard: { value: hardCorrect, desc: "hard correct" },
              challenging: {
                value: challengingCorrect,
                desc: "challenging correct",
              },
            },
            {
              catagory: "Incorrect",
              easy: { value: easyInCorrect, desc: "easy incorrect" },
              moderate: {
                value: moderateInCorrect,
                desc: "moderate incorrect",
              },
              hard: { value: hardInCorrect, desc: "hard incorrect" },
              challenging: {
                value: challengingInCorrect,
                desc: "challenging incorrect",
              },
            },
          ];

          const textBarChartData = [
            {
              catagory: "Solved",
              easy: { value: textEasySolved, desc: "easy solved" },
              moderate: { value: textModerateSolved, desc: "moderate solved" },
              hard: { value: textHardSolved, desc: "hard solved" },
              challenging: {
                value: textChallengingSolved,
                desc: "challenging solved",
              },
            },
            {
              catagory: "Unsolved",
              easy: { value: textEasyUnSolved, desc: "easy unsolved" },
              moderate: {
                value: textModerateUnSolved,
                desc: "moderate unsolved",
              },
              hard: { value: textHardUnSolved, desc: "hard unsolved" },
              challenging: {
                value: textChallengingUnSolved,
                desc: "challenging unsolved",
              },
            },
          ];

          // console.log(pieChartData);
          // console.log(barChartData);
          const score = data?.candidateExam?.score;
          const scoreTableMCQ = [
            ["Score", `${score} %`],
            ["Correct Points", `${data?.totalCorrectPoints}`],
            ["Incorrect Points", `${data?.totalIncorrectpoints}`],
            ["Total Points", `${data?.totalPoints}`],
            ["Unanswered Questions", `${data?.unanswerd}`],
          ];

          const isfullscreen = fullScreenMode ? "Yes" : "No";
          const isWebcam = webCamEnabled ? "Yes" : "No";
          const mouse =
            countMouseMovedOutside > 0 ? `${countMouseMovedOutside}` : "No";
          const tab =
            switchingTab || countSwitchingTab > 0
              ? `${countSwitchingTab}`
              : "No";
          const antiCheatingMonitorData = [
            ["Device Used", `${deviceUsed}`],
            ["Fullscreen Mode Active", `${isfullscreen}`],
            ["Location", `${candidateLocation}`],
            ["IP Address", `${ipAddress}`],
            ["Webcam Enabled", `${isWebcam}`],
            ["Mouse Outside Window", `${mouse}`],
            ["Switching Tabs", `${tab}`],
            ["Time Outside Window", `${timeOutsideScreen}`],
          ];

          //behavior score calculation
          const maxScore = 100;
          const fullScreenPoints = tracker && !fullScreenMode ? 5 : 0;
          const webcamPoints = cameraEnabled && !webCamEnabled ? 5 : 0;
          const mousePoints =
            countMouseMovedOutside <= 20 ? countMouseMovedOutside * 1 : 20;
          const tabPoints =
            countSwitchingTab <= 3 ? countSwitchingTab * 10 : 30;
          let behaviorScore =
            maxScore -
            fullScreenPoints -
            webcamPoints -
            mousePoints -
            tabPoints;
          if (behaviorScore < 0) behaviorScore = 0;
          const behaviourScoreTable = [
            ["Behavior Score", `${behaviorScore} / ${maxScore}`],
            ["FullScreen", `- ${fullScreenPoints}`],
            ["Webcam", `- ${webcamPoints}`],
            ["Mouse", `- ${mousePoints}`],
            ["Tab", `- ${tabPoints}`],
          ];
          const behaviorScoreChart = [
            { name: "Score", value: behaviorScore, fill: "#A5D6A7" },
            { name: "Fullscreen", value: fullScreenPoints, fill: "#EF9A9A" },
            { name: "Webcam", value: webcamPoints, fill: "#FFD54F" },
            { name: "Mouse", value: mousePoints, fill: "#FF8A65" },
            { name: "Tab", value: tabPoints, fill: "#CE93D8" },
          ];
          // console.log(behaviorScoreChart);

          const invitedDate = data?.candidateExam?.sendDate?.substring(0, 10);
          // console.log(invitedDate);

          return (
            <>
              <div className="heading-candidate-details" key={index}>
                <button className="exam-report" onClick={handleExport}>
                  Exam Report
                </button>
                <button
                  className="exam-report"
                  onClick={() => {
                    navigate("/downloadPdf", {
                      state: {
                        trackerIndexPdf: trackerIndex,
                        currentAssessment: currentAssessment,
                        candidateTracker: candidateTracker,
                        invitedDate: invitedDate,
                        examStatus: examStatus,
                        behaviourScoreTable: behaviourScoreTable,
                        antiCheatingMonitorData: antiCheatingMonitorData,
                        scoreTableMCQ: scoreTableMCQ,
                        questionsTable: questionsTable,
                        candidateName: candidateName,
                        textQuestionsTable: textQuestionsTable,
                      },
                    });
                  }}
                >
                  PDF
                </button>
                {data?.exam?.type === "Code" &&
                  (data?.exam?.type).toUpperCase() == "CODE" && (
                    <div className="candidate-details-heading">
                      <p className="candidate-details-p">
                        {data?.exam?.name} (CODING)
                      </p>
                    </div>
                  )}
                {data?.exam?.type === "MCQ" &&
                  (data?.exam?.type).toUpperCase() == "MCQ" && (
                    <div className="candidate-details-heading">
                      <p className="candidate-details-p">
                        {data?.exam?.name} (MCQ)
                      </p>
                    </div>
                  )}
                {data?.exam?.type === "Text" &&
                  (data?.exam?.type).toUpperCase() == "TEXT" && (
                    <div className="candidate-details-heading">
                      <p className="candidate-details-p">
                        {data?.exam?.name} (TEXT)
                      </p>
                    </div>
                  )}
              </div>
              {/* ---------------------------- */}
              <div className="container">
                <div className="container container-1">
                  <div className="invited-column">
                    <p className="column-title">Exam Status</p>
                    <p className="container-column-heading">Invited</p>
                    <p style={{ padding: "0 0 0 10px" }}>{invitedDate}</p>
                    <p className="container-column-heading">Status</p>
                    {examStatus == "ABORTED" ? (
                      <p className="container-column-aborted">Aborted</p>
                    ) : (
                      <div>
                        <p className="container-column-body">Completed</p>
                      </div>
                    )}
                    <p className="container-column-heading">Camera Required?</p>
                    <p style={{ padding: "0 0 0 10px" }}>
                      {cameraEnabled ? "Yes" : "No"}
                    </p>
                    <p className="container-column-heading">
                      Screen Activity Tracked?
                    </p>
                    <p style={{ padding: "0 0 0 10px" }}>
                      {tracker ? "Yes" : "No"}
                    </p>
                    {/* <img
                      src={ExamCandidateImage}
                      alt=""
                      className=""
                      style={{ width: "150px", margin: "0 0 0 30px" }}
                    /> */}
                  </div>
                  {candidateScoreData?.candidateExamType === "Text" ? (
                    <div className="column-score">
                      <p className="column-title">Exam Details</p>

                      {/* <CustomAccordion
                      id={1}
                      title="Score"
                      table={scoreTableMCQ}
                      expanded={expanded}
                      handleChange={handleAccordionChange}
                    /> */}
                      <CustomAccordion
                        id={3}
                        title="Questions Information"
                        expanded={expanded}
                        handleChange={handleAccordionChange}
                        customTable={textQuestionsTable}
                      />
                      <CustomAccordion
                        id={2}
                        title="Behavior Score"
                        table={behaviourScoreTable}
                        expanded={expanded}
                        handleChange={handleAccordionChange}
                      />
                      {/* <CustomAccordion
                      id={3}
                      title="Questions Information"
                      expanded={expanded}
                      handleChange={handleAccordionChange}
                      customTable={questionsTable}
                    /> */}
                    </div>
                  ) : (
                    <div className="column-score">
                      <p className="column-title">Exam Details</p>

                      <CustomAccordion
                        id={1}
                        title="Score"
                        table={scoreTableMCQ}
                        expanded={expanded}
                        handleChange={handleAccordionChange}
                      />
                      <CustomAccordion
                        id={2}
                        title="Behavior Score"
                        table={behaviourScoreTable}
                        expanded={expanded}
                        handleChange={handleAccordionChange}
                      />
                      <CustomAccordion
                        id={3}
                        title="Questions Information"
                        expanded={expanded}
                        handleChange={handleAccordionChange}
                        customTable={questionsTable}
                      />
                    </div>
                  )}
                  <div className="anti-cheating-column">
                    <p className="column-title">Anti-cheating monitor</p>
                    <CustomTable table={antiCheatingMonitorData} />
                  </div>
                </div>
                {candidateScoreData?.candidateExamType === "Text" ? (
                  <div className="container container-2">
                    <div className="column-chart">
                      <ResponsiveContainer width="100%" height={400}>
                        <PieChart
                          margin={{
                            top: 20,
                            right: 20,
                            left: 20,
                            bottom: 20,
                          }}
                        >
                          <Legend
                            height={36}
                            iconType="circle"
                            layout="horizontal"
                            align="center"
                            verticalAlign="bottom"
                            iconSize={10}
                            formatter={renderColorfulLegendText}
                          />
                          <Pie
                            dataKey="value"
                            data={textPieChartData}
                            nameKey="name"
                            innerRadius={50}
                            outerRadius={100}
                            labelLine={false}
                            label
                          />
                          <text
                            enableBackground={"black"}
                            x="50%"
                            y={25}
                            fill="black"
                            textAnchor="middle"
                            dominantBaseline="central"
                            // textDecoration="underline"
                          >
                            <tspan
                              className="c-score-tspan"
                              fontSize="20"
                              fontStyle="Italic"
                            >
                              Points
                            </tspan>
                          </text>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="column-chart">
                      <ResponsiveContainer width="100%" height={400}>
                        <PieChart
                          margin={{
                            top: 20,
                            right: 20,
                            left: 20,
                            bottom: 20,
                          }}
                        >
                          <Legend
                            height={50}
                            fontSize={12}
                            iconType="circle"
                            layout="horizontal"
                            align="center"
                            verticalAlign="bottom"
                            iconSize={10}
                            formatter={renderColorfulLegendText}
                          />
                          <Pie
                            dataKey="value"
                            data={behaviorScoreChart}
                            nameKey="name"
                            innerRadius={50}
                            outerRadius={100}
                            labelLine={false}
                            label
                          />
                          <text
                            x="50%"
                            y={25}
                            fill="black"
                            textAnchor="middle"
                            dominantBaseline="central"
                            // textDecoration="underline"
                          >
                            <tspan fontSize="20" fontStyle="Italic">
                              Behavior Score
                            </tspan>
                          </text>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="column-chart">
                      <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                          data={textBarChartData}
                          margin={{
                            top: 50,
                            right: 40,
                            left: 10,
                            bottom: 20,
                          }}
                        >
                          <CartesianGrid stroke="#ccc" />
                          <XAxis type="category" dataKey="catagory" />
                          <YAxis type="number" />
                          <Tooltip />
                          {/* <Bar dataKey="value" /> */}
                          <Bar
                            dataKey="easy.value"
                            barSize={80}
                            stackId="1"
                            stroke="#0A579E"
                            fill="#0A579E"
                            name="Easy"
                          />
                          <Bar
                            dataKey="moderate.value"
                            stackId="1"
                            stroke="#0A579E"
                            fill="#1578CF"
                            name="Morderate"
                          />
                          <Bar
                            dataKey="hard.value"
                            stackId="1"
                            stroke="#0A579E"
                            fill="#249CFF"
                            name="Hard"
                          />
                          <Bar
                            dataKey="challenging.value"
                            stackId="1"
                            stroke="#0A579E"
                            fill="#77C2FE"
                            name="Challenging"
                          />
                          <text
                            x="50%"
                            y={30}
                            fill="black"
                            // textDecoration="underline"
                            textAnchor="middle"
                            dominantBaseline="central"
                          >
                            <tspan fontSize="20" fontStyle="Italic">
                              Questions
                            </tspan>
                          </text>
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                ) : (
                  <div className="container container-2">
                    <div className="column-chart">
                      <ResponsiveContainer width="100%" height={400}>
                        <PieChart
                          margin={{
                            top: 20,
                            right: 20,
                            left: 20,
                            bottom: 20,
                          }}
                        >
                          <Legend
                            height={36}
                            iconType="circle"
                            layout="horizontal"
                            align="center"
                            verticalAlign="bottom"
                            iconSize={10}
                            formatter={renderColorfulLegendText}
                          />
                          <Pie
                            dataKey="value"
                            data={pieChartData}
                            nameKey="name"
                            innerRadius={50}
                            outerRadius={100}
                            labelLine={false}
                            label
                          />
                          <text
                            enableBackground={"black"}
                            x="50%"
                            y={25}
                            fill="black"
                            textAnchor="middle"
                            dominantBaseline="central"
                            // textDecoration="underline"
                          >
                            <tspan
                              className="c-score-tspan"
                              fontSize="20"
                              fontStyle="Italic"
                            >
                              Points
                            </tspan>
                          </text>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="column-chart">
                      <ResponsiveContainer width="100%" height={400}>
                        <PieChart
                          margin={{
                            top: 20,
                            right: 20,
                            left: 20,
                            bottom: 20,
                          }}
                        >
                          <Legend
                            height={50}
                            fontSize={12}
                            iconType="circle"
                            layout="horizontal"
                            align="center"
                            verticalAlign="bottom"
                            iconSize={10}
                            formatter={renderColorfulLegendText}
                          />
                          <Pie
                            dataKey="value"
                            data={behaviorScoreChart}
                            nameKey="name"
                            innerRadius={50}
                            outerRadius={100}
                            labelLine={false}
                            label
                          />
                          <text
                            x="50%"
                            y={25}
                            fill="black"
                            textAnchor="middle"
                            dominantBaseline="central"
                            // textDecoration="underline"
                          >
                            <tspan fontSize="20" fontStyle="Italic">
                              Behavior Score
                            </tspan>
                          </text>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="column-chart">
                      <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                          data={barChartData}
                          margin={{
                            top: 50,
                            right: 40,
                            left: 10,
                            bottom: 20,
                          }}
                        >
                          <CartesianGrid stroke="#ccc" />
                          <XAxis type="category" dataKey="catagory" />
                          <YAxis type="number" />
                          <Tooltip />
                          {/* <Bar dataKey="value" /> */}
                          <Bar
                            dataKey="easy.value"
                            barSize={80}
                            stackId="1"
                            stroke="#0A579E"
                            fill="#0A579E"
                            name="Easy"
                          />
                          <Bar
                            dataKey="moderate.value"
                            stackId="1"
                            stroke="#0A579E"
                            fill="#1578CF"
                            name="Morderate"
                          />
                          <Bar
                            dataKey="hard.value"
                            stackId="1"
                            stroke="#0A579E"
                            fill="#249CFF"
                            name="Hard"
                          />
                          <Bar
                            dataKey="challenging.value"
                            stackId="1"
                            stroke="#0A579E"
                            fill="#77C2FE"
                            name="Challenging"
                          />
                          <text
                            x="50%"
                            y={30}
                            fill="black"
                            // textDecoration="underline"
                            textAnchor="middle"
                            dominantBaseline="central"
                          >
                            <tspan fontSize="20" fontStyle="Italic">
                              Questions
                            </tspan>
                          </text>
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                )}
                {cameraEnabled ? (
                  <div className="container container-3">
                    {examCandidateId && (
                      <CaptureSlider exam_candidate_id={examCandidateId} />
                    )}
                  </div>
                ) : null}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
