import React, { useState } from "react";
import { TextField } from "../../Common/TextField/TextField";
import { Button } from "../../Common/Button/Button";
import { DropDown } from "../../Common/DropDown/DropDown";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
interface Props {
  data?: any;
  name?: any;
  onSubmit?: any;
}
export const TestDetails: React.FC<Props> = ({ data, name, onSubmit }) => {
  const [extraTimeAllowed, setExtraTimeAllowed] = useState();
  const [extraTime, setExtraTime] = useState();
  const [examStage, setExamStage] = useState("");
  const [error, setError] = useState("");
  const handleEmail = (e: any) => {
    if (!examStage) {
      setError("Please select exam stage");
      return;
    }

    onSubmit({ examStage , cameraEnabled, activityTrackerEnabled});
  };
  const [cameraPopoverOpen, setCameraPopoverOpen] = useState(false);
  const [activityPopoverOpen, setActivityPopoverOpen] = useState(false);
  const [cameraAnchorEl, setCameraAnchorEl] = useState(null);
  const [activityAnchorEl, setActivityAnchorEl] = useState(null);
  const [cameraEnabled, setcameraEnabled] = useState(false);
  const [activityTrackerEnabled, setactivityTrackerEnabled] = useState(false);
  const handleCameraPopoverOpen = (event: any) => {
    setCameraPopoverOpen(true);
    setCameraAnchorEl(event.currentTarget);
  };

  const handleCameraPopoverClose = () => {
    setCameraPopoverOpen(false);
    setCameraAnchorEl(null);
  };

  const handleActivityPopoverOpen = (event: any) => {
    setActivityPopoverOpen(true);
    setActivityAnchorEl(event.currentTarget);
  };

  const handleActivityPopoverClose = () => {
    setActivityPopoverOpen(false);
    setActivityAnchorEl(null);
  };
  const handleCameraCheckboxChange = (event: any) => {
    setcameraEnabled(event.target.checked);
  };

  const handleActivityCheckboxChange = (event: any) => {
    setactivityTrackerEnabled(event.target.checked);
  };

  const levelOptions = [
    { label: "LEVEL 1 - Easy exam", value: "LEVEL 1" },
    { label: "LEVEL 2 - Hard exam", value: "LEVEL 2" },
    { label: "LEVEL 3 - Moderate exam", value: "LEVEL 3" },
    { label: "LEVEL 4 - Challenging exam", value: "LEVEL 4" },
  ];

  const extraTimeOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: 20,
          paddingBottom: 60,
          paddingLeft: 32,
          paddingRight: 32,
        }}
      >
        <label
          style={{
            fontFamily: "Roboto",
            fontSize: 18,
            alignSelf: "center",
            marginBottom: 20,
            color: "#e71919",
            fontWeight: "700",
          }}
        >
          {error}
        </label>

        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <div style={{ display: "flex", flex: 1 }}>
            <DropDown
              value={examStage}
              options={levelOptions}
              label="Exam Stage"
              handleChange={(e: any) => {
                setExamStage(e.value);
                setError("");
              }}
              showLabel={true}
            />
          </div>
          <div style={{ width: 50 }} />
        </div>
        <div style={{ display: "flex", marginBottom: "1rem" }}>
          <FormGroup style={{ display: "flex", flexDirection: "row" }}>
            <FormControlLabel
              aria-owns={cameraPopoverOpen ? "mouse-over-popover1" : undefined}
              onMouseEnter={handleCameraPopoverOpen}
              onMouseLeave={handleCameraPopoverClose}
              control={<Checkbox onChange={handleCameraCheckboxChange} />}
              label="Camera Required?"
            />
            <Popover
              id="mouse-over-popover1"
              sx={{
                pointerEvents: "none",
              }}
              open={cameraPopoverOpen}
              anchorEl={cameraAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handleCameraPopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 1 }}>
                Candidates will be recorded and footage will be sent to the
                recruiter
              </Typography>
            </Popover>
            <FormControlLabel
              aria-owns={
                activityPopoverOpen ? "mouse-over-popover2" : undefined
              }
              onMouseEnter={handleActivityPopoverOpen}
              onMouseLeave={handleActivityPopoverClose}
              control={<Checkbox onChange={handleActivityCheckboxChange} />}
              label="Screen Activity Monitored?"
            />
            <Popover
              id="mouse-over-popover2"
              sx={{
                pointerEvents: "none",
              }}
              open={activityPopoverOpen}
              anchorEl={activityAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handleActivityPopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 1 }}>
                Candidate's mouse and screen activity will be tracked and sent
                to recruiter
              </Typography>
            </Popover>
          </FormGroup>
          {/* <div>Camera On: {cameraEnabled ? "Yes" : "No"}</div>
          <div>Activity On: {activityTrackerEnabled ? "Yes" : "No"}</div> */}
        </div>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <div style={{ width: 50 }} />
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "flex-end",
            }}
          >
            <Button
              value="Submit"
              className="test-details-submit-button"
              handleClick={handleEmail}
              styles={{ width: 200, height: 55 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
