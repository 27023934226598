//Sachin Dhalani - [12/4/2023] - Home page for admin dashboard
import React, { useState } from "react";
import "./style.css";
import { ADD_RECRUITERS, NAME } from "../Constants/constants";
import { Button } from "../../Common/Button/Button";
import { Modal } from "../../Common/Modal/Modal";
import { CreateRecruiter } from "./CreateRecruiter";
import { useAppSelector } from "../../hooks/app";
import { RootState } from "../../redux/store";
import { ShowCandidates } from "./ShowCandidates";
import { useNavigate } from "react-router-dom";
//RiArrowRightCircleFill
interface Props {
  admins?: any;
  candidates?: any;
  OrganizationCandidate?: any;
}
export const Home: React.FC<Props> = ({ admins, OrganizationCandidate }) => {
  // console.log("admins from dashboard: ", admins);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editRecruiter, setEditRecruiter] = useState<boolean>(false);
  const [candidateData, setCandidateData] = useState<any>();
  const [editData, setEditData] = useState<any>();
  const [showCandidateModels, setShowCandidateModels] =
    useState<boolean>(false);
  const navigate = useNavigate();
  let jsonInput = { isCreate: true };
  const handleAssessment = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setEditRecruiter(false);
    setShowCandidateModels(false);
  };
  const handleEdit = (result: any) => {
    setEditRecruiter(true);
    setEditData(result);
  };
  console.log("OrganizationCandidate: ", OrganizationCandidate);
  const handleShowCandidate = (data: any) => {
    console.log("id: ", data);
    if (data) {
      let test = OrganizationCandidate.filter(
        (element: any) => element.recruiter_id === data.id
      );
      setCandidateData(test);
      setShowCandidateModels(true);
    }
  };

  const handleExamDetailClick = (data: any) => {
    navigate("/candidates", { state: { newData: data } });
  };
  // console.log('test: ', candidateData);
  return (
    <div className="dashboard">
      <div className="heading">
        <p className="primary-texts">My Recruiters</p>
        <div className="button-create-assessment">
          <Button
            value={ADD_RECRUITERS}
            className="create-assessment-btn"
            handleClick={handleAssessment}
          />
          <Button
            value="+"
            className="create-assessment-mobile-btn"
            handleClick={handleAssessment}
          />
        </div>
      </div>
      <div>
        <div className="assessment-record">
          <div className="col-sm-12 table-parent-div">
            <div className="table-responsive" style={{ overflowX: "scroll" }}>
              <table className="table table-bordered table-display-candidate">
                <thead className="display-candidate-thead">
                  <tr>
                    <th scope="col" className="w-auto">{NAME}</th>
                    <th scope="col" className="w-auto">Email</th>
                    <th scope="col" className="w-auto">UserName</th>
                    <th scope="col" className="w-auto">Contact</th>
                    <th scope="col" className="w-auto">Edit</th>
                    <th scope="col" className="w-auto">All Candidates</th>
                    <th scope="col" className="w-auto">Completed Exam</th>
                  </tr>
                </thead>
                <tbody>
                  {admins?.length > 0 ? (
                    admins?.map((result: any, index: any) => (
                      <tr className="display-candidate-tr" key={index}>
                        <td className="text-nowrap">
                          <p className="fw-normal mb-1">
                            {result?.firstName + " " + result?.lastName}
                          </p>
                        </td>
                        <td className="text-nowrap">
                          <p className="fw-normal mb-1">{result?.email}</p>
                        </td>
                        <td className="text-nowrap">{result?.username}</td>
                        <td className="text-nowrap">{result?.contact}</td>
                        <td className="text-nowrap">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                            cursor={"pointer"}
                            onClick={() => handleEdit(result)}
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                          </svg>
                        </td>
                        <td className="Show-Candidate-Btn-div text-nowrap">
                          <Button
                            value="View"
                            handleClick={() => handleShowCandidate(result)}
                          />
                        </td>
                        <td className="Show-Candidate-Btn-div text-nowrap">
                          <Button
                            value="Exam Details"
                            handleClick={() => handleExamDetailClick(result)}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <p className="no-records-found-error-message">
                      No Records Found
                    </p>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {showModal ? (
            <Modal
              children={
                <CreateRecruiter
                  isUpdate={false}
                  editedData={jsonInput}
                  onSubmit={() => setShowModal(false)}
                />
              }
              showModalHeader={true}
              modalHeader={"Add Recruiter"}
              isFlexible={true}
              topRightCloseButtonID={"x-  "}
              showModal={true}
              showBackButton={true}
              showBBPSLogo={true}
              handleBackClick={handleCloseModal}
            ></Modal>
          ) : null}
          {editRecruiter ? (
            <Modal
              children={
                <CreateRecruiter
                  editedData={editData}
                  isUpdate={true}
                  onEdit={() => setEditRecruiter(false)}
                />
              }
              showModalHeader={true}
              modalHeader={"Update Recruiter"}
              isFlexible={true}
              topRightCloseButtonID={"x-  "}
              showModal={true}
              showBackButton={true}
              showBBPSLogo={true}
              handleBackClick={handleCloseModal}
            ></Modal>
          ) : null}

          {showCandidateModels ? (
            <Modal
              modalStyle={{ width: "1000px" }}
              showModal={true}
              children={
                <ShowCandidates
                  recruiters={admins}
                  candidateData={candidateData}
                  onSubmit={() => setShowCandidateModels(false)}
                />
              }
              showModalHeader={true}
              modalHeader={"Candidates"}
              isFlexible={true}
              topRightCloseButtonID={"x-  "}
              showBackButton={true}
              showBBPSLogo={true}
              handleBackClick={handleCloseModal}
            ></Modal>
          ) : null}
        </div>
      </div>
    </div>
  );
};
