import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import { Button } from "../../Common/Button/Button";
import { Modal } from "../../Common/Modal/Modal";
import { TestDetails } from "./TestDetails";
import { useAppDispatch } from "../../hooks/app";
import { sendInvitation } from "../../actions/candidate";
import java_icon from "./assets/java.png";
import javascript_icon from "./assets/javascript.png";
import python_icon from "./assets/python.png";
import android_icon from "./assets/android.png";
import swift_icon from "./assets/swift.png";
import dotnet_icon from "./assets/dotnet.png";
import claung_icon from "./assets/clang.png";
import react_icon from "./assets/react.png";
import { displayAllAssessmentByExamIdForAdmin } from "../../actions/assessment";
import { DropDown } from "../../Common/DropDown/DropDown";
import { examTypes } from "../../constants/constants";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@material-tailwind/react";
interface Props {
  orgTest?: any;
  candidateData?: any;
  sampleTest?: any;
}
export const Home: React.FC<Props> = ({
  orgTest,
  candidateData,
  sampleTest,
}) => {
  // useEffect(() => {
  //   examTypes.push({
  //     label: "All",
  //     value: "All",
  //   });
  // })
  console.log("sampleTest: ", sampleTest);
  console.log("orgTest: ", orgTest);
  const [examStage, setExamStage] = useState("LEVEL 1");
  const [extraTimeAllowed, setExtraTimeAllowed] = useState("true");
  const [extraTime, setExtraTime] = useState("0");
  const [cameraEnabled, setcameraEnabled] = useState(false);
  const [activityTrackerEnabled, setactivityTrackerEnabled] = useState(false);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDetailModal, setOpenDetailModel] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const userData = location.state;
  const [imageState, setImageState] = useState();
  const [data, setData] = useState();
  const [name, setName] = useState();
  const [selectedTest, setSelectedTest] = useState<any>(undefined);
  const [type, setType] = useState("All");
  const handleTest = (id: any, name: any, data: any = null) => {
    setSelectedTest(data);
    setData(id);
    setName(name);
    // setOpenDetailModel(true);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenDetailModel(false);
  };

  const dispatch = useAppDispatch();
  const sendAssessment = () => {
    const body: any = {
      examId: selectedTest.id,
      examStage: examStage,
      candidateExamType: selectedTest.type,
      extraTimeAllowed: extraTimeAllowed === "Yes" ? true : false,
      extraTime: extraTimeAllowed ? extraTime : null,
      validTimeInHours: 24,
      cameraEnabled: cameraEnabled,
      activityTrackerEnabled: activityTrackerEnabled,
    };

    if (userData) {
      body["candidateId"] = userData.id;
    } else {
      body["candidateEmail"] = userData.id;
      body["candidateFirstName"] = userData.id;
      body["candidateLastName"] = userData.id;
      body["candidateMiddleName"] = userData.id;
      body["candidateBirthDate"] = userData.id;
      body["candidateContact"] = userData.id;
      body["contact_country_code"] = userData.id;
    }

    dispatch(sendInvitation(body));
    setOpenDetailModel(false);
    navigate(-1);
  };
  const getDetailModel = () => {
    if (selectedTest && userData) {
      return (
        <div className="test-question-model-details">
          <h4 className="organization-test-question-heading">Exam Details</h4>
          <div className="test-question-details-div">
            <div className="test-question-details-label">
              <label className="exam-details-label-text">Exam Name:</label>
              <label className="exam-details-value-text">
                {selectedTest.name}
              </label>
            </div>
            <div className="test-question-details-label">
              <label className="exam-details-label-text">Exam Type:</label>
              <label className="exam-details-value-text">
                {selectedTest.type ? selectedTest.type : "-"}
              </label>
            </div>
          </div>
          <div className="test-question-details-div">
            <div className="test-question-details-label">
              <label className="exam-details-label-text">Technology:</label>
              <label className="exam-details-value-text">
                {selectedTest.technology ? selectedTest.technology : "-"}
              </label>
            </div>
            <div className="test-question-details-label">
              <label className="exam-details-label-text">Category:</label>
              <label className="exam-details-value-text">
                {selectedTest.category ? selectedTest.category : "-"}
              </label>
            </div>
          </div>
          <div className="test-question-details-div">
            <div className="test-question-details-label">
              <label className="exam-details-label-text">Default Exam:</label>
              <label className="exam-details-value-text">
                {selectedTest.isDefault ? "Yes" : "No"}
              </label>
            </div>
            <div className="test-question-details-label">
              <label className="exam-details-label-text">Required Time:</label>
              <label className="exam-details-value-text">
                {selectedTest.requiredTime ? selectedTest.requiredTime : "-"}
              </label>
            </div>
          </div>

          <div className="test-question-details-div">
            <div className="test-question-details-label">
              <label className="exam-details-label-text">
                Difficulty Level:
              </label>
              <label className="exam-details-value-text">
                {selectedTest.difficultyLevel
                  ? selectedTest.difficultyLevel
                  : "-"}
              </label>
            </div>
          </div>

          <>
            <h4 className="organization-test-question-heading">
              Candidate Details
            </h4>
            <div className="test-question-details-div">
              <div className="test-question-details-label">
                <label className="exam-details-label-text">Name:</label>
                <label className="exam-details-value-text">
                  {userData.firstName + " " + userData.lastName}
                </label>
              </div>
              <div className="test-question-details-label">
                <label className="exam-details-label-text">Email:</label>
                <label className="exam-details-value-text">
                  {userData.email}
                </label>
              </div>
            </div>
            <div className="test-question-details-div">
              <div className="test-question-details-label">
                <label className="exam-details-label-text">Contact:</label>
                <label className="exam-details-value-text">
                  {userData.contact ? userData.contact : "-"}
                </label>
              </div>
              <div className="test-question-details-label">
                <label className="exam-details-label-text">Organization:</label>
                <label className="exam-details-value-text">-</label>
              </div>
            </div>
            <div className="test-question-details-div">
              <div className="test-question-details-label">
                <label className="exam-details-label-text">
                  Recruiter Name:
                </label>
                <label className="exam-details-value-text">
                  {userData.recruiter_name ? userData.recruiter_name : "-"}
                </label>
              </div>
            </div>
            <div className="test-question-details-div">
              <div className="org-details-test-button">
                <Button
                  value="Cancel"
                  className="org-test-question-send-test-cancel-btn"
                  handleClick={() => setOpenDetailModel(false)}
                />
                <div style={{ width: 20 }} />

                <Button
                  className="org-test-question-send-test-btn"
                  value="Send Test"
                  handleClick={sendAssessment}
                />
              </div>
            </div>
          </>
        </div>
      );
    } else {
      return <></>;
    }
  };
  const getTechnologyInfo = (type: any) => {
    switch (type) {
      case "Java":
        return java_icon;
      case "Javascript":
        return javascript_icon;
      case "ReactJs":
        return react_icon;
      case "Python":
        return python_icon;
      case "Android":
        return android_icon;
      case "Swift":
        return swift_icon;
      case "MySql":
        return claung_icon;
      case "C Launguage":
        return claung_icon;
      case ".Net":
        return dotnet_icon;
      default:
        return "test";
    }
  };
  const handleViewTest = (data: any) => {
    navigate("/display-created-assessment", { state: data });
    dispatch(displayAllAssessmentByExamIdForAdmin(data.id));
  };
  let tempOrgTest = orgTest?.filter((element: { type: any }) => {
    if (element.type === type) return true;
  });
  orgTest = orgTest?.filter((element: { type: any }) => {
    if (element.type === type) {
      return true;
    }
    if (type === "All") {
      return true;
    }
  });
  let tempSampleTest = sampleTest?.filter((element: { type: any }) => {
    if (element.type === type) return true;
  });
  sampleTest = sampleTest?.filter((element: { type: any }) => {
    if (element.type === type) {
      return true;
    }
    if (type === "All") {
      return true;
    }
  });
  return (
    <div className="test-questions-home">
      <div className="heading">
        {/* <p className="primary-texts">{TEST_HEADING}</p> */}
        <p className="primary-texts">Sample Assessment</p>
        <DropDown
          className="drop-down-menu-main"
          value={type}
          options={examTypes}
          noOptionsText={"No Exam Type found"}
          label="Assessment Type"
          handleChange={(e: any) => {
            setType(e.value);
          }}
          showLabel={true}
        />
      </div>
      {/* <div>
        <button
          className="add-candidates-button"
          onClick={() => navigate("/create-assessment")}
        >
          Create Assessment
        </button>
      </div> */}
      <div className="Test-views">
        <div className="item-container">
          {console.log("orgTest after filter: ", orgTest)}
          {orgTest?.length > 0
            ? userData
              ? orgTest?.map((data: any, index: any) => (
                  <div className="card" key={index}>
                    <img
                      src={getTechnologyInfo(data.technology)}
                      style={{
                        height: 40,
                        width: 40,
                        display: "flex",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    <p className="primary-text"> {data.name}</p>

                    <Button
                      className="select-test-button"
                      value="Select Test"
                      handleClick={() => handleTest(data?.id, data?.name, data)}
                    />
                  </div>
                ))
              : orgTest?.map((data: any, index: any) => (
                  <div className="card" key={index}>
                    <img
                      src={getTechnologyInfo(data.technology)}
                      style={{
                        height: 40,
                        width: 40,
                        display: "flex",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    <p className="primary-text"> {data.name}</p>

                    {/* <Link to="/questions"> */}
                    <Button
                      value="View"
                      className="select-test-button"
                      handleClick={() => handleViewTest(data)}
                    />
                    {/* <VisibilityIcon className="eye-icon" /> */}
                    {/* </Link> */}
                  </div>
                ))
            : "No Test Found"}
        </div>
        <div>
          {/* <hr/>
            <p className="P-tag-view-Test">______________________Test Created By Braven-Tool_____________________</p>
            <hr/> */}
          <h4 className="Partition-line">Bravens Test</h4>
        </div>
        <div className="item-container-bravens">
          {/* ------------Test of braven admin */}
          {sampleTest?.length > 0
            ? userData
              ? sampleTest?.map((data: any, index: any) =>
                  !data.orgnizationId ? (
                    <div className="card" key={index}>
                      <img
                        src={getTechnologyInfo(data.technology)}
                        style={{
                          height: 40,
                          width: 40,
                          display: "flex",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                      <p className="primary-text"> {data.name}</p>

                      <Button
                        className="select-test-button"
                        value="Select Test"
                        handleClick={() =>
                          handleTest(data?.id, data?.name, data)
                        }
                      />
                    </div>
                  ) : null
                )
              : sampleTest?.map((data: any, index: any) =>
                  !data.orgnizationId ? (
                    <div className="card" key={index}>
                      <img
                        src={getTechnologyInfo(data.technology)}
                        style={{
                          height: 40,
                          width: 40,
                          display: "flex",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                      <p className="primary-text"> {data.name}</p>

                      {/* <Link to="/questions"> */}
                      <Button
                        value="View"
                        className="select-test-button"
                        handleClick={() => handleViewTest(data)}
                      />
                      {/* <VisibilityIcon className="eye-icon" /> */}
                      {/* </Link> */}
                    </div>
                  ) : null
                )
            : "No Test Found"}
        </div>
      </div>

      {openModal ? (
        <Modal
          children={
            <TestDetails
              data={data}
              name={name}
              onSubmit={(obj: any) => {
                setExamStage(obj.examStage);
                setExtraTime(obj.extraTime);
                setExtraTimeAllowed(obj.extraTimeAllowed);
                setcameraEnabled(obj.cameraEnabled);
                setactivityTrackerEnabled(obj.activityTrackerEnabled);
                setOpenModal(false);
                setOpenDetailModel(true);
              }}
            />
          }
          showModalHeader={true}
          isFlexible={true}
          modalHeader={name}
          topRightCloseButtonID={"x-  "}
          showModal={true}
          showBackButton={true}
          showBBPSLogo={true}
          handleBackClick={handleCloseModal}
        ></Modal>
      ) : null}

      {openDetailModal ? (
        <Modal
          children={getDetailModel()}
          showModalHeader={true}
          isFlexible={true}
          modalHeader={name}
          topRightCloseButtonID={"x-  "}
          showModal={true}
          showBackButton={true}
          showBBPSLogo={true}
          handleBackClick={handleCloseModal}
        ></Modal>
      ) : null}
      <div
        className="rounded-full bg-[#173380] border border-[#0087cd] p-3 m-4 fixed bottom-3 right-1 mt-10 cursor-pointer"
        onClick={() => {
          navigate("/create-assessment");
        }}
      >
        <Tooltip content="Create Assessment">
          <PlusIcon className="w-8 text-white font-semibold" />
        </Tooltip>
      </div>
    </div>
  );
};
