import React, { useEffect } from "react";
import { DashboardPage } from "./DashboardPage";
import { Navbar } from "./Navbar";
import { displayCandidate } from "../../actions/admin";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useAppDispatch } from "../../hooks/app";
export const RecruiterDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const candidates = useSelector(
    (state: RootState) => state.admin.adminDetails
    );
    console.log('candidates : ', candidates);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const displayAdmins = async () => {
    await dispatch(displayCandidate(user?.id));
  };
  useEffect(() => {
    displayAdmins();
  }, []);

  return (
    <div className="Dashboard-index">
      <Navbar />
      <DashboardPage candidates={candidates} />
      <div className="center-stepper"></div>
    </div>
  );
};
