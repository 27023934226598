import React, { useEffect, useState } from "react";
import { ASSESSMENT_HEADING } from "../Constants/constants";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { useAppDispatch, useAppSelector } from "../../hooks/app";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { Button } from "../../Common/Button/Button";
import { displayAssessmentSore } from "../../actions/assessment";
import { DataLoading } from "../../Common/DataLoading/DataLoading";

interface props {
  onSubmit?: any;
  candidateData?: any;
  recruiters?: any;
}
export const ShowCandidates: React.FC<props> = ({
  onSubmit,
  candidateData,
  recruiters,
}) => {
  const [selectedData, setSelectedData] = useState<any>();
  console.log("recruiter: ", recruiters);
  //displayAssessmentScore
  console.log("OrganizationCandidate from show candidates: ", candidateData);
  const candidateScoreData = useAppSelector(
    (state: RootState) => state.assessment.displayAssessmentScore
  );
  console.log("canidateData: ", candidateScoreData);

  // console.log("id::::",recruiters[0].id+""+candidateData[0].recruiter_id)

  const user = useAppSelector((state: RootState) => state.user.userDetails);
  // console.log('user from show candidate: ', user);
  const candidates = useAppSelector(
    (state: RootState) => state.assessment.displayCandidateCompletedStatus?.candidateList
    );
    console.log('candidates from show candidates: ', candidates);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleCandidateDetails = async (data: any) => {
    console.log("data: ", data);
    setSelectedData(data);
    dispatch(displayAssessmentSore(data.id));

    //   console.log('filterData: ', filterData);
  };

  useEffect(() => {
    if (selectedData) {
      const filterData: any = candidateScoreData.filter(
        (element: any) => element.candidateExam.candidateId === selectedData.id
      );

      // navigate("/candidate-details", {
      //   state: { data: selectedData, id: filterData[0].candidateExam.id },
      // });
    }
  }, [candidateScoreData]);

  return (
    <div className="dashboard">
      <div className="heading">
        {/* <p className="primary-texts">{ASSESSMENT_HEADING}</p> */}
        {/* <div className="button-create-assessment">
        <Link to="/create-assessment">
        <Button
        value={CREATE_ASSESSMENT_BUTTON}
        className="create-assessment-btn"
        handleClick={handleAssessment}
        />
        
        <Button
        value="+"
        className="create-assessment-mobile-btn"
        handleClick={handleAssessment}
        />
        </Link>
      </div> */}
      </div>
      <div>
        <div className="assessment-record">
          {/* {loadingState.loading ? <Loading /> : null} */}
          <table className="table table-bordered table-display-candidate">
            <thead className="display-candidate-thead">
              <tr>
                <th scope="col">Name</th>
                {/* <th scope="col">UserName</th> */}
                <th scope="col">Email</th>
                <th scope="col">Contact</th>
              </tr>
            </thead>
            <tbody>
              {candidateData?.length ? (
                candidateData?.map((result: any, index: any) => (
                  <tr className="display-candidate-tr" key={index}>
                    <td>
                      <p className="fw-normal mb-1">
                        {result?.firstName + " " + result?.lastName}
                      </p>
                    </td>
                    {/* <td>{result?.username}</td> */}
                    <td>{result?.email}</td>
                    <td>{result?.contact}</td>
                    {/* {result?.exam?.map((examStatus:any)=>{
                    examStatus.status === "COMPLETED"?
                    <Button
                  value='Details'
                  handleClick = {()=>handleCandidateDetails(result)}/>
                    :<Button
                    disabled 
                    value='Details'
                    handleClick = {()=>handleCandidateDetails(result)}/>
                  })} */}

                    {/* {result?.exam[0]?.status === "COMPLETED" ? (
                      <Button
                        className="AdminDashboard-Details-btn"
                        value="Details"
                        handleClick={() => {
                          handleCandidateDetails(result);
                        }}
                      />
                    ) : (
                      <Button
                        disabled
                        value="Not Attempted"
                        handleClick={() => handleCandidateDetails(result)}
                      />
                    )} */}
                  </tr>
                ))
              ) : (
                <p className="no-records-found-error-message">
                  No Records Found
                </p>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
