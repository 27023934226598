//Sachin Dhalani - [21/3/2023] - Constant File to declare all sample text

//Landing Page Heading
export const HIRING_HEADING = "We Make Hiring Easy!!";
//Landing Page Sub Heading
export const HIRING_SUB_HEADING =
  " Easy to Hire Talented Developers & Professional Agencies!!";
//Landing page Get Started button
export const GET_STARTED = "Let's Get Started";
//About us Learn More Button
export const LEARN_MORE = "Learn More";
//About us Watch Video Button
export const WATCH_VIDEO = "Watch Video";
//About Us heading
export const ABOUT_HEADING = "60+ Technologies Registered on our Platform";
//About us sample text
export const SAMPLE_TEXT =
  "Test candidates for job-specific skills like coding or digital marketing, as well as more general skills like critical thinking.";
//About us sample text1
export const SAMPLE_TEXT1 =
  "Our unique personality and value tests allow you to get to know your applicants as real people – not just pieces of paper.";
//Landing page Work heading
export const WORK_HEADING = "How It Works";
//Testimonial
export const TESTIMONIAL = "Testimonial";
//Testimonial heading
export const TESTIMONIAL_HEADING = "What They Are Saying";
//Recruiter Dashboard primary assessment heading
export const ASSESSMENT_HEADING = "My Candidates";
//Organizations Dashboard primary Organizations heading
export const ORGANIZATION_HEADING = "My Organizations";
//admin Dashboard heading
export const ORGANIZATION_NAME = "BravensTool";
//admin Dashboard heading
export const ADD_RECRUITERS = "Add Recruiters";
//Recruiter Dashboard Create assessment button
export const CREATE_ASSESSMENT_BUTTON = "Create New Assessment +";
//Recruiter Dashboard Candidate Name
export const NAME = "Name";
//Recruiter Dashboard Candidate Title
export const TITLE = "Title";
//Recruiter Dashboard Candidate Status
export const STATUS = "Status";
//Recruiter Dashboard Candidate Position
export const POSITION = "Position";
//Recruiter Dashboard Candidate Actions
export const ACTIONS = "Actions";
//Recruiter Dashboard Candidate Email
export const EMAIL = "Email";
//Recruiter Dashboard Candidate Remark
export const RESULT = "Result";
//Recruiter Dashboard Candidate Tests
export const TEST = "Test";
//Recruiter Dashboard Candidate attempts
export const ATTEMPTS = "Attempts";
//Recruiter Dashboard Candidate Last Activity
export const LAST_ACTIVITY = "Last Activity";
//Dashboard Candidates heading
export const CANDIDATE_HEADING = "Candidate Reports";
// Sample test heading
export const TEST_HEADING = "Organization Test";
//
export const WELCOME_TEXT =
  "Thank you for applying at Ampcus and welcome to our assessment.";
export const WELCOME_SUB_HEADING_TEXT =
  "Completing it will give you a chance to show off your skills and stand out from the crowd";
//
export const GOOD_LUCK = "Good luck!";
//Organization Dashboard Create Organization button
export const CREATE_ORGANIZATION_BUTTON = "Create New Organization +";
