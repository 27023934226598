//Sachin Dhalani - [14/4/2023]
import React, { useState } from "react";
import { Button } from "../../Common/Button/Button";
import { TextField } from "../../Common/TextField/TextField";
import { History } from "history"; //import History interface
import {
  isEmailValid,
  isMobileNumberValid,
  isNameValid,
  isTextValid,
} from "../../helpers/validate";
import "./style.css";
import { addUser } from "../../actions/admin";
import { useAppDispatch } from "../../hooks/app";

interface Props {
  history?: History; //Optional history parameter which is passed by the Router
  organizationId: number;
  onSubmit?: any;
}
export const CreateAdmin: React.FC<Props> = ({ organizationId, onSubmit }) => {
  //firstName variable in the state and setFirstName method for updating the token value
  const [firstName, setFirstName] = useState("");
  //LastName variable in the state and setLastName method for updating the token value
  const [lastName, setLastName] = useState("");
  //MiddleName variable in the state and setMiddleName method for updating the token value
  const [middleName, setMiddleName] = useState("");
  //userName in the state and setUserName method for updating the token value
  const [userName, setUserName] = useState("");
  //mobileNumber in the state and setNumber method for updating the token value
  const [contact, setContact] = useState<string>("");
  //birthDate in the state and setBirthDate method for updating the token value
  const [birthDate, setBirthDate] = useState<any>();
  //countryCode in the state and setCountryCode method for updating the token value
  const [contact_country_code, setContact_country_code] = useState("+91");
  //email variable in the state and setEmail method for updating the token value
  const [email, setEmail] = useState<string>("");
  //role in the state and setRole method for updating the token value
  const [role, setRole] = useState<string>("ADMIN");
  //emailError variable in the state and setEmailError method for updating the token value
  const [emailError, setEmailError] = useState<string>("");
  //firstNameError variable in the state and setFirstNameError method for updating the token value
  const [firstNameError, setFirstNameError] = useState<string>("");
  //lastNameError variable in the state and setLastNameError method for updating the token value
  const [lastNameError, setLastNameError] = useState<string>("");
  //userNameError variable in the state and setUserNameError method for updating the token value
  const [userNameError, setUserNameError] = useState<string>("");
  //middleNameError variable in the state and setMiddleNameError method for updating the token value
  const [middleNameError, setMiddleNameError] = useState<string>("");
  //contactError variable in the state and setContactError method for updating the token value
  const [contactError, setContactError] = useState<string>("");

  const dispatch = useAppDispatch();
  const handleClick = async () => {
    setRole("ADMIN");
    //check if email is valid
    const emailValid = isEmailValid(email);
    //check if password is valid
    const firstNameValid = isTextValid(firstName);
    //check if last name is valid
    const lastNameValid = isTextValid(lastName);
    //check if user name is valid
    const userNameValid = isTextValid(userName);
    //check if middle name is valid
    const contactValid = isMobileNumberValid(contact);
    //If valid, clear error states and execute the signUp action creator
    if (
      emailValid &&
      firstNameValid &&
      lastNameValid &&
      userNameValid &&
      contactValid
    ) {
      setEmailError("");
      setFirstNameError("");
      setLastNameError("");
      setUserNameError("");
      setContactError("");
      dispatch(
        addUser(
          firstName,
          middleName,
          lastName,
          email,
          userName,
          contact,
          birthDate,
          contact_country_code,
          role,
          organizationId
        )
      );
      onSubmit();
    }
    //if email,firstName,lastName or password is invalid, set errors in the state for whatever is invalid
    else {
      if (!emailValid) {
        setEmailError("Email is invalid!");
      }
      if (!firstNameValid) {
        setFirstNameError("First Name is invalid!");
      }

      if (!lastNameValid) {
        setLastNameError("Last Name is invalid!");
      }
      if (!userNameValid) {
        setUserNameError("User Name is invalid!");
      }

      if (!contactValid) {
        setContactError("Contact is invalid!");
      }
    }
  };
  return (
    <div className="create-admin-page">
      <div className="add-admin-parent">
        <div className="add-admin-left" style={{ width: "295px" }}>
          <TextField
            value={firstName}
            placeholder="Enter First Name"
            label="First Name"
            required={true}
            handleChange={(e) => setFirstName(e.target.value)}
          />
          <p className="validation-error">{firstNameError}</p>
        </div>
        <div className="add-admin-right">
          <TextField
            value={middleName}
            placeholder="Enter Middle Name"
            label="Middle Name"
            handleChange={(e) => setMiddleName(e.target.value)}
          />
          {/* <p className="validation-error">{middleNameError}</p> */}
        </div>
      </div>

      <div className="add-admin-parent">
        <div className="add-admin-left" style={{ width: "320px" }}>
          <TextField
            value={lastName}
            placeholder="Enter Last Name"
            label="Last Name"
            required={true}
            handleChange={(e) => setLastName(e.target.value)}
          />
          <p className="validation-error">{lastNameError}</p>
        </div>
        <div className="add-admin-right">
          <TextField
            value={contact}
            placeholder="93239*****"
            label="Contact Number"
            required={true}
            handleChange={(e) =>
              setContact(e.target.value.replace(/[^0-9]/gi, ""))
            }
          />
          <p className="validation-error">{contactError}</p>
        </div>
      </div>

      <div className="add-admin-parent">
        <div className="add-admin-left">
          <TextField
            value={email}
            placeholder="Enter Email Address"
            label="Email"
            required={true}
            handleChange={(e) => setEmail(e.target.value.replace(/\s/g, ""))}
          />
          <p className="validation-error">{emailError}</p>
        </div>
        <div className="add-admin-right">
          <TextField
            value={userName}
            placeholder="Enter UserName"
            label="User Name"
            required={true}
            handleChange={(e) => setUserName(e.target.value)}
            // type="email"
          />
          <p className="validation-error">{userNameError}</p>
        </div>
      </div>

      <div className="add-candidate-page-container">
        <div className="create-admin-submit-btn">
          <Button
            value="Submit"
            handleClick={handleClick}
            className="add-candidate-submit-btn"
          />
        </div>
      </div>
    </div>
  );
};
