import React, { useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useAppDispatch } from '../../hooks/app';
import { getImageArray } from '../../actions/camera';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { API_URL } from '../../actions/serverConnection';
import axios from 'axios';
import { DataLoading } from '../../Common/DataLoading/DataLoading';
import { InfinitySpin, ThreeCircles } from 'react-loader-spinner';
import Swal from 'sweetalert2';

interface Props {
  exam_candidate_id: any
}
// chetan patil - [23-8-2023] - images and videos getting from firebase console realtime database 
export const CaptureSlider: React.FC<Props> = ({ exam_candidate_id }) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>();

  useEffect(() => {
    
    dispatch(getImageArray(exam_candidate_id));
  }, [getImageArray])

  const imageObj = useSelector((state: RootState) => state.camera.cameraDetails);
  

  const processedImageObject = [];
  const processedVideoObject = [];

  if (imageObj != null) {
    if (imageObj.imageString != undefined) {
      
      let imageArray: string[] = [];
      let videoArray: string[] = [];

      let imageString: string = imageObj.imageString;
      let videoString: string = imageObj.videoString;
      imageArray = imageString.split(" ");
      
      videoArray = videoString.split(" ");

      for (var i = 0; i < imageArray?.length; ++i) {
        let url = { API_URL }.API_URL + "/" + imageArray[i];
        processedImageObject.push(
          <div>
            <img src={url} />
            <p className="legend"> Screenshot {i + 1} </p>
          </div>
        );
      }

      for (var i = 0; i < videoArray?.length; ++i) {
        let url = { API_URL }.API_URL + "/" + videoArray[i];
        processedVideoObject.push(
          <div style={{ width: "400px" }}>
            <video width={"400px"} src={url} autoPlay loop controls />
          </div>
        );
      }
    } else {
      let url = { API_URL }.API_URL + "/";
      processedImageObject.push(
        <div style={{ width: "250px" }}>
          <img src={url + "uploads/screenshots/Image_not_found.jpg"} />
        </div>
      );
      processedVideoObject.push(
        <div style={{ width: "400px" }}>
          <img src={url + "uploads/videos/Video_not_found.jpg"} />
        </div>
      );
    }
  } else {
    let url = { API_URL }.API_URL + "/";
    processedImageObject.push(
      <div style={{ width: "250px" }}>
        <img src={url + "uploads/screenshots/Image_not_found.jpg"} />
      </div>
    );
    processedVideoObject.push(
      <div style={{ width: "400px" }}>
        <img src={url + "uploads/videos/Video_not_found.jpg"} />
      </div>
    );
  }
  
  

  const fetchData = async () => {
    const databaseURL =
      "https://bravenstoolimageupload-default-rtdb.firebaseio.com//users.json?auth=AIzaSyCi1tuVZ9XCt46GzUhbcqonI6XlAG-sSKo";

    try {
      const response = await axios.get(`${databaseURL}.json`);
      setData(response.data);
      
    } catch (error) {
      
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  let imagesAcc: any[] = [];
  let videosAcc: any[] = [];

  

  const jsonArray = data && (Object.keys(data).map(key => data[key]));
  

  jsonArray?.map((ele: any) => {
    if (ele?.exam_candidate_ids === exam_candidate_id) {
      for (let i = 0; i < ele?.images?.length; i++) {
        imagesAcc?.push(
          <div style={{ width: "100%", height: "300px" }}>
            <img src={ele?.images[i]} style={{ width: "500px" }} />
          </div>
        );
      }
      for (let i = 0; i < ele?.videos?.length; i++) {
        videosAcc?.push(
          <div style={{ width: "100%", height: "300px" }}>
            <video controls width={510} height={300}>
              <source src={ele?.videos[i]} type="video/mp4" />
            </video>
          </div>
        );
      }
    }
  })
  
  


  return (
    <>
      {data ? (
        <>
          <div className="carousel-column-images" >
            <Carousel infiniteLoop={true} >
              {imagesAcc}
            </Carousel>
          </div>
          <div className="carousel-column-videos" >
            <Carousel infiniteLoop={true}>
              {videosAcc}
            </Carousel>
          </div>
        </>
      ) : (
        // <ThreeCircles
        //   height="100"
        //   width="100"
        //   color="#1f7ebc"
        //   wrapperStyle={{strokeWidth:"5px"}}
        //   wrapperClass=""
        //   visible={true}
        //   ariaLabel="three-circles-rotating"
        //   outerCircleColor=""
        //   innerCircleColor=""
        //   middleCircleColor=""
        // />
          <div>
            <center>
        <InfinitySpin
          width='200'
            color="#1f7ebc"
              />
            </center>
            <center><p>Loading images and videos</p></center>
        </div>
      )}
    </>
  );
};