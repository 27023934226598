import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer, toast } from "react-toastify";
import { languageOptions } from "../../constants/languageOptions";
import useKeyPress from "../../hooks/useKeyPress";
import { CodeEditorWindow } from "./CodeEditorWindow";
import { LanguagesDropdown } from "./LanguagesDropdown";
import { OutputWindow } from "./OutputWindow";
import { CustomInput } from "./CustomInput";
import { OutputDetails } from "./OutputDetails";
import { Button } from "../../Common/Button/Button";
import { defaultCode } from "./DefaultCode";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  createCandidateTrackerDetails,
  submitAnswer,
  updateCandidateStatus,
} from "../../actions/assessment";
import { useAppDispatch } from "../../hooks/app";
import Box from "@mui/material/Box";
import Swal from "sweetalert2";
import ResultImage from "../../Assets/result-gif.gif";
import { TextArea } from "../../Common/TextArea/TextArea";
import { sendImageArray } from "../../actions/camera";
import Typography from "@mui/material/Typography";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

interface Props {
  capturedPhotos: string[];
  capturedBase64Videos: string[];
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const Landing: React.FC<Props> = ({
  capturedPhotos,
  capturedBase64Videos,
}) => {
  const [showResults, setShowResults] = useState(false);
  const [outputDetails, setOutputDetails] = useState<any>("");
  // 
  const [processing, setProcessing] = useState(Boolean);
  const [theme, setTheme] = useState("vs-dark");
  const [language, setLanguage] = useState(languageOptions[0]);
  const [code, setCode] = useState(defaultCode[language.value]);
  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [status, setStatus] = useState("");
  // const questions,setQuestions]=useState([]);

  const [isTabActive, setIsTabActive] = useState(true);
  let [count, setCount] = useState<any>(0);
  let [countOutsideWindow, setCountOutsideWindow] = useState<any>(0);

  // chetan patil [16-6-2023] = following variables are taken for anti cheating monitor and assessment timer

  let [mouseMovedOutside, setMouseMovedOutside] = useState<boolean>(false);
  let [countMouseMovedOutside, setCountMouseMovedOutside] = useState<any>(0);
  let [switchingTab, setSwitchingTab] = useState<boolean>(false);
  let [countSwitchingTab, setCountSwitchingTab] = useState<any>(0);
  let [fullScreenMode, setFullScreenMode] = useState<boolean>(true);
  let [ipAddress, setIp] = useState<any>();

  // 
  const state = useLocation();
  const dispatch = useAppDispatch();
  let codeData = state?.state?.questions[0] ? state?.state?.questions[0] : "";
  // 

  let trackingDetails = state?.state?.trackerData;
  // 

  const exam_candidate_id = trackingDetails?.exam_candidate_id;
  const deviceUsed = trackingDetails?.deviceUsed;
  const latitude = trackingDetails?.latitude;
  const longitude = trackingDetails?.longitude;
  const webCamEnabled = trackingDetails?.webCamEnabled;
  // const ipAddress = trackingDetails.ipAddress;
  // 

  const [timer, setTimer] = useState<any>(0);
  const [sec, setSec] = useState<any>();
  const [min, setMin] = useState<any>();
  const webcamRef: any = useRef(null);
  // 

  const Ref = useRef<any>(null);

  const RAPID_API_KEY = "d601295ce1msh16dae3f5f78fa6dp152f3bjsnd03bb47b42e9";

  const assessmentObj = useSelector((state: RootState) => state.assessment);
  const cameraEnabled =
    assessmentObj?.displayAssessmentExam?.candidateExam?.cameraEnabled;
  
  const tracker =
    assessmentObj?.displayAssessmentExam?.candidateExam?.activityTrackerEnabled;
  
  const questions = state?.state?.questions;
  //----- console.log(
  //   "question navigate:::::",
  //   assessmentObj.displayAssessmentExam?.candidateExam.id
  // );
  const [customInput, setCustomInput] = useState(""); //questions[currentQuestion].options[0].testCaseInput
  const examDetails = assessmentObj.displayAssessmentExam?.exam;
  // 
  // 
  // 

  const [sidebarWidth, setSidebarWidth] = useState<number>(500);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [initialClickX, setInitialClickX] = useState<number>(0);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isChecked, setIsChecked] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);
  const [submissions, setSubmissions] = useState(
    Array(questions?.length)?.fill(false)
  );

  useEffect(() => {
    if (!tracker) {
      return;
    } else {
      const handleMouseMove = (event: MouseEvent) => {
        if (!isResizing || !sidebarRef.current) return;
        const sidebarRect = sidebarRef.current.getBoundingClientRect();
        const sidebarWidthDelta = event.clientX - initialClickX;
        const newSidebarWidth = sidebarRect.width + sidebarWidthDelta;
        setSidebarWidth(newSidebarWidth);
        setInitialClickX(event.clientX);
      };

      const handleMouseUp = () => {
        setIsResizing(false);
      };

      if (isResizing) {
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
        document.body.style.userSelect = "none";
      } else {
        document.body.style.userSelect = "";
      }

      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        document.body.style.userSelect = "";
      };
    }
  }, [isResizing, initialClickX, tracker]);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsResizing(true);
    setInitialClickX(event.clientX);
  };

  const handleNext = () => {
    if (currentQuestion + 1 < questions?.length) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const onSelectChange = (sl: any) => {
    
    setLanguage(sl);
    setCode(defaultCode[sl.value]);
    
    
  };

  const onChange = (action: any, data: any) => {
    switch (action) {
      case "code": {
        setCode(data);
        
        
        break;
      }
      default: {
        
      }
    }
  };

  const checkStatus = async (token: any) => {
    const options = {
      method: "GET",
      url: `https://judge0-ce.p.rapidapi.com/submissions/${token}`,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "X-RapidAPI-Key": RAPID_API_KEY,
        "X-RapidAPI-Host": "judge0-ce.p.rapidapi.com",
      },
    };
    try {
      let response = await axios.request(options);
      let statusId = response.data.status?.id;
      if (statusId === 1 || statusId === 2) {
        setTimeout(() => {
          checkStatus(token);
        }, 2000);
        return;
      } else {
        setProcessing(false);
        setOutputDetails(response.data);
        
        
        showSuccessToast(`Compiled Successfully!`);
        return;
      }
    } catch (err) {
      setProcessing(false);
      // showErrorToast();
    }
  };

  const handleCompile = () => {
    setProcessing(true);
    const formData = {
      language_id: language.id,
      source_code: window.btoa(code),
      stdin: window.btoa(customInput),
    };
    const options = {
      method: "POST",
      url: "https://judge0-ce.p.rapidapi.com/submissions",
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "content-type": "application/json",
        "Content-Type": "application/json",
        "X-RapidAPI-Key": RAPID_API_KEY, //8ea2c56383mshcdd268cf7f0c9dfp10293fjsn29c648b08b80
        "X-RapidAPI-Host": "judge0-ce.p.rapidapi.com",
      },
      data: formData,
    };
    axios
      .request(options)
      .then(function (response) {
        const token = response?.data?.token;
        checkStatus(token);
      })
      .catch(function (error) {
        
        setProcessing(false);
      });
  };
  useEffect(() => {
    if (enterPress && ctrlPress) {
      handleCompile();
    }
  }, [ctrlPress, enterPress]);

  const outputCheck = (output: any, index: number) => {
    const expected_output =
      questions[currentQuestion].options[index].testCaseOutput;
    // 
    
    return output.trim() == expected_output.trim();
  };

  const submitTestCase = (index: number) => {
    let output = window.atob(outputDetails.stdout).trim();
    let correct = outputCheck(output, index);
    let body = undefined;
    body = {
      examId: codeData.examId,
      candidateExamId: assessmentObj.displayAssessmentExam?.candidateExam.id,
      questionId: questions[currentQuestion].id,
      questionType: "CODE",
      // questionOptionId: [codeData.options[index].id],
      questionOptionId: [questions[currentQuestion].options[index].id],
      textAnswer: code,
      userOutput: window.atob(outputDetails.stdout).trim(),
      isCorrect: correct,
      testCaseOutput: questions[currentQuestion].options[index].testCaseOutput,
      testCaseInput: questions[currentQuestion].options[index].testCaseInput,
    };
    
    if (body) {
      dispatch(submitAnswer(body));
    }
    handleNext();
  };
  const compileAllTestCase = (input: any, index: number) => {
    setProcessing(true);
    const formData = {
      language_id: language.id,
      source_code: window.btoa(code),
      stdin: window.btoa(input),
    };
    const options = {
      method: "POST",
      url: "https://judge0-ce.p.rapidapi.com/submissions",
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "content-type": "application/json",
        "Content-Type": "application/json",
        "X-RapidAPI-Key": RAPID_API_KEY, // "139cfb9fa0msh59b795435ada9bfp1d0a36jsnc2628025d6df", //8ea2c56383mshcdd268cf7f0c9dfp10293fjsn29c648b08b80
        "X-RapidAPI-Host": "judge0-ce.p.rapidapi.com",
      },
      data: formData,
    };
    axios
      .request(options)
      .then(function (response) {
        const token = response?.data?.token;
        checkStatus(token).then(() => {
          submitTestCase(index);
        });
      })
      .catch(function (error) {
        
        setProcessing(false);
      });
  };
  const compileSampleTests = (input: any, index: number) => {
    setProcessing(true);
    const formData = {
      language_id: language.id,
      source_code: window.btoa(code),
      stdin: window.btoa(input),
    };
    const options = {
      method: "POST",
      url: "https://judge0-ce.p.rapidapi.com/submissions",
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "content-type": "application/json",
        "Content-Type": "application/json",
        "X-RapidAPI-Key": RAPID_API_KEY, //"139cfb9fa0msh59b795435ada9bfp1d0a36jsnc2628025d6df", //8ea2c56383mshcdd268cf7f0c9dfp10293fjsn29c648b08b80
        "X-RapidAPI-Host": "judge0-ce.p.rapidapi.com",
      },
      data: formData,
    };
    axios
      .request(options)
      .then(function (response) {
        const token = response?.data?.token;
        checkStatus(token).then(() => {
          // 
        });
        //prints if they got the correct answer or incorrect answer
      })
      .catch(function (error) {
        
        setProcessing(false);
      });
  };

  //------ 

  const handleTestCases = () => {
    showTestingToast();
    // setIsSubmit(true);
    const testInputs = questions[currentQuestion].options.map(
      (option: any) => option.testCaseInput
    );
    //tests first 2 cases
    for (let i = 0; i < 1; i++) {
      
      try {
        compileSampleTests(testInputs[i], i);
      } catch (error) {
        
      }
    }
  };

  const handleSubmit = async () => {
    const updatedSubmissions = [...submissions];
    updatedSubmissions[currentQuestion] = true;
    setSubmissions(updatedSubmissions);
    setIsSubmit(false);
    showTestingToast();
    const testInputs = questions[currentQuestion].options.map(
      (option: any) => option.testCaseInput
    );
    
    // testInputs.forEach((input: any, index: number) => {
    //   
    //   try {
    //     compileAllTestCase(input, index);
    //   } catch (error) {
    //     
    //   }
    // });
    //for testing purposes to test lower API calls
    for (let i = 0; i < 1; i++) {
      
      try {
        compileAllTestCase(testInputs[i], i);
      } catch (error) {
        
      }
    }
  };

  const handleThemeChange = (event: { target: { value: any } }) => {
    const selectedTheme = event.target.value;
    setTheme(selectedTheme);
  };
  const showSuccessToast = (msg: any) => {
    toast.success(msg || `Compiled Successfully!`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showTestingToast = () => {
    toast.info(`Testing In Progress`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showErrorToast = (msg?: any, timer?: any) => {
    toast.error(msg || `Something went wrong! Please try again.`, {
      position: "top-right",
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const behaviourScoreCalculation = () => {
    const maxScore = 100;
    const fullScreenPoints = fullScreenMode ? 0 : 5;
    const webcamPoints = webCamEnabled ? 0 : 5;
    const mousePoints =
      countMouseMovedOutside <= 20 ? countMouseMovedOutside * 1 : 20;
    const tabPoints = countSwitchingTab <= 3 ? countSwitchingTab * 10 : 30;
    let behaviorScore =
      maxScore - fullScreenPoints - webcamPoints - mousePoints - tabPoints;
    if (behaviorScore < 0) behaviorScore = 0;
    return behaviorScore;
  };

  const handleStatusOnLoad = () => {
    let behaviourScore: number = behaviourScoreCalculation();
    setStatus("ABORTED");
    // setCountMouseMovedOutside(0);
    // setCountSwitchingTab(0);
    dispatch(
      updateCandidateStatus(exam_candidate_id, "ABORTED", behaviourScore)
    );
  };

  const [isOutsideScreen, setIsOutsideScreen] = useState(false);
  const [timeOutsideScreen, setTimeOutsideScreen] = useState(0);
  const [startTime, setStartTime] = useState(0);

  useEffect(() => {
    if (!tracker) {
      return;
    } else {
      const handleMouseMovement = (event: { clientX: any; clientY: any }) => {
        const mouseX = event.clientX;
        const mouseY = event.clientY;

        if (
          mouseX < 2 ||
          mouseY < 5 ||
          mouseX >= window.innerWidth - 10 ||
          mouseY >= window.innerHeight - 10
        ) {
          setIsOutsideScreen(true);
        } else {
          setIsOutsideScreen(false);
        }
      };

      document.addEventListener("mouseleave", handleMouseMovement);
      document.addEventListener("mouseenter", handleMouseMovement);

      return () => {
        document.removeEventListener("mouseleave", handleMouseMovement);
        document.removeEventListener("mouseenter", handleMouseMovement);
      };
    }
  }, [tracker]);

  useEffect(() => {
    if (!tracker) {
      return;
    } else {
      let timerId: string | number | NodeJS.Timeout | undefined;
      // setTimeOutsideScreen(0)
      if (isOutsideScreen) {
        if (startTime === 0) {
          setStartTime(Date.now());
        }
        timerId = setInterval(() => {
          setTimeOutsideScreen(
            (prevTime) => prevTime + Math.round((Date.now() - startTime) / 1000)
          );
          setStartTime(Date.now());
        }, 1000);
      } else {
        if (startTime !== 0) {
          clearInterval(timerId);
          // setTimeOutsideScreen((prevTime) => prevTime + (Date.now() - startTime));
          setStartTime(0);
        }
      }
      // 

      return () => {
        clearInterval(timerId);
      };
    }
  }, [isOutsideScreen, startTime, tracker]);

  const handleSubmitTrackerDetails = async () => {

    let behaviourScore: number = behaviourScoreCalculation();
    if (countSwitchingTab > 3 && tracker) {
      handleStatusOnLoad();
    } else {
      dispatch(
        updateCandidateStatus(
          assessmentObj.displayAssessmentExam?.candidateExam.id,
          "COMPLETED",
          behaviourScore,
          feedback
        )
      );
    }
    dispatch(
      createCandidateTrackerDetails(
        exam_candidate_id,
        deviceUsed,
        latitude,
        longitude,
        webCamEnabled,
        ipAddress,
        mouseMovedOutside,
        countMouseMovedOutside,
        switchingTab,
        countSwitchingTab,
        fullScreenMode,
        timeOutsideScreen
      )
    );

    let candidate_id: number =
      assessmentObj?.displayAssessmentExam?.candidateExam?.id;

    capturedPhotos.push(state?.state?.firstImage);

    const databaseURL =
      "https://bravenstoolimageupload-default-rtdb.firebaseio.com//users.json?auth=AIzaSyCi1tuVZ9XCt46GzUhbcqonI6XlAG-sSKo";
    // const endpoint = 'users.json'; // Replace 'data' with the appropriate path

    const requestBody = {
      exam_candidate_ids: exam_candidate_id,
      images: capturedPhotos,
      videos: capturedBase64Videos,
    };

    const response = await fetch(`${databaseURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      
      
    } else {
      
    }

    dispatch(
      sendImageArray(capturedPhotos, capturedBase64Videos, candidate_id)
    );
    document.exitFullscreen();
  };

  const handleSubmitAssesment = () => {
    setShowResults(true);
    setStatus("COMPLETED");
    if (countSwitchingTab > 3 && tracker) {
      setStatus("ABORTED");
    }
  };

  const element = document.documentElement;
  useEffect(() => {
    if (!tracker) {
      return;
    } else {
      const handleVisibilityChange = () => {
        setIsTabActive(!document.hidden);
        
        setSwitchingTab(true);
        setCountSwitchingTab(countSwitchingTab++);
        Swal.fire(
          "WARNING!",
          "Leaving exam multiple times may be flagged as cheating, if you change tab more than 3 times, then test will be disbanded"
          // 'You changed tab more than 3 times, so test is ended',
        );
      };
      if (count > 0) {
        element.requestFullscreen();
      }
      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    }
  }, [tracker]);

  useEffect(() => {
    if (!tracker) {
      return;
    } else {
      let timerId: string | number | NodeJS.Timeout | undefined;

      const handleMouseMove = (event: { clientX: any; clientY: any }) => {
        const mouseX = event.clientX;
        const mouseY = event.clientY;

        if (
          mouseX < 2 ||
          mouseY < 5 ||
          mouseX >= window.innerWidth - 10 ||
          mouseY >= window.innerHeight - 10
        ) {
          clearTimeout(timerId);
          timerId = setTimeout(() => {
            if (!mouseMovedOutside) {
              setMouseMovedOutside(true);
            }
            setCountMouseMovedOutside((prevCount: number) => prevCount + 1);

            if (countMouseMovedOutside >= 1) {
              Swal.fire(
                "WARNING!",
                "Do not move your cursor multiple times outside window"
              );
            }
          }, 500);
        } else {
          // setIsOutsideScreen(false)
          clearTimeout(timerId);
        }
      };

      document.addEventListener("mousemove", handleMouseMove);

      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        clearTimeout(timerId);
      };
    }
  }, [mouseMovedOutside, countMouseMovedOutside, tracker]);

  const handleFullscreenChange = () => {
    if (!tracker) {
      return;
    } else {
      setFullScreenMode(!!document.fullscreenElement);
    }
  };

  useEffect(() => {
    if (!tracker) {
      return;
    } else {
      document.addEventListener("fullscreenchange", handleFullscreenChange);

      return () => {
        document.removeEventListener(
          "fullscreenchange",
          handleFullscreenChange
        );
      };
    }
  }, [tracker]);

  const handleTimerEnd = () => {
    setShowResults(true);
  };

  const min2 = examDetails?.requiredTime;

  const getTimeRemaining = (e: any) => {
    const total = Date.parse(e) - Date.now();
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);

    setSec(seconds);
    setMin(minutes);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };
  const startTimer = (e: any) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };
  const clearTimer = (e: any) => {
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    });
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + min2 * 60);

    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        const { ip } = response.data;
        setIp(ip);
        
      } catch (error) {
        
      }
    };

    fetchIpAddress();
  }, []);

  //---- 
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div>
      {showResults ||
      (countSwitchingTab > 3 && tracker) ||
      (min < 1 && sec < 1) ? (
        <div className="final-result">
          {countSwitchingTab > 3 && tracker ? (
            <h1 style={{ color: "red" }} className="welcome-text">
              Your assessment has been disbanded due to multiple tab switch.
            </h1>
          ) : min < 1 && sec < 1 ? (
            <h1 className="welcome-text" style={{ color: "red" }}>
              Your assessment has been ended due to time
            </h1>
          ) : (
            <h1 className="welcome-text">
              Thanks for completing this assessment
            </h1>
          )}
          {/* <h1 className="welcome-text">
        Thanks for completing this assessment
      </h1> */}
          <p className="partition"></p>
          <div className="side-gif-main" style={{ columnGap: "50px" }}>
            <div className="" style={{ width: "1500px" }}>
              {!tracker || countSwitchingTab <= 3 ? (
                <div>
                  <h1 className="welcome-text">Feedback</h1>
                  <TextArea
                    className="quiz-feedback-form"
                    value={feedback}
                    handleChange={(e: any) => {
                      setFeedback(e.target.value);
                    }}
                    placeholder={"Help us to improve this assessment"}
                  />
                </div>
              ) : null}

              <Link to="/">
                <Button
                  value="Continue"
                  handleClick={handleSubmitTrackerDetails}
                  className="test-taker-next-btn"
                />
              </Link>
            </div>
            <div className="" style={{ marginTop: "100px", width: "1500px" }}>
              <img style={{}} src={ResultImage} alt="" className="" />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="code-quiz-countdown">
            <h5>
              Test ends in:{" "}
              <strong>
                {min} min {sec} sec
              </strong>
            </h5>
            {currentQuestion == questions?.length - 1 ? (
              <Button
                value="End Test"
                handleClick={handleSubmitAssesment}
                className="submit-assessment-button p-2 m-auto"
              />
            ) : (
              <Button
                value=""
                handleClick={handleSubmitAssesment}
                className="disabled"
              />
            )}
          </div>
          <div className="code-editor-container">
            <div
              className="sidebar"
              style={{
                width: sidebarWidth,
                maxWidth: "600px",
                minWidth: "250px",
              }}
              ref={sidebarRef}
            >
              <div className="sidebar-content">
                {questions?.[currentQuestion]?.difficultyLevel == "EASY" && (
                  <div className="question-type-easy">Easy</div>
                )}
                {questions?.[currentQuestion]?.difficultyLevel ==
                  "MODERATE" && (
                  <div className="question-type-moderate">Moderate</div>
                )}
                {questions?.[currentQuestion]?.difficultyLevel == "HARD" && (
                  <div className="question-type-hard ">Hard</div>
                )}
                {questions?.[currentQuestion]?.difficultyLevel ==
                  "CHALLENGING" && (
                  <div className="question-type-challenging">Challenging</div>
                )}
                <div className="question-header">
                  <h3>{currentQuestion + 1}. TITLE OF QUESTION</h3>
                  <p className="partition m-auto"></p>
                </div>
                <div className="current-question-div p-1">
                  <p className="current-question-info p-1">
                    {questions[currentQuestion].questions}
                  </p>
                </div>
                <div className="resize-bar" onMouseDown={handleMouseDown} />
                <div className="testcases-section p-2 m-auto">
                  <div className="testcase mb-3">
                    <h5 className="p-1">Example 1: </h5>
                    <div className="sample-input-output-container">
                      <p className="m-auto p-1">
                        <b className="heavy-text">Input: </b>
                        {questions[currentQuestion].options[0].testCaseInput}
                      </p>
                      <p className="m-auto p-1">
                        <b className="heavy-text">Output: </b>
                        {questions[currentQuestion].options[0].testCaseOutput}
                      </p>
                    </div>
                  </div>
                  <div className="testcase mb-3">
                    <h5 className="p-1">Example 2: </h5>
                    <div className="sample-input-output-container">
                      <p className="m-auto p-1">
                        <b className="heavy-text">Input: </b>
                        {questions[currentQuestion].options[1].testCaseInput}
                      </p>
                      <p className="m-auto p-1">
                        <b className="heavy-text">Output: </b>
                        {questions[currentQuestion].options[1].testCaseOutput}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="dsa-btn p-1">
                  {currentQuestion < 1 || submissions[currentQuestion - 1] ? (
                    <Button
                      value={""}
                      handleClick={handlePrevious}
                      className="hidden"
                    />
                  ) : (
                    <Button
                      value={"Previous"}
                      handleClick={handlePrevious}
                      className="question-button"
                    />
                  )}
                  {currentQuestion >= questions?.length - 1 ? (
                    <Button
                      value={""}
                      handleClick={handleNext}
                      className="hidden"
                    />
                  ) : (
                    <Button
                      value={"Next"}
                      handleClick={handleNext}
                      className="question-button"
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className="main-content"
              // style={{ maxWidth: `calc(100% - ${sidebarWidth}px)` }}
            >
              <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <div className="code-editor-toolbar p-2 m-auto gap-4 mb-2 flex">
                <div className="code-editor-toolbar">
                  <select
                    value={theme}
                    onChange={handleThemeChange}
                    className="code-editor-theme shadow-sm flex"
                  >
                    <option value="vs">Light</option>
                    <option value="vs-dark">Dark</option>
                    <option value="hc-black">High Contrast</option>
                  </select>
                  <div className="code-editor-language">
                    <LanguagesDropdown onSelectChange={onSelectChange} />
                  </div>
                </div>
                <div className="code-editor-toolbar gap-4">
                  <Button
                    value={processing ? "Processing..." : "Compile"}
                    className="compile-testcases"
                    handleClick={() => {
                      handleTestCases();
                      setIsSubmit(true);
                    }}
                  />
                  {isSubmit ? (
                    <Button
                      value={"Submit"}
                      className="submit-button"
                      handleClick={() => {
                        handleSubmit();
                      }}
                    />
                  ) : null}
                </div>
              </div>
              <div className="code-editor w-full">
                <CodeEditorWindow
                  code={code}
                  onChange={onChange}
                  language={language?.value}
                  theme={theme}
                />
              </div>
              {/* <div className="right-container flex flex-shrink-0 w-[30%] flex-col"> */}
              <div
                style={{ display: "flex", marginTop: "1rem" }}
                className="code-editor-tab-div"
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Tabs
                    defaultActiveKey="custominput"
                    id="uncontrolled-tab-example"
                    className="code-editor-tabs mb-2"
                  >
                    <Tab
                      eventKey="custominput"
                      title="Custom Input"
                      className="code-editor-single-tab"
                    >
                      <div className="custom-input-div">
                        <CustomInput
                          customInput={customInput}
                          setCustomInput={setCustomInput}
                        />
                        <Button
                          value={
                            processing ? "Processing..." : "Run Custom Input"
                          }
                          className="compile-button"
                          handleClick={() => {
                            handleCompile();
                          }}
                        />
                      </div>
                    </Tab>
                    <Tab
                      eventKey="output"
                      title="Output"
                      className="code-editor-single-tab"
                    >
                      <OutputWindow outputDetails={outputDetails} />
                    </Tab>
                    <Tab
                      eventKey="outputdetails"
                      title="Output Details"
                      className="code-editor-single-tab"
                    >
                      <div className="output-details">
                        {outputDetails && (
                          <OutputDetails outputDetails={outputDetails} />
                        )}
                      </div>
                    </Tab>
                  </Tabs>
                </Box>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
