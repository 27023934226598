//Chetan Patil - [07/07/2023] - Pdf page for Candidates details page
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.css";
import { CustomTable } from "./CustomTable";
import { AdminNavbar } from "../AdminDashboard/Navbar";
import { Navbar } from "../RecruitersDashboard/Navbar";
import jwt_decode from "jwt-decode";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const DownloadScorePdf: React.FC = () => {
    const location = useLocation();

    const state = location.state;
    console.log("state gggg: ", state);

    const [role, setRole] = useState("");
    const token: any = localStorage.getItem("token");
    let decoded: any = jwt_decode(token);

    useEffect(() => {
        if (decoded) {
            setRole(decoded?.role);
        }
    }, []);

    // const trackerIndexPdf = state.trackerIndexPdf;
    const currentAssessment = state.currentAssessment;
    console.log('currentAssessment: ', currentAssessment);
    // const candidateTracker = state.candidateTracker;
    const invitedDate = state.invitedDate;
    const examStatus = state.examStatus;


    const scoreTable = state.scoreTableMCQ;
    console.log("scoreTable: ", scoreTable);

    const behaviourScoreTable = state.behaviourScoreTable;
    console.log("scoreTable: ", behaviourScoreTable);

    const questionsTable = state.questionsTable;
    console.log("scoreTable: ", questionsTable);

    const candidateName = state.candidateName;
    console.log('candidateName: ', candidateName);

    const textQuestionsTable = state.textQuestionsTable;
    console.log('textQuestionsTable: ', textQuestionsTable);



    const examsTable = [
        [
            "Candidate Name",
            `${currentAssessment[0].candidateExam.candidateFirstName} ${currentAssessment[0].candidateExam.candidateLastName}`,
        ],
        ["Exam Name", `${currentAssessment[0].exam.name}`],
        ["Exam Invited Date", `${invitedDate}`],
        ["Exam Status", `${examStatus}`],
    ];

    const printDocument = async () => {
        const input: any = document.getElementsByClassName("pdf-only-section");

        const pdf: any = new jsPDF({ unit: "mm", format: [210, 297] });
        const canvas: any = await html2canvas(input[0]);
        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(imgData, "JPEG", 0, 0, 210, 297);
        pdf.save(`${candidateName} Exam.pdf`);
    };

    console.log('currentAssessment[0]?.candidateExam?.candidateExamType !== "Text": ', currentAssessment[0]?.candidateExam?.candidateExamType !== "Text");
    return (
        <div className="">
            {role === "ADMIN" ? <AdminNavbar /> : <Navbar />}

            <div style={{ marginTop: "4%" }}>
                <button className="exam-report" onClick={printDocument}>Download</button>
                <div className="pdf-only-section" style={{ paddingTop: "10px" }}>
                    <div className="" style={{ marginBottom: "40px" }}>
                        <center
                            style={{
                                backgroundColor: "#1f7ebc",
                                color: "white",
                                padding: "5px 0 5px 0",
                                fontSize: "18px",
                            }}
                        >
                            Candidate Exam Information
                        </center>
                        <CustomTable table={examsTable} />
                    </div>
                    {currentAssessment[0]?.candidateExam?.candidateExamType !== "Text" ? (
                    <div className="" style={{ marginBottom: "40px" }}>
                        <center
                            style={{
                                backgroundColor: "#1f7ebc",
                                color: "white",
                                padding: "5px 0 5px 0",
                                fontSize: "18px",
                            }}
                        >
                            Candidate Score
                        </center>
                        <CustomTable table={scoreTable} />
                    </div>
                    ):null}
                    <div className="" style={{ marginBottom: "40px" }}>
                        <center
                            style={{
                                backgroundColor: "#1f7ebc",
                                color: "white",
                                padding: "5px 0 5px 0",
                                fontSize: "18px",
                            }}
                        >
                            Behaviour Score
                        </center>
                        <CustomTable table={behaviourScoreTable} />
                    </div>
                    {currentAssessment[0]?.candidateExam?.candidateExamType !== "Text" ? (
                        <div className="" style={{ marginBottom: "40px" }}>
                            <center
                                style={{
                                    backgroundColor: "#1f7ebc",
                                    color: "white",
                                    padding: "5px 0 5px 0",
                                    fontSize: "18px",
                                }}
                            >
                                Questions Information
                            </center>
                            <CustomTable table={questionsTable} />
                        </div>
                    ) : (
                            <div className="" style={{ marginBottom: "40px" }}>
                                <center
                                    style={{
                                        backgroundColor: "#1f7ebc",
                                        color: "white",
                                        padding: "5px 0 5px 0",
                                        fontSize: "18px",
                                    }}
                                >
                                    Questions Information
                                </center>
                                <CustomTable table={textQuestionsTable} />
                            </div>  
                    )}
                </div>
            </div>
        </div>
    );
};
