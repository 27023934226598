/* chetan patil [13-4-23] added underline to navbar */

import React, { useContext, useEffect, useState } from "react";
import { BsPersonCircle, BsPower } from "react-icons/bs";
import { HiOutlineBars3 } from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import "./navbar.css";
import { logout } from "../../actions/user";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useAppDispatch } from "../../hooks/app";

export const Navbar: React.FC = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  //Method to handle logout operation
  const handleLogout = () => {
    navigate("/");
    //Calls the logout action creator with the dispatch for userReducer
    dispatch(logout());
  };

  const menuOptions = [
    {
      text: "Home",
      icon: <HomeIcon />,
    },
    {
      text: "About",
      icon: <InfoIcon />,
    },
    {
      text: "Testimonials",
      icon: <CommentRoundedIcon />,
    },
    {
      text: "Contact",
      icon: <PhoneRoundedIcon />,
    },
    {
      text: "Account",
      icon: <AccountCircleOutlinedIcon />,
    },
  ];
  return (
    <nav className="nav-underline-container">
      <div className="nav-logo-container">
        <h2 className="hiring-logo">BravensTool</h2>
      </div>
      <div className="navbar-links-container">
        <a href="/">Home</a>
        <a href="/">About</a>
        <a href="/">Testimonials</a>
        <a href="/">Contact</a>
        <a href="/demo">Demo</a>
        <a href="/upgrade">Upgrade</a>
        {/* If token is set in headers display Logout icon */}

        {user ? (
          <a className="logout-navbar-option" onClick={handleLogout}>
            {/* <BsPower className="navbar-account-icon" /> */}Logout
          </a>
        ) : (
          <Link to="/sign-in">
            {/* <BsPersonCircle className="navbar-account-icon" /> */}Login
          </Link>
        )}
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </nav>
  );
};
