//Sachin Dhalani - [14/4/2023]
import {
  ADD_ADMIN,
  ADD_ADMIN_ERROR,
  FETCH_ADMIN,
  FETCH_ADMIN_ERROR,
} from "../actions/Types";

//State type for defining the state of the reducer
interface Actions {
  payload: any;
  type: string;
}
//AdminInterface to define the State type for the state of the reducer
interface AdminInterface {
  adminDetails: any;
  adminDetailsError: string;
  addAdmin: any;
  addAdminError: string;
}
//State type for defining the state of the reducer
export type State = AdminInterface;
//Initial state of the reducer of type State
export const initialState: State = {
  adminDetails: undefined,
  adminDetailsError: "",
  addAdmin: undefined,
  addAdminError: "",
};
export const AdminReducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case FETCH_ADMIN:
      return {
        ...state,
        adminDetails: action.payload,
        adminDetailsError: "",
      };
    case FETCH_ADMIN_ERROR:
      return {
        ...state,
        adminDetails: [],
        adminDetailsError: action.payload,
      };
    case ADD_ADMIN:
      return {
        ...state,
        addAdmin: action.payload,
        addAdminError: "",
      };
    case ADD_ADMIN_ERROR:
      return {
        ...state,
        addAdmin: [],
        addAdminError: action.payload,
      };
    //return state as it is if action is not of any of the aforementioned types
    default:
      return state;
  }
};
