import React from "react";

interface props {
  onSubmit?: any;
  adminData?: any;
  recruiters?: any;
}
export const ShowAdmin: React.FC<props> = ({
  onSubmit,
  adminData,
  recruiters,
}) => {
  

  return (
    <div className="dashboard">
      <div className="heading">
      </div>
      <div>
        <div className="assessment-record">
          <table className="table table-bordered table-display-candidate">
            <thead className="display-candidate-thead">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Contact</th>
              </tr>
            </thead>
            <tbody>
              {adminData?.length ? (
                adminData?.map((data: any, index: any) => (
                  <tr className="display-candidate-tr" key={index}>
                    <td>
                      <p className="fw-normal mb-1">
                        {data?.firstName + " " + data?.lastName}
                      </p>
                    </td>
                    <td>{data?.email}</td>
                    <td>{data?.contact}</td>
                  </tr>
                ))
              ) : (
                <p className="no-records-found-error-message">
                  No Records Found
                </p>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
