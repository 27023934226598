//Sachin Dhalani -To select job role & assessment for creating a new assessment
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../Common/Button/Button";
import { DropDown } from "../../Common/DropDown/DropDown";
import { TextField } from "../../Common/TextField/TextField";
import { MultiStepContext } from "../../context/MultiStepContext";
import { isNumberValid, isTextValid } from "../../helpers/validate";
import "./style.css";
import jwt_decode from "jwt-decode";
import { useAppDispatch, useAppSelector } from "../../hooks/app";
import {
  addAssessment,
  updateCreateExamStepper,
} from "../../actions/assessment";
import {
  difficultyLevels,
  examTypes,
  technology_list,
  testCategory,
} from "../../constants/constants";
import { RootState } from "../../redux/store";
export const FirstStep: React.FC = () => {
  let examTypesEnum = examTypes;
  examTypesEnum = examTypesEnum.filter(element => {
    if(element.label === 'All'){
      return false;
    } else {
      return true;
    }
  });
  //testName variable in the state and setTestName method for updating the test name value
  const [name, setName] = useState("");
  //testType variable in the state and settesttype method for updating the test type value
  const [type, setType] = useState("");
  //testCategory variable in the state and setTestCategory method for updating the test category value
  const [category, setCategory] = useState("");
  //requiredTime variable in the state and setRequiredTime method for updating the test required time value
  const [requiredTime, setRequiredTime] = useState<number>();
  //testVersion variable in the state and setTestVersion method for updating the test version value
  const [technology, setTechnology] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("");
  //testNameError variable in the state and setTestNameError method for updating the token value
  const [testNameError, setTestNameError] = useState<string>("");
  //testTypeError variable in the state and setTestTypeError method for updating the token value
  const [testTypeError, setTestTypeError] = useState<string>("");
  //requiredTimeError variable in the state and setRequiredTimeError method for updating the token value
  const [requiredTimeError, setRequiredTimeError] = useState<string>("");
  const [orgnizationId, setOrganizationId] = useState("");
  //calling context to store & get values of text filed globally
  const context: any = useContext(MultiStepContext);
  //Set steps to update the step value in context
  const setStep = context?.setStep;
  //Temporary option to display position in drop down
  const handleChange = (event: any) => {
    const result = event.target.value;
    setRequiredTime(result);
  };

  useEffect(() => {
    const token: any = localStorage.getItem("token");
    let decoded: any = jwt_decode(token);
    setOrganizationId(decoded?.organization?.id);
  }, []);

  const dispatch = useAppDispatch();
  //Navigates to Second Step on Next button click
  const handleNextStep = () => {
    //check if test name is valid
    const testNameValid = isTextValid(name);
    //check if test type is valid
    const testTypeValid = isTextValid(type);
    //check if required time is valid
    const requiredTimeValid = isNumberValid(requiredTime);

    //if name is valid set test name error as null and navigates to second step
    if (testNameValid && testTypeValid && requiredTimeValid) {
      setTestNameError("");
      setTestTypeError("");
      setRequiredTimeError("");
      dispatch(
        addAssessment(
          name,
          type,
          technology,
          category,
          requiredTime,
          difficultyLevel,
          orgnizationId
        )
      );

      dispatch(updateCreateExamStepper(2));
    } else {
      //if test name is not valid throw error
      if (!testNameValid) {
        setTestNameError("Test name is required");
      }
      //if test type is not valid throw error
      if (!testTypeValid) {
        setTestTypeError("Test type is required");
      }
      //if required time is not valid throw error
      if (!requiredTimeValid) {
        setRequiredTimeError("Time cannot be null");
      }
    }
  };

  return (
    <div className="first-step-main">
      <div className="first-step-one">
        <TextField
          placeholder="Name your Test"
          value={name}
          label="Assessment Name"
          handleChange={(e) => setName(e.target.value)}
        />
      

        <DropDown
          className="drop-down-menu-main"
          value={technology}
          options={technology_list}
          noOptionsText={"No technology Found"}
          label="Technology"
          handleChange={(e: any) => {
            setTechnology(e.value);
          }}
          showLabel={true}
        />
      </div>
      <p className="error-message">{testNameError}</p>

      <div className="first-step-two">
        {/* <TextField
          placeholder="Type"
          value={type}
          label="Assessment Type"
          handleChange={(e) => setType(e.target.value)}
        /> */}
        
        <DropDown
          className="drop-down-menu-main"
          value={type}
          options={examTypesEnum}
          noOptionsText={"No Exam Type found"}
          label="Assessment Type"
          handleChange={(e: any) => {
            setType(e.value);
          }}
          showLabel={true}
        />

        <DropDown
          className="drop-down-menu-main"
          value={category}
          options={testCategory}
          noOptionsText={"No Category Found"}
          label="Category"
          handleChange={(e: any) => {
            setCategory(e.value);
          }}
          showLabel={true}
        />
      </div>

      <p className="error-message">{testTypeError}</p>
      <div className="first-step-three">
        <TextField
          placeholder="Required time"
          value={requiredTime}
          label="Required Time"
          handleChange={(e: any) => {
            const value = e.target.value.replace(/\D/g, "");
            setRequiredTime(value);
          }}
        />

        <DropDown
          className="drop-down-menu-main"
          value={difficultyLevel}
          options={difficultyLevels}
          noOptionsText={"No Level Found"}
          label="Difficulty Level"
          handleChange={(e: any) => {
            setDifficultyLevel(e.value);
          }}
          showLabel={true}
        />
      </div>
      <p className="error-message">{requiredTimeError}</p>
      <Button
        className="create-assessment-next-step-btn"
        value="Next Step"
        handleClick={handleNextStep}
      />
    </div>
  );
};
