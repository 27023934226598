import React, { useState } from "react";
import { Button } from "../../Common/Button/Button";
import { FloatLabel } from "../../Common/FloatLabel/FloatLabel";
import { useNavigate } from "react-router-dom";

export const Demo: React.FC = () => {
  const [firstName, SetFirstName] = useState<string>("");
  const [lastName, SetLastName] = useState<string>("");
  const [jobFunction, SetJobFunction] = useState<string>("");
  const [email, SetEmail] = useState<string>("");
  const [companyName, SetCompanyName] = useState<string>("");
  const [nextYearHiring, SetNextYearHiring] = useState<any>();
  const [showResults, setShowResults] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const navigator = useNavigate();
  const handleNext = () => {
    if (!email) {
      setErrorMessage(" Please enter email. ");
      return;
    }
    if (!nextYearHiring) {
      setErrorMessage(" Please enter hiring.");
      return;
    }
    if (!firstName) {
      setErrorMessage(" Please enter first name.");
      return;
    }
    if (!lastName) {
      setErrorMessage(" Please enter last name.");
      return;
    }
    if (!jobFunction) {
      setErrorMessage(" Please enter job function name.");
      return;
    }
    if (!companyName) {
      setErrorMessage("Please enter company name.");
      return;
    }
    setShowResults(true);
  };

  return (
    <>
      {showResults ? (
        <div>
          <div className="Heading-name">
            <h3>BravensTool</h3>
          </div>
          <div className="Thankyou-content">
            <div className="wrapper-1">
              <div className="wrapper-2">
                <h1 className="Thank-you-heading">Thank you !</h1>
                <p>Thanks for filling the form. </p>
                <p>Our Technical Team will get back to you in next 24hrs</p>
                <Button
                  className="demo-goHome-btn"
                  value="Go Home"
                  handleClick={() => {
                    navigator("/");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="Heading-name">
            <h3>BravensTool</h3>
          </div>
          <div className="demo-container">
            <div className="FloatLabel-email">
              <FloatLabel
                className="Float"
                myStyle={"input-custom-width"}
                value={email}
                label="Work Email Address*"
                handleChange={(e: any) => {
                  SetEmail(e.target.value);
                }}
              />
              <FloatLabel
                className="Float"
                value={nextYearHiring}
                myStyle={"input-custom-width"}
                label="How many people are you planning to hire next year?*"
                handleChange={(e: any) => SetNextYearHiring(e.target.value)}
              />
            </div>

            <div className="FloatLabel-names">
              <FloatLabel
                myStyle={"input-custom-width"}
                className="Float"
                value={firstName}
                label="First Name*"
                handleChange={(e: any) => SetFirstName(e.target.value)}
              />

              <FloatLabel
                className="Float"
                myStyle={"input-custom-width"}
                value={lastName}
                label="Last Name*"
                handleChange={(e: any) => SetLastName(e.target.value)}
              />
            </div>

            <div className="FloatLabel-names">
              <FloatLabel
                className="Float"
                myStyle={"input-custom-width"}
                value={jobFunction}
                label="Job Function*"
                handleChange={(e: any) => SetJobFunction(e.target.value)}
              />

              <FloatLabel
                className="Float"
                myStyle={"input-custom-width"}
                value={companyName}
                label="Compony Name*"
                handleChange={(e: any) => SetCompanyName(e.target.value)}
              />
            </div>

            <div className="action-btn">
              <Button
                className="demo-Next-btn"
                value="Next"
                handleClick={() => handleNext()}
              />
              <p className="mx-3 m-2 error-message">{errorMessage}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
