//Sachin Dhalani - [14/4/2023] To add Recruiters
import React, { useEffect, useState } from "react";
import { Button } from "../../Common/Button/Button";
import { TextField } from "../../Common/TextField/TextField";
import {
  isEmailValid,
  isMobileNumberValid,
  isNameValid,
  isTextValid,
} from "../../helpers/validate";
import jwt_decode from "jwt-decode";
import { addUser, updateUser } from "../../actions/admin";
import { useAppDispatch } from "../../hooks/app";
import { DatePicker } from "../../Common/DatePicker/DatePicker";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import moment from "moment-timezone";
import validator from "validator";

interface Props {
  onSubmit?: any;
  editData?: any;
  isUpdate?: boolean;
  onEdit?: any;
}
export const CreateCandidate: React.FC<Props> = ({
  onSubmit,
  editData,
  isUpdate,
  onEdit,
}) => {
  //firstName variable in the state and setFirstName method for updating the token value
  const [firstName, setFirstName] = useState("");
  //LastName variable in the state and setLastName method for updating the token value
  const [lastName, setLastName] = useState("");
  console.log("lastName: ", lastName);
  //MiddleName variable in the state and setMiddleName method for updating the token value
  const [middleName, setMiddleName] = useState("");
  //userName in the state and setUserName method for updating the token value
  const [userName, setUserName] = useState("");
  //date variable in the state and setDate method for updating the token value
  // const [date, setDate] = useState("");
  // //mobileNumber in the state and setNumber method for updating the token value
  const [contact, setContact] = useState<string>("");
  //countryCode in the state and setCountryCode method for updating the token value
  const [birthDate, setBirthDate] = useState<any>();
  //email variable in the state and setEmail method for updating the token value
  const [email, setEmail] = useState<string>("");
  //role in the state and setRole method for updating the token value
  const [role, setRole] = useState<string>("CANDIDATE");
  //emailError variable in the state and setEmailError method for updating the token value
  const [emailError, setEmailError] = useState<string>("");
  //firstNameError variable in the state and setFirstNameError method for updating the token value
  const [firstNameError, setFirstNameError] = useState<string>("");
  //lastNameError variable in the state and setLastNameError method for updating the token value
  const [lastNameError, setLastNameError] = useState<string>("");
  //userNameError variable in the state and setUserNameError method for updating the token value
  const [userNameError, setUserNameError] = useState<string>("");
  //middlenameError variable in the state and setMiddleNameError method for updating the token value
  const [middleNameError, setMiddleNameError] = useState<string>("");
  //contactError variable in the state and setContactError method for updating the token value
  const [contactError, setContactError] = useState<string>("");
  //dateError variable in the state and setDateError method for updating the token value
  const [dateError, setDateError] = useState<string>("");

  //contact_country_code variable in the state and setContact_country_code method for updating the token value
  const [contact_country_code, setContact_country_code] = useState("+91");
  //token variable in the state and setToken method for updating the token value
  const [organizationId, setOrganizationId] = useState();
  const [recruiter_id, setRecruiter_id] = useState();
  const [id, setId] = useState<any>();
  const [dateValid, setDateValid] = useState<boolean>();
  //Use Effect hook that runs every time there is a change in the user reducer's state
  useEffect(() => {
    const token: any = localStorage.getItem("token");
    let decoded: any = jwt_decode(token);
    setOrganizationId(decoded?.organization?.id);
    setRecruiter_id(decoded?.id);
    setRole("CANDIDATE");
    //Tried to fetch the token from the local storage and set it in the state variable called token
  }, []);

  const handleBirthDate = (value: any) => {
    if (validator.isDate(value)) {
      setDateValid(true);

      setBirthDate(value);
      setDateError("");
    } else {
      setDateValid(false);
      setDateError("date in invalid");
    }
  };

  useEffect(() => {
    if (editData) {
      setId(editData.id);
      setFirstName(editData.firstName);
      setLastName(editData.lastName);
      setMiddleName(editData.middleName);
      setContact(editData.contact);
      setEmail(editData.email);
      setUserName(editData.username);
      setBirthDate(
        editData?.birthDate
          ? moment(editData?.birthDate).format("YYYY-MM-DD")
          : "--"
      );
    }
  }, []);

  const dispatch = useAppDispatch();
  const handleClick = async () => {
    //check if email is valid
    const emailValid = isEmailValid(email);
    //check if first name is valid
    const firstNameValid = isNameValid(firstName);
    //check if last name is valid
    const lastNameValid = isTextValid(lastName);
    //check if user name is valid
    const userNameValid = isTextValid(userName);
    //check if middle name is valid
    const middleNameValid = isTextValid(middleName);
    //check if contact is valid
    const contactValid = isMobileNumberValid(contact);

    // const handleBirthDate=()=>{
    //   if (validator.isDate(birthDate)) {
    //     setDateValid(true);
    //   } else {
    //     setDateValid(false);
    //   }
    // }

    //If valid, clear error states and execute the signUp action creator
    if (
      emailValid &&
      firstNameValid &&
      lastNameValid &&
      userNameValid &&
      contactValid &&
      dateValid
    ) {
      setEmailError("");
      setFirstNameError("");
      setLastNameError("");
      setUserNameError("");
      setContactError("");
      setDateError("");
      dispatch(
        addUser(
          firstName,
          middleName,
          lastName,
          email,
          userName,
          contact,
          birthDate,
          contact_country_code,
          role,
          organizationId,
          recruiter_id,
          "candidate"
        )
      );
      onSubmit();
    }
    //if email,firstName,lastName or password is invalid, set errors in the state for whatever is invalid
    else {
      if (!emailValid) {
        setEmailError("Email is invalid");
      }
      if (!firstNameValid) {
        setFirstNameError("First Name is invalid!");
      }
      if (!lastNameValid) {
        setLastNameError("Last Name is invalid!");
      }
      if (!userNameValid) {
        setUserNameError("User Name is invalid!");
      }
      // if (!middleNameValid) {
      //   setMiddleNameError("Middle Name cannot be empty!");
      // }
      if (!contactValid) {
        setContactError("Contact is invalid!");
      }
      if (dateValid === false || !birthDate) {
        setDateError("Birth date is invalid!");
      }
    }
  };

  const handleEditCandidate = () => {
    //check if email is valid
    const emailValid = isEmailValid(email);
    //check if first name is valid
    const firstNameValid = isTextValid(firstName);
    //check if last name is valid
    const lastNameValid = isTextValid(lastName);
    //check if user name is valid
    const userNameValid = isTextValid(userName);
    //check if middle name is valid
    // const middleNameValid = isTextValid(middleName);
    //check if contact is valid
    const contactValid = isMobileNumberValid(contact);

    if (validator.isDate(birthDate)) {
      setDateValid(true);
    } else {
      setDateValid(false);
    }

    //If valid, clear error states and execute the signUp action creator
    if (
      emailValid &&
      firstNameValid &&
      lastNameValid &&
      userNameValid &&
      contactValid &&
      dateValid
    ) {
      setEmailError("");
      setFirstNameError("");
      setLastNameError("");
      setUserNameError("");
      setMiddleNameError("");
      setContactError("");
      setDateError("");
      dispatch(
        updateUser(
          id,
          firstName,
          middleName,
          lastName,
          email,
          userName,
          contact,
          birthDate,
          "candidate"
        )
      );
      onEdit();
    }
    //if email,firstName,lastName or password is invalid, set errors in the state for whatever is invalid
    else {
      if (!emailValid) {
        setEmailError("Email is invalid");
      }
      if (!firstNameValid) {
        setFirstNameError("First Name is invalid!");
      }
      if (!lastNameValid) {
        setLastNameError("Last Name is invalid!");
      }
      if (!userNameValid) {
        setUserNameError("User Name is invalid!");
      }
      // if (!middleNameValid) {
      //   setMiddleNameError("Middle Name cannot be empty!");
      // }
      if (!contactValid) {
        setContactError("Contact is invalid!");
      }
      if (dateValid === false || !birthDate) {
        setDateError("Birth date is invalid!");
      }
    }
  };

  return (
    <div className="add-candidate-container">
      <div className="add-candidate-parent">
        <div>
          <TextField
            value={firstName}
            placeholder="Enter First Name"
            label="First Name"
            required={true}
            handleChange={(e) => setFirstName(e.target.value)}
            // setLastName(e.target.value.replace(/[0-9]/gi, ""))}
          />
          <p className="validation-error">{firstNameError}</p>
        </div>
        <div>
          <TextField
            value={middleName}
            placeholder="Enter Middle Name"
            label="Middle Name"
            handleChange={(e) => setMiddleName(e.target.value)}
            // setLastName(e.target.value.replace(/[0-9]/gi, ""))}
          />
          {/* <p className="validation-error">{middleNameError}</p> */}
        </div>
      </div>

      <div className="add-candidate-parent">
        <div className="add-candidate-lastName">
          <TextField
            value={lastName}
            placeholder="Enter Last Name"
            label="Last Name"
            required={true}
            handleChange={
              (e) => {
                setLastName(e.target.value);
              }
              // setLastName(e.target.value.replace(/[0-9]/gi, ""))
            }
            // setLastName(e.target.value.replace(/[0-9]/gi, ""))}
          />
          <p className="validation-error">{lastNameError}</p>
        </div>
        <div>
          <DatePicker
            value={birthDate}
            label="Birth Date"
            // required={true}
            // className="last-name-text"
            handleChange={(e: any) => handleBirthDate(e.target.value)}
            // myDefaultValue={isUpdate ? birthDate : null}
          />
          <p className="validation-error">{dateError}</p>
        </div>
      </div>

      <div className="add-candidate-parent">
        <div className="add-candidate-left">
          <TextField
            value={contact}
            placeholder="93239*****"
            label="Contact Number"
            required={true}
            handleChange={(e) =>
              setContact(e.target.value.replace(/[^0-9]/gi, ""))
            }
          />
          <p className="validation-error">{contactError}</p>
        </div>
        <div>
          <TextField
            value={userName}
            placeholder="Enter UserName"
            label="User Name"
            required={true}
            handleChange={(e) => setUserName(e.target.value)}
            // type="email"
          />
          <p className="validation-error">{userNameError}</p>
        </div>
      </div>

      <div className="add-candidate-parent">
        <div className="add-candidate-lastName">
          <TextField
            value={email}
            placeholder="Enter Email Address"
            label="Email"
            required={true}
            handleChange={(e) => setEmail(e.target.value.replace(/\s/g, ""))}
            disabled={isUpdate ? true : false}
          />
          <p className="validation-error">{emailError}</p>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            // marginLeft: 20,
          }}
        >
          <Button
            value={isUpdate ? "Update" : "Submit"}
            handleClick={() =>
              isUpdate ? handleEditCandidate() : handleClick()
            }
            className="add-candidate-submit-btn"
            // styles={{ width: 200, height: 55 }}
          />
        </div>
      </div>
    </div>
  );
};
