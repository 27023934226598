import React from "react";
import "./style.css";
interface Props {
  outputDetails?: any;
}
export const OutputDetails: React.FC<Props> = ({ outputDetails }) => {
  return (
    <div className="metrics-container m-auto flex flex-col">
      <p className="text-sm">
        Status:{" "}
        <span className="code-editor-compile-status">
          {outputDetails?.status?.description}
        </span>
      </p>
      <p className="text-sm">
        Memory:{" "}
        <span className="code-editor-compile-status">
          {outputDetails?.memory}
        </span>
      </p>
      <p className="text-sm">
        Time:{" "}
        <span className="code-editor-compile-status">
          {outputDetails?.time}
        </span>
      </p>
    </div>
  );
};
