//Sachin Dhalani - [12/4/2023] - Home page for organization dashboard
import React, { useEffect, useState } from "react";
import { MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import "./style.css";
import {
  ORGANIZATION_HEADING,
  CREATE_ORGANIZATION_BUTTON,
  NAME,
} from "../Constants/constants";
import { Button } from "../../Common/Button/Button";
import { Modal } from "../../Common/Modal/Modal";
import { CreateOrganization } from "./CreateOrganization";
import { CreateAdmin } from "./CreateAdmin";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useAppDispatch } from "../../hooks/app";
import { deleteOrganization } from "../../actions/organization";
import moment from "moment-timezone";
import { displayAdmin, displayOrgAdmin } from "../../actions/admin";
import { ShowAdmin } from "./ShowAdmin";
interface Props {
  organizations?: any;
}
export const Home: React.FC<Props> = ({ organizations }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showAdmin, setShowAdmin] = useState<boolean>(false);
  const [orgId, setOrgId] = useState<any>();
  const [editOrganization, setEditOrganization] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>();
  const [adminData, setAdminData] = useState<any>();
  const admins = useSelector((state: RootState) => state.admin.adminDetails);
  

  let jsonInput = { isCreate: true };
  const handleCreateOrganization = () => {
    setShowModal(true);
  };
  const handleCreateAdmin = async (organizationId: number) => {
    setOrgId(organizationId);
    setOpenModal(true);
  };
  
  
  const handleDisplayAdmin = async (organizationId: number) => {
    // setOrgId(organizationId);
    setShowAdmin(true)
    await dispatch(displayOrgAdmin(organizationId));
  };

  useEffect(() => {
    if (showAdmin) {
      setAdminData(admins);
    }
  }, [admins, showAdmin]);

  const handleCloseModal = () => {
    setShowModal(false);
    setOpenModal(false);
    setShowAdmin(false)
    setEditOrganization(false);
  };
  const dispatch = useAppDispatch();
  const handleDelete = (id: number) => {
    
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1f7ebc",
      cancelButtonColor: "#hhh",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        dispatch(deleteOrganization(id));
      }
    });
  };
  const handleEdit = (data: any) => {
    setEditOrganization(true);
    setEditData(data);
  };
  return (
    <div className="dashboard">
      <div className="heading">
        <p className="primary-texts">{ORGANIZATION_HEADING}</p>
        <div className="button-create-assessment">
          <Button
            value={CREATE_ORGANIZATION_BUTTON}
            className="create-assessment-btn"
            handleClick={handleCreateOrganization}
          />
          <Button
            value="+"
            className="create-assessment-mobile-btn"
            handleClick={handleCreateOrganization}
          />
        </div>
      </div>
      <div>
        <div className="assessment-record">
          <table className="table table-bordered table-display-candidate">
            <thead className="display-candidate-thead">
              <tr>
                <th scope="col">{NAME}</th>
                <th scope="col">Full Name</th>
                <th scope="col">Email</th>
                <th scope="col">Contact</th>
                <th scope="col">Plan</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Reg Number</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {organizations?.length > 0 ? (
                organizations?.map((organizationData: any, index: any) => (
                  <tr className="display-candidate-tr" key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="ms-3">
                          <p className="fw-bold mb-1">
                            {organizationData?.name}
                          </p>
                        </div>
                      </div>
                    </td>

                    <td>
                      <p className="fw-normal mb-1">
                        {organizationData?.firstName +
                          " " +
                          organizationData?.lastName}
                      </p>
                    </td>
                    <td>
                      <p className="fw-normal mb-1">
                        {organizationData?.email}
                      </p>
                    </td>
                    <td>{organizationData?.contact}</td>
                    <td>{organizationData?.selectedPlan}</td>
                    <td>
                      {/* {organizationData?.planStartDate?.trim()} */}
                      {organizationData?.planStartDate
                        ? moment(organizationData?.planStartDate).format(
                            "MM/DD/YYYY"
                          )
                        : "--"}
                    </td>
                    <td>
                      {/* {organizationData?.planEndDate} */}
                      {organizationData?.planEndDate
                        ? moment(organizationData?.planEndDate).format(
                            "MM/DD/YYYY"
                          )
                        : "--"}
                    </td>
                    <td>{organizationData?.regNo}</td>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        cursor={"pointer"}
                        onClick={() => handleEdit(organizationData)}
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                      </svg>
                    </td>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        cursor={"pointer"}
                        onClick={() => handleDelete(organizationData.id)}
                      >
                        <g>
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M20 7v13a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7H2V5h20v2h-2zM6 7v13h12V7H6zm5 2h2v2h-2V9zm0 3h2v2h-2v-2zm0 3h2v2h-2v-2zM7 2h10v2H7V2z" />{" "}
                        </g>
                      </svg>
                    </td>
                    <td>
                      <Button
                        className="org-dashboard-add-admin-btn"
                        value="Add Admin"
                        handleClick={() =>
                          handleCreateAdmin(organizationData?.id)
                        }
                      />
                       <Button
                        className="org-dashboard-add-admin-btn mt-2"
                        value="View Admin"
                        handleClick={() =>
                          handleDisplayAdmin(organizationData?.id)
                        }
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <p className="no-records-found-error-message">
                  No Organization Found
                </p>
              )}
            </tbody>
          </table>
          {openModal ? (
            <Modal
              children={
                <CreateAdmin
                  organizationId={orgId}
                  onSubmit={() => setOpenModal(false)}
                />
              }
              showModalHeader={true}
              modalHeader={"Create Admin"}
              isFlexible={true}
              topRightCloseButtonID={"x-  "}
              showModal={true}
              showBackButton={true}
              showBBPSLogo={true}
              handleBackClick={handleCloseModal}
            ></Modal>
          ) : null}
          {showModal ? (
            <Modal
              children={
                <CreateOrganization
                  isUpdate={false}
                  orgData={jsonInput}
                  onSubmit={() => setShowModal(false)}
                />
              }
              showModalHeader={true}
              modalHeader={"Create Organization"}
              isFlexible={true}
              topRightCloseButtonID={"x-  "}
              showModal={true}
              showBackButton={true}
              showBBPSLogo={true}
              handleBackClick={handleCloseModal}
            ></Modal>
          ) : null}

          {editOrganization ? (
            <Modal
              children={
                <CreateOrganization
                  isUpdate={true}
                  orgData={editData}
                  onEdit={() => setEditOrganization(false)}
                />
              }
              showModalHeader={true}
              modalHeader={"Edit Organization"}
              isFlexible={true}
              topRightCloseButtonID={"x-  "}
              showModal={true}
              showBackButton={true}
              showBBPSLogo={true}
              handleBackClick={handleCloseModal}
            ></Modal>
          ) : null}

         {showAdmin ? (
            <Modal
              modalStyle={{ width: "1000px" }}
              showModal={true}
              children={
                <ShowAdmin
                  // recruiters={admins}
                  adminData={adminData}
                  onSubmit={() => setShowAdmin(false)}
                />
              }
              showModalHeader={true}
              modalHeader={"Admins"}
              isFlexible={true}
              topRightCloseButtonID={"x-  "}
              showBackButton={true}
              showBBPSLogo={true}
              handleBackClick={handleCloseModal}
            ></Modal>
          ) : null}

        </div>
      </div>
    </div>
  );
};
