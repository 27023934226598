//External Imports
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import moment from "moment";
//Internal Imports
import { Button } from "../../Common/Button/Button";
import {
  ATTEMPTS,
  CANDIDATE_HEADING,
  CREATE_ASSESSMENT_BUTTON,
  EMAIL,
  LAST_ACTIVITY,
  NAME,
  RESULT,
  TEST,
} from "../Constants/constants";
import "./style.css";
import { useAppDispatch, useAppSelector } from "../../hooks/app";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { displayCandidatesSelectedOption } from "../../actions/candidate";
interface Props {
  candidates?: any;
  userId?: number;
  OrganizationCandidate?: any;
  candidateQuestionAnswer?: any;
  role?: any;
  recruiterCandidateData?: any;
}
export const Home: React.FC<Props> = ({
  candidates,
  userId,
  OrganizationCandidate,
  candidateQuestionAnswer,
  role,
  recruiterCandidateData,
}) => {
  console.log("candidates of candidate score: ", candidates);
  console.log("userId: of candidate score ", userId);
  console.log("OrganizationCandidate: ", OrganizationCandidate);
  console.log("recruiterCandidateData: ", recruiterCandidateData);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleCandidateDetails = (data: any) => {
    console.log("data: ", data);
    let id: any = [];
    let filtedata = candidateQuestionAnswer.filter(
      (element: any) => element?.candidateExamId === data?.id
    );

    filtedata.map((newData: any) => {
      console.log("newData: ", newData.questionOptionId);
      id.push(newData.questionOptionId);
    });
    console.log("id: ", id.toString());
    let bodyData = id.toString();
    let body = {
      bodyData,
    };
    console.log("body: ", body);

    if (body) {
      dispatch(displayCandidatesSelectedOption(body.bodyData));
    }

    navigate("/candidate-details", {
      state: { data: data, allTypeQuestionsData: candidateQuestionAnswer },
    });
  };

  const fData = [];
  
  for(let i=0;i<candidates?.length;i++){
    if(candidates[i]?.recruiter_id === userId){
      fData.push(candidates[i]);
    }
  }
  console.log('fData: ', fData);


  return (
    <div className="candidate-home">
      <div className="heading">
        {role === "ADMIN" ? (
          <p className="primary-texts">Recruiter - {recruiterCandidateData.firstName} {recruiterCandidateData.lastName}</p>
        ) : (
          <p className="primary-texts">{CANDIDATE_HEADING}</p>
        )}
      </div>
      <div className="assessment-record">
        <table className="table table-bordered table-display-candidate">
          <thead className="display-candidate-thead">
            <tr>
              <th className="col-md-2.4" scope="col">
                {NAME}
              </th>
              <th className="col-md-2.4" scope="col">
                Email
              </th>
              <th className="col-md-2.4" scope="col">
                Stage
              </th>
              <th className="col-md-2.4" scope="col">
                Exam Type
              </th>
              <th className="col-md-2.4" scope="col">
                Invited Date
              </th>
              <th className="col-md-2.4" scope="col">
                Score
              </th>
              <th className="col-md-2.4" scope="col">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {candidates && candidates?.length > 0  && fData?.length > 0 ? (
              candidates?.map((result: any, index: any) =>
                result.recruiter_id === userId ? (
                  <tr className="display-candidate-tr" key={index}>
                    <td>
                      <p className="fw-normal mb-1">
                        {result?.candidateFirstName +
                          " " +
                          result?.candidateLastName}
                      </p>
                    </td>
                    <td>
                      <p className="fw-normal mb-1">{result?.candidateEmail}</p>
                    </td>
                    <td>{result?.examStage}</td>
                    <td>{result?.candidateExamType}</td>
                    <td>
                      {result?.sendDate
                        ? moment(result?.sendDate).format("MM/DD/YYYY - LT")
                        : "--"}
                    </td>
                    {/* <td>{result?.score}%</td> */}
                    {result?.candidateExamType === "Text" ? (
                      <td >NA</td>
                    ): (
                        <td className="">{result?.score}</td>
                    )}
                    <td>
                      <button
                        className="rec-dashboard-send-assessment-btn"
                        onClick={() => {
                          handleCandidateDetails(result);
                        }}
                      >
                        Details
                      </button>
                    </td>
                  </tr>
                ) : null
              )
            ) : (
              <p className="no-records-found-error-message">No Records Found</p>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
