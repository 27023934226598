//All action types are mentioned here as constants
export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILED = "SIGN_IN_FAILED";
export const RESET_USER_DETAILS = "RESET_USER_DETAILS";

export const SIGN_UP = "SIGN_UP";
export const LOGOUT = "LOGOUT";

export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const STOP_LOADING = "STOP_LOADING";
export const START_LOADING = "START_LOADING";
export const LOAD_SCREEN = "LOAD_SCREEN";
export const DELETE_QUESTION_STARTED = "DELETE_QUESTION_STARTED";
export const DELETE_QUESTION_COMPLETED = "DELETE_QUESTION_COMPLETED";
export const DELETE_QUESTION_FAILED = "DELETE_QUESTION_FAILED";
export const DELETE_LOCAL_QUESTION = "DELETE_LOCAL_QUESTION";

//To Display application roles in drop down
export const APPLICATION_ROLES = "APPLICATION_ROLES";
export const APPLICATION_ROLES_ERROR = "APPLICATION_ROLES_ERROR";

//To Display list of Organization
export const DISPLAY_ORGANIZATION = "DISPLAY_ORGANIZATION";
export const DISPLAY_ORGANIZATION_ERROR = "DISPLAY_ORGANIZATION_ERROR";

//To Add Organization
export const ADD_ORGANIZATION = "ADD_ORGANIZATION";
export const ADD_ORGANIZATION_ERROR = "ADD_ORGANIZATION_ERROR";

//To Display list of Admin
export const FETCH_ADMIN = "FETCH_ADMIN";
export const FETCH_ADMIN_ERROR = "FETCH_ADMIN_ERROR";

//To display candidate from organization
export const FETCH_CANDIDATE_FROM_ORGANIZATION = "FETCH_CANDIDATE_FROM_ORGANIZATION";
export const FETCH_CANDIDATE_FROM_ORGANIZATION_ERROR = "FETCH_CANDIDATE_FROM_ORGANIZATION_ERROR";

//To Add Admin
export const ADD_ADMIN = "ADD_ADMIN";
export const ADD_ADMIN_ERROR = "ADD_ADMIN_ERROR";

//To Display list of Recruiters
export const FETCH_RECRUITERS = "FETCH_RECRUITERS";
export const FETCH_RECRUITERS_ERROR = "FETCH_RECRUITERS_ERROR";

//To Add Recruiters
export const ADD_RECRUITERS = "ADD_RECRUITERS";
export const ADD_RECRUITERS_ERROR = "ADD_RECRUITERS_ERROR";

//To Display list of Candidates
export const FETCH_CANDIDATES = "FETCH_CANDIDATES";
export const FETCH_CANDIDATES_ERROR = "FETCH_CANDIDATES_ERROR";

//To Add Candidates
export const ADD_CANDIDATES = "ADD_CANDIDATES";
export const ADD_CANDIDATES_ERROR = "ADD_CANDIDATES_ERROR";

//To Create Assessments
export const CREATE_ASSESSMENT = "CREATE_ASSESSMENT";
export const CREATE_ASSESSMENT_ERROR = "CREATE_ASSESSMENT_ERROR";
export const ADD_LOCAL_QUESTION_ASSESSMENT = "ADD_LOCAL_QUESTION_ASSESSMENT";
export const UPDATE_QUESTIOn_INDEX = "UPDATE_QUESTIOn_INDEX";
export const UPDATE_CREATE_EXAM_STEPPER = "UPDATE_CREATE_EXAM_STEPPER";
export const RESET_CREATE_EXAM = "RESET_CREATE_EXAM";
export const RESET_CREATE_QUESTION = "RESET_CREATE_QUESTION";

export const SET_EXAM_CODE = "SET_EXAM_CODE";
export const RESET_EXAM_CODE = "RESET_EXAM_CODE";

//To display all Assessments
export const DISPLAY_ASSESSMENT = "DISPLAY_ASSESSMENT";
export const DISPLAY_ASSESSMENT_ERROR = "DISPLAY_ASSESSMENT_ERROR";

//To display Assessment by organization Id
export const DISPLAY_ASSESSMENT_ORG = "DISPLAY_ASSESSMENT_ORG";
export const DISPLAY_ASSESSMENT_ORG_ERROR = "DISPLAY_ASSESSMENT_ORG_ERROR";

//To get assessment by exam id
export const DISPLAY_ASSESSMENT_EXAM = "DISPLAY_ASSESSMENT_EXAM";
export const DISPLAY_ASSESSMENT_EXAM_ERROR = "DISPLAY_ASSESSMENT_EXAM_ERROR";

//To add particular question for assessment
export const CREATE_ASSESSMENT_QUESTION = "CREATE_ASSESSMENT_QUESTION";
export const CREATE_ASSESSMENT_QUESTION_ERROR =
  "CREATE_ASSESSMENT_QUESTION_ERROR";
export const UPDATE_QUESTION_REQUEST = "UPDATE_QUESTION_REQUEST";
export const UPDATE_QUESTION_REQUEST_SUCCESS =
  "UPDATE_QUESTION_REQUEST_SUCCESS";
export const UPDATE_QUESTION_REQUEST_FAILED = "UPDATE_QUESTION_REQUEST_FAILED";

export const UPLOAD_QUESTION_REQUEST = "UPLOAD_QUESTION_REQUEST";
export const UPLOAD_QUESTION_REQUEST_SUCCESS =
  "UPLOAD_QUESTION_REQUEST_SUCCESS";
export const UPLOAD_QUESTION_REQUEST_FAILED = "UPLOAD_QUESTION_REQUEST_FAILED";

export const SUBMIT_ANSWER_REQUEST = "SUBMIT_ANSWER_REQUEST";
export const SUBMIT_ANSWER_REQUEST_SUCCESS = "SUBMIT_ANSWER_REQUEST_SUCCESS";
export const SUBMIT_ANSWER_REQUEST_FAILED = "SUBMIT_ANSWER_REQUEST_FAILED";

//To send invitation to candidate
export const CANDIDATE_INVITE = "CANDIDATE_INVITE";
export const CANDIDATE_INVITE_ERROR = "CANDIDATE_INVITE_ERROR";

//To update candidate status
export const UPDATE_CANDIDATE_STATUS_REQUEST =
  "UPDATE_CANDIDATE_STATUS_REQUEST";
export const UPDATE_CANDIDATE_STATUS_SUCCESS =
  "UPDATE_CANDIDATE_STATUS_SUCCESS";
export const UPDATE_CANDIDATE_STATUS_FAILED = "UPDATE_CANDIDATE_STATUS_FAILED";

//To display candidate score & assessment details
export const DISPLAY_CANDIDATE_ASSESSMENT_SCORE_REQUEST =
  "DISPLAY_CANDIDATE_ASSESSMENT_SCORE_REQUEST";
export const DISPLAY_CANDIDATE_ASSESSMENT_SCORE_SUCCESS =
  "DISPLAY_CANDIDATE_ASSESSMENT_SCORE_SUCCESS";
export const DISPLAY_CANDIDATE_ASSESSMENT_SCORE_ERROR =
  "DISPLAY_CANDIDATE_ASSESSMENT_SCORE_ERROR";

//To display candidate by completed status
export const DISPLAY_CANDIDATE_BY_STATUS_REQUEST =
  "DISPLAY_CANDIDATE_BY_STATUS_REQUEST";
export const DISPLAY_CANDIDATE_BY_STATUS_SUCCESS =
  "DISPLAY_CANDIDATE_BY_STATUS_SUCCESS";
export const DISPLAY_CANDIDATE_BY_STATUS_ERROR =
  "DISPLAY_CANDIDATE_BY_STATUS_ERROR";

//To display candidate tracker details of(Device used, location, IP address, mouse location, full screen mode)
export const CREATE_CANDIDATE_TRACKER_DETAIL_REQUEST_SUCCESS =
  "UPDATE_CANDIDATE_DETAIL_REQUEST_SUCCESS";
export const CREATE_CANDIDATE_TRACKER_DETAIL_REQUEST =
  "UPDATE_CANDIDATE_DETAIL_REQUEST";
export const CREATE_CANDIDATE_TRACKER_DETAIL_REQUEST_ERROR =
  "UPDATE_CANDIDATE_DETAIL_REQUEST_ERROR";

  //To display candidate details(Device used, location, IP address, mouse location, full screen mode)
export const DISPLAY_CANDIDATE_TRACKER_DETAIL_PAGE_REQUEST =
"DISPLAY_CANDIDATE_DETAIL_PAGE_REQUEST";
export const DISPLAY_CANDIDATE_TRACKER_DETAIL_PAGE_SUCCESS =
"DISPLAY_CANDIDATE_DETAIL_PAGE_SUCCESS";
export const DISPLAY_CANDIDATE_TRACKER_DETAIL_PAGE_ERROR =
"DISPLAY_CANDIDATE_DETAIL_PAGE_ERROR";

//Candidate Past assessment for candidate dashboard
export const CANDIDATE_PREVIOUS_ASSESSMENT_REQUEST =
  "CANDIDATE_PREVIOUS_ASSESSMENT_REQUEST";
export const CANDIDATE_PREVIOUS_ASSESSMENT_SUCCESS =
  "CANDIDATE_PREVIOUS_ASSESSMENT_SUCCESS";
export const CANDIDATE_PREVIOUS_ASSESSMENT_ERROR =
  "CANDIDATE_PREVIOUS_ASSESSMENT_ERROR";

// To update Users
export const UPDATE_RECRUITERS = "UPDATE_RECRUITERS";
export const UPDATE_RECRUITERS_ERROR = "UPDATE_RECRUITERS_ERROR";

// To update organization
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const UPDATE_ORGANIZATION_ERROR = "UPDATE_ORGANIZATION_ERROR";

//To Delete organizations
export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";
export const DELETE_ORGANIZATION_ERROR = "DELETE_ORGANIZATION_ERROR";

//To send string of base64 converted from array
export const SEND_IMAGE_STRING_ARRAY = "SEND_IMAGE_STRING_ARRAY";
export const SEND_IMAGE_STRING_ARRAY_ERROR = "SEND_IMAGE_STRING_ARRAY_ERROR";

//To get string of base64 and convert to array
export const GET_IMAGE_STRING_ARRAY = "GET_IMAGE_STRING_ARRAY";
export const GET_IMAGE_STRING_ARRAY_ERROR = "GET_IMAGE_STRING_ARRAY_ERROR";

//to get camdidate selected option
export const DISPLAY_CANDIDATE_SELECTED_OPTION = "DISPLAY_CANDIDATE_SELECTED_OPTION";
export const DISPLAY_CANDIDATE_SELECTED_OPTION_ERROR = "DISPLAY_CANDIDATE_SELECTED_OPTION_ERROR"