import React, { useEffect } from "react";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBadge,
} from "mdb-react-ui-kit";
import { Button } from "../../Common/Button/Button";
import "./style.css";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks/app";
import { RootState } from "../../redux/store";
import { displayCandidatesPreviousAssessments } from "../../actions/candidate";
interface Props {
  candidates?: any;
}
export const CandidateDashboardPage: React.FC<Props> = ({ candidates }) => {
  const handleAssessment = () => {
    console.log("clicked");
  };
  const dispatch = useAppDispatch();
  const candidatesList = useSelector(
    (state: RootState) => state.candidate.candidatePreviousAssessment
  );
  useEffect(() => {
    dispatch(displayCandidatesPreviousAssessments());
  }, []);
  return (
    <div className="dashboard">
      <div className="heading">
        <p className="primary-texts">My Assessments</p>
      </div>
      <div>
      <div className="col-sm-12 table-parent-div">
      <table className="table table-bordered table-display-candidate">
      <thead className="display-candidate-thead">
              <tr>
                <th scope="col">Test Name</th>
                <th scope="col">Apply Date</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {candidatesList?.length > 0 ? (
                candidatesList?.map((result: any, index: any) => (
                  <tr key={index}>
                    <td>
                      <p className="fw-normal mb-1">{result?.exam?.name}</p>
                    </td>
                    <td>{result?.sendDate}</td>
                    <td>
                      <MDBBadge color="info" pill>
                        {result?.status}
                      </MDBBadge>
                    </td>
                    <td>
                      <Button
                        value="Start Assessment"
                        className="rec-dashboard-send-assessment-btn"
                        handleClick={handleAssessment}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <p className="no-records-found-error-message">
                  No Records Found
                </p>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
