//Sachin Dhalani - [10/3/2023] - Login page with userName & password validation
import React, { useEffect, useState } from "react";
import { Button } from "../../Common/Button/Button";
import { TextField } from "../../Common/TextField/TextField";
import "./login.css";
import { isPasswordValid, isTextValid } from "../../helpers/validate";
import { signIn } from "../../actions/user";
import { History } from "history"; //import History interface
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
  useParams,
} from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  faFacebook,
  faGithub,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import SignUpImage from "../../Assets/signupbg1.png";
import { useAppDispatch } from "../../hooks/app";
import { RootState } from "../../redux/store";
import { UserRoles } from "../../constants/constants";
import { setExamCode } from "../../actions/assessment";
import { Loading } from "../../Common/Loading/Loading";

interface Props {
  history?: History; //Optional history parameter which is passed by the Router
}
export const SignIn: React.FC<Props> = (props) => {
  //userName in the state and setUserName method for updating the token value
  const [userName, setUserName] = useState<string>("");
  //password variable in the state and setPassword method for updating the token value
  const [password, setPassword] = useState<string>("");
  //passwordError variable in the state and setPasswordError method for updating the token value
  const [passwordError, setPasswordError] = useState<string>("");
  //emailError variable in the state and setEmailError method for updating the token value
  const [userNameError, setUserNameError] = useState<string>("");
  //Get the state and the dispatch properties form the UserContext and rename them to userState and userDispatch resp.
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.user);
  const loading = useSelector((state: RootState) => state.app.loading);
  const navigate = useNavigate();

  const { id } = useParams();
  //Get the state and the dispatch properties form the LoadingContext and rename them to loadingState and loadingDispatch resp.
  //handleClick method that is executed when the Sign In button is clicked
  const handleClick = () => {
    //check if UserName is valid
    const userNameValid = isTextValid(userName);
    //check if password is valid
    const passwordValid = isPasswordValid(password);
    //If valid, clear error states and execute the signIn action creator
    if (userNameValid && passwordValid) {
      setUserNameError("");
      setPasswordError("");
      dispatch(signIn(userName, password));
    }
    //if userName or password is invalid, set errors in the state
    else {
      if (!userNameValid) {
        setUserNameError("User Name cannot be empty");
      }
      if (!passwordValid) {
        setPasswordError("Password is invalid. Must be at least 6 characters");
      }
    }
  };

  useEffect(() => {
    if (user.userDetails && !loading) {
      if (user.userDetails.role === UserRoles.BRAVEN_TOOL_ADMIN) {
        navigate("/organization-dashboard", { replace: true });
      } else if (user.userDetails.role === UserRoles.USER) {
        navigate("/recruiter-dashboard", { replace: true });
      } else if (user.userDetails.role === UserRoles.ADMIN) {
        navigate("/admin-dashboard", { replace: true });
      } else if (user.userDetails.role === UserRoles.CANDIDATE) {
        if (id) {
          dispatch(setExamCode(id));
          navigate("/test-taker", { state: { id } });
        } else {
          navigate("/candidate-dashboard", { replace: true });
        }
      }
    }
  }, [user.userDetails, loading]);
  const loadingState = useSelector((state: RootState) => state.loading);
  return (
    <>
    <div className="main-login-container">
      <div className="login-container">
        <div className="login-image">
          <img src={SignUpImage} className="sign-up-image" alt="" />
        </div>
        {loadingState.loading ? <Loading /> : null}

        <div className="form-container">
          <h2>Sign In</h2>
          <div className="form-content">
            <TextField
              className="sign-in-textfield"
              value={userName}
              label="UserName"
              placeholder="Enter Username"
              handleChange={(e) => setUserName(e.target.value)}
              type="email"
            />
            <p className="validation-error">{userNameError}</p>
            <TextField
              className="sign-in-textfield"
              value={password}
              label="Password"
              placeholder="Enter Password"
              handleChange={(e) => setPassword(e.target.value)}
              type="password"
            />
            <p className="validation-error">{passwordError}</p>

            {/* for remember me and forget password line */}
            {/* <div className="d-flex justify-content-around align-items-center mb-4 checkbox">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="form1Example3"
                />
                <label className="form-check-label"> Remember me </label>
              </div>
              <a href="#!">Forgot password?</a>
            </div> */}

            <Button
              className="sign-in-button"
              value="Sign In"
              handleClick={handleClick}
              id="sign-in_button"
            />
          </div>

          {/* single line */}
          <hr className="my-4"></hr>
          <div>
            <p>
              {/* Ajay Bagul - [13/3/2023] - added social login options */}
              <div className="singlecol">
                <div className="social-media-icons-white d-flex justify-content-evenly ">
                  <a href="">
                    {/* <FontAwesomeIcon icon =  ></FontAwesomeIcon> */}
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                  <a href="">
                    {/* <FontAwesomeIcon icon =  ></FontAwesomeIcon> */}
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                  <a href="">
                    {/* <FontAwesomeIcon icon =  ></FontAwesomeIcon> */}
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                  <a href="">
                    {/* <FontAwesomeIcon icon =  ></FontAwesomeIcon> */}
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                </div>
              </div>
            </p>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};
