//Julian DSa - [27/5/2023] - Camera picture capture every 50 seconds
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface Props {
  capturedPhotos: string[];
  capturedBase64Videos: string[];
  setCapturedPhotos: any;
  setCapturedBase64Videos: any;
}

export const VideoCapture: React.FC<Props> = ({
  capturedPhotos,
  capturedBase64Videos,
  setCapturedPhotos,
  setCapturedBase64Videos,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const assessmentObj = useSelector((state: RootState) => state.assessment);
  const cameraEnabled =
    assessmentObj?.displayAssessmentExam?.candidateExam?.cameraEnabled;
  console.log("camera", cameraEnabled);
  const tracker =
    assessmentObj?.displayAssessmentExam?.candidateExam?.activityTrackerEnabled;
  console.log("tracker", tracker);

  useEffect(() => {
    if (!cameraEnabled) {
      return;
    } else {
      const capturePhotoInterval = setInterval(captureFrame, 15000);

      return () => {
        clearInterval(capturePhotoInterval);
      };
    }
  }, [capturedPhotos, cameraEnabled]);

  useEffect(() => {
    if (!cameraEnabled) {
      return;
    } else {
      const captureVideoInterval = setInterval(captureVideo, 15000);

      return () => {
        clearInterval(captureVideoInterval);
      };
    }
  }, [capturedBase64Videos, cameraEnabled]);

  useEffect(() => {
    if (!cameraEnabled) {
      return;
    } else {
      if (videoRef.current) {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: true })
          .then((stream) => {
            videoRef.current!.srcObject = stream;
            videoRef.current!.play();
            mediaRecorderRef.current = new MediaRecorder(stream);
          })
          .catch((error) => {
            console.error("Error");
          });
      }
    }
  }, [cameraEnabled]);

  const captureFrame = () => {
    if (!cameraEnabled) {
      return;
    } else {
      const video = videoRef.current!;
      const canvas = canvasRef.current!;
      const context = canvas.getContext("2d")!;

      const width: any = video?.videoWidth;
      canvas.width = width;
      const height: any = video?.videoHeight;
      canvas.height = height;

      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const dataUrl = canvas.toDataURL("image/jpeg");
      setCapturedPhotos((prevPhotos: any) => [...prevPhotos, dataUrl]);
    }
  };

  const startRecording = () => {
    if (!cameraEnabled) {
      return;
    } else {
      // mediaChunksRef.current = [];
      const mediaStream = videoRef.current!.srcObject as MediaStream;
      const chunks: Blob[] = [];
      mediaRecorderRef.current = new MediaRecorder(mediaStream);

      mediaRecorderRef.current.addEventListener("dataavailable", (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      });

      mediaRecorderRef.current.addEventListener("stop", () => {
        const blob = new Blob(chunks, { type: "video/webm" });

        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Video = reader.result as string;
          setCapturedBase64Videos((prevVideo: any) => [
            ...prevVideo,
            base64Video,
          ]);
        };
        reader.readAsDataURL(blob);
      });

      mediaRecorderRef.current.start();
    }
  };

  const stopRecording = () => {
    mediaRecorderRef.current?.stop();
  };

  const processVideoBlob = (blob: Blob) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Video = reader.result as string;
      setCapturedBase64Videos((prevVideo: any) => [...prevVideo, base64Video]);
    };
    reader.readAsDataURL(blob);
  };

  const captureVideo = () => {
    if (!cameraEnabled) {
      return;
    } else {
      startRecording();

      setTimeout(() => {
        stopRecording();
      }, 5000);
    }
  };

  const handleVideoStream = (stream: MediaStream) => {
    const video = videoRef.current!;
    video.srcObject = stream;

    video.onloadeddata = () => {
      video.play();
    };
  };

  const handleVideoError = (error: MediaStream) => {
    console.log("Error accessing webcam:", error);
  };

  useEffect(() => {
    if (!cameraEnabled) {
      return;
    } else {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: false })
          .then(handleVideoStream)
          .catch(handleVideoError);
      } else {
        console.log("getUserMedia is not supported in this browser");
      }
    }
  }, [cameraEnabled]);

  useEffect(() => {
    if (!cameraEnabled) {
      return;
    } else {
      console.log(capturedPhotos);
      //   console.log(capturedVideos);
      console.log(capturedBase64Videos);
    }
  }, [capturedPhotos, capturedBase64Videos, cameraEnabled]);

  return (
    <div>
      {cameraEnabled ? (
        <>
          <video muted ref={videoRef} style={{ display: "none" }} />
          <canvas ref={canvasRef} style={{ display: "none" }} />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
