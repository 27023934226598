import { Home } from "./Home";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks/app";
import React, { useEffect, useState } from "react";
import { RootState } from "../../redux/store";
import {
  displayAllAssessments,
  displayAllOrganizationAssessments,
} from "../../actions/assessment";
import { Navbar } from "../OrganizationDashboard/Navbar";
import jwt_decode from "jwt-decode";
export const BravensTestQuestions: React.FC = () => {
  const [role, setRole] = useState("");
  const dispatch = useAppDispatch();
  const token: any = localStorage.getItem("token");
  let decoded: any = jwt_decode(token);

  const sampleTest = useSelector(
    (state: RootState) => state.assessment.displayAssessment
  );
  useEffect(() => {
    if (decoded) {
      setRole(decoded?.role);
      console.log("sampleTest: ", sampleTest);
    }
  }, []);
  const displayAdmins = async () => {
    await dispatch(
      displayAllOrganizationAssessments(decoded?.organization?.id)
    );
  };

  useEffect(() => {
    dispatch(displayAllAssessments());
    displayAdmins();
  }, []);

  return (
    <div>
      <Navbar />
      <Home sampleTest={sampleTest} />
    </div>
  );
};
