//Sachin Dhalani - [10/3/2023]
import React, { useEffect, useState } from "react";
import { Button } from "../../Common/Button/Button";
import { TextField } from "../../Common/TextField/TextField";
import { History } from "history"; //import History interface
import { isEmailValid, isNameValid, isTextValid } from "../../helpers/validate";
import { DropDown } from "../../Common/DropDown/DropDown";
import {
  addOrganization,
  updateOrganization,
} from "../../actions/organization";
import { useAppDispatch } from "../../hooks/app";
import "./style.css";
interface Props {
  onSubmit?: any;
  orgData?: any;
  isUpdate?: boolean;
  onEdit?: any;
  history?: History; //Optional history parameter which is passed by the Router
}
export const CreateOrganization: React.FC<Props> = ({
  onSubmit,
  orgData,
  isUpdate,
  onEdit,
}) => {
  console.log("orgData: ", orgData);
  //firstName variable in the state and setFirstName method for updating the token value
  const [name, setName] = useState("");
  //firstName variable in the state and setFirstName method for updating the token value
  const [firstName, setFirstName] = useState("");
  //LastName variable in the state and setLastName method for updating the token value
  const [lastName, setLastName] = useState("");
  //planStartDate variable in the state and setPlanStartDate method for updating the token value
  const [contact_country_code, setContact_country_code] = useState("+91");
  //registrationNumber variable in the state and setRegistrationNumber method for updating the token value
  const [regNo, setRegistrationNumber] = useState("");
  //uniqueId variable in the state and setUniqueId method for updating the token value
  const [uniqId, setUniqueId] = useState("");
  //numberOfUsers variable in the state and setNumberOfUsers method for updating the token value
  const [numberOfUsers, setNumberOfUsers] = useState<any>();
  //userName in the state and setUserName method for updating the token value
  const [userName, setUserName] = useState("");
  //mobileNumber in the state and setNumber method for updating the token value
  const [contact, setContact] = useState<string>("");
  //email variable in the state and setEmail method for updating the token value
  const [email, setEmail] = useState<string>("");
  //role in the state and setRole method for updating the token value
  const [selectedPlan, setSelectedPlan] = useState<string>("");
  //emailError variable in the state and setEmailError method for updating the token value
  const [emailError, setEmailError] = useState<string>("");
  //firstNameError variable in the state and setFirstNameError method for updating the token value
  const [firstNameError, setFirstNameError] = useState<string>("");
  //middleNameError variable in the state and setMiddleNameError method for updating the token value
  const [orgNameError, setOrgNameError] = useState<string>("");
  //lastNameError variable in the state and setLastNameError method for updating the token value
  const [lastNameError, setLastNameError] = useState<string>("");
  //userNameError variable in the state and setUserNameError method for updating the token value
  const [userNameError, setUserNameError] = useState<string>("");
  const [regnoError, setRegnoError] = useState<string>("");
  const [uniqIdError, setUniqIdError] = useState<string>("");
  const [numberOfUsersError, setNumberOfUsersError] = useState<string>("");
  const [contactError, setContactError] = useState<string>("");
  const [SelectedPlanError, setSelectedPlanError] = useState<string>("");
  const [counter, setCounter] = useState<any>(0);
  const [id, setId] = useState<any>();
  const [middleName, setMiddleName] = useState<string>("");

  // setName(orgData.name)

  useEffect(() => {
    if (orgData) {
      setId(orgData.id);
      setName(orgData.name);
      setFirstName(orgData.firstName);
      setLastName(orgData.lastName);
      setUniqueId(orgData.uniqId);
      setRegistrationNumber(orgData.regNo);
      setNumberOfUsers(orgData.numberOfUsers);
      setEmail(orgData.email);
      setUserName(orgData.userName);
      setContact(orgData.contact);
      setSelectedPlan(orgData.selectedPlan);
    }
  }, []);

  const planOptions = [
    {
      label: "FREE",
      value: "FREE",
    },
    {
      label: "SILVER",
      value: "SILVER",
    },
    {
      label: "GOLD",
      value: "GOLD",
    },
    {
      label: "PLATINUM",
      value: "PLATINUM",
    },
    {
      label: "CUSTOM",
      value: "CUSTOME",
    },
  ];

  const data = {
    name,
    email,
    firstName,
    middleName,
    lastName,
    contact,
    contact_country_code,
    selectedPlan,
    numberOfUsers,
    uniqId,
    regNo,
  };
  console.log("data: ", data);

  const dispatch = useAppDispatch();
  const handleClick = async () => {
    setContact_country_code("+91");
    //check if email is valid
    const emailValid = isEmailValid(email);
    //check if first name is valid
    const firstNameValid = isTextValid(firstName);
    //check if organization name is valid
    const organizationNameValid = isTextValid(name);
    //check if last name is valid
    const lastNameValid = isTextValid(lastName);
    //check if user name is valid
    const userNameValid = isTextValid(userName);
    //If valid, clear error states and execute the signUp action creator
    if (
      emailValid &&
      organizationNameValid &&
      name &&
      contact &&
      contact_country_code &&
      selectedPlan &&
      numberOfUsers &&
      uniqId &&
      regNo
    ) {
      setEmailError("");
      setFirstNameError("");
      setOrgNameError("");
      setContactError("");
      setNumberOfUsersError("");
      setLastNameError("");
      // setUserNameError("");

      dispatch(
        addOrganization(
          name,
          firstName,
          middleName,
          lastName,
          email,
          contact,
          contact_country_code,
          selectedPlan,
          numberOfUsers,
          uniqId,
          regNo
        )
      );
      onSubmit();
    }
    //if email,firstName,lastName or password is invalid, set errors in the state for whatever is invalid
    else {
      if (!emailValid) {
        setEmailError("Email is invalid!");
      }
      if (!userNameValid) {
        setUserNameError("User Name is invalid!");
      }
      if (!firstNameValid || !firstName) {
        setFirstNameError("First name is invalid!");
      }
      if (!lastNameValid || !lastName) {
        setLastNameError("Last name is invalid!");
      }
      if (!name) {
        setOrgNameError("Organization Name is invalid!");
      }
      if (name && !organizationNameValid) {
        setOrgNameError("Organization Name is invalid!");
      }
      if (!regNo) {
        setRegnoError("Register number is invalid!");
      }
      if (!uniqId) {
        setUniqIdError("UniqId is invalid!");
      }
      if (!numberOfUsers) {
        setNumberOfUsersError("Number of user is invalid!");
      }
      if (!contact) {
        setContactError("Contact is invalid!");
      }
      if (!selectedPlan) {
        setSelectedPlanError("Selected plan is invalid!");
      }
    }
  };

  const handleEditOrganization = () => {
    if (
      name &&
      contact &&
      firstName&&
      contact_country_code &&
      selectedPlan &&
      numberOfUsers &&
      uniqId &&
      regNo
    ) {
      setFirstNameError("");
      setOrgNameError("");
      setContactError("");
      setNumberOfUsersError("");
      setLastNameError("");
      dispatch(
        updateOrganization(
          id,
          name,
          firstName,
          middleName,
          lastName,
          contact,
          selectedPlan,
          numberOfUsers,
          userName
        )
      );
      onEdit();
    }else{
      if (!firstName) {
        setFirstNameError("First name is invalid!");
      }
      if (!lastName) {
        setLastNameError("Last name is invalid!");
      }
      if (!name) {
        setOrgNameError("Organization Name is invalid!");
      }
      if (!regNo) {
        setRegnoError("Register number is invalid!");
      }
      if (!uniqId) {
        setUniqIdError("UniqId is invalid!");
      }
      if (!numberOfUsers) {
        setNumberOfUsersError("Number of user is invalid!");
      }
      if (!contact) {
        setContactError("Contact is invalid!");
      }
      if (!selectedPlan) {
        setSelectedPlanError("Selected plan is invalid!");
      }
    }
  };
  return (
    <div className="add-Organization-page">
      <div className="add-organization-page-container">
        <div >
          <TextField
            value={name}
            placeholder="Enter Organization Name"
            label="*Organization Name"
            type="text"
            handleChange={(e) => {
              setName(e.target.value);
            }}
          />
          <p className="validation-error">{orgNameError}</p>
        </div>
        <div className="add-org-left">
          <TextField
            value={uniqId}
            placeholder="set unique id for organization"
            label="*Unique Id"
            handleChange={(e) => setUniqueId(e.target.value.replace(/[^0-9]/gi, ""))}
            disabled={isUpdate ? true : false}
          />
          <p className="validation-error">{uniqIdError}</p>
        </div>
      </div>
     
     
      {/* <p className="Other-Validation-Error">{uniqIdError}</p> */}
      <div className="add-organization-page-container">
        <div >
          <TextField
            value={regNo}
            placeholder="Enter Registration Number"
            label="*Registration Number"
            handleChange={(e) => setRegistrationNumber(e.target.value.replace(/[^0-9]/gi, ""))}
            disabled={isUpdate ? true : false}
          />
          <p className="validation-error">{regnoError}</p>
        </div>
        <div className="add-org-left">
          <TextField
            value={numberOfUsers}
            placeholder="Enter Number of users"
            label="*Number of Users"
            handleChange={(e) => setNumberOfUsers(e.target.value.replace(/[^0-9]/gi, ''))}
          />
          <p className="validation-error">{numberOfUsersError}</p>
        </div>
      </div>
     
     
      <div className="add-organization-page-container">
        <div className="add-org-down">
          <TextField
            value={email}
            placeholder="Enter Email Address"
            label="*Email"
            handleChange={(e) => setEmail(e.target.value.replace(/\s/g, ''))}
            disabled={isUpdate ? true : false}
          />
          <p className="validation-error">{emailError}</p>
        </div>
        <div >
          <TextField
            value={contact}
            placeholder="93239*****"
            label="*Contact Number"
            handleChange={(e) => setContact(e.target.value.replace(/[^0-9]/gi, ''))}
          />
          <p className="validation-error">{contactError}</p>
        </div>
        {/* <div className="add-candidate-text">
          <TextField
            value={userName}
            placeholder="Enter UserName"
            label="*User Name"
            handleChange={(e) => setUserName(e.target.value)}
            // type="email"
          />
        </div> */}
      </div>
      <div className="add-organization-page-container">
        <div className="add-org-last">
          <TextField
            value={firstName}
            placeholder="Enter First Name"
            label="*First Name"
            handleChange={(e) =>
              setFirstName(e.target.value)
            }
          />
          <p className="validation-error">{firstNameError}</p>
        </div>
        <div >
          <TextField
            value={lastName}
            placeholder="Enter Last Name"
            label="*Last Name"
            handleChange={(e) =>
              setLastName(e.target.value)
            }
          />
          <p className="validation-error">{lastNameError}</p>
        </div>
      </div>
     
     
      <div className="add-organization-page-container">
        <div className="add-candidate-text">
          <div
            style={{
              display: "flex",
              flex: 1,
              marginLeft: "-10%",
              width: "110%",
            }}
          >
            <DropDown
              value={selectedPlan}
              options={planOptions}
              noOptionsText={"No Plan Found"}
              label="*Plan"
              className="plan-drop-down"
              handleChange={(e: any) => {
                setSelectedPlan(e.value);
              }}
              showLabel={true}
            />
          </div>
        </div>
        {/* <p className="ContactError">{contactError}</p> */}
        <div className="add-candidate-text">
          {" "}
          <div style={{ display: "flex", flex: 1 }}></div>
        </div>
      </div>
      <p className="Plan-error">{SelectedPlanError}</p>
      {/* <p className="OtherErrors">{lastNameError}</p> */}
      <div className="add-organization-page-container">
        <div className="create-admin-submit-btn">
          <Button
            value={isUpdate ? "Update" : "Submit"}
            handleClick={() =>
              isUpdate ? handleEditOrganization() : handleClick()
            }
            className="add-candidate-submit-btn"
          />
        </div>
      </div>
    </div>
  );
};
