
import React, { useState } from "react";
import { Landing } from "./Landing";
import { VideoCapture } from "../Questions/VideoCapture";

export const CodeEditor: React.FC = () => {
  const [capturedPhotos, setCapturedPhotos] = useState<string[]>([]);
  const [capturedBase64Videos, setCapturedBase64Videos] = useState<string[]>([]);

  return (
    <>
      <Landing capturedPhotos={capturedPhotos} capturedBase64Videos={capturedBase64Videos} />
      <VideoCapture capturedPhotos={capturedPhotos} setCapturedPhotos={setCapturedPhotos}
        capturedBase64Videos={capturedBase64Videos} setCapturedBase64Videos={setCapturedBase64Videos} />
    </>
  );
};
