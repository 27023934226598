//Sachin Dhalani - [3/14/2023] - simple dashboard for Test taker which display name of the person taking test & few instructions
import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { Button } from "../../Common/Button/Button";
import { useAppDispatch } from "../../hooks/app";
import { useFullScreenHandle } from "react-full-screen";
import {
  GET_STARTED,
  GOOD_LUCK,
  WELCOME_SUB_HEADING_TEXT,
  WELCOME_TEXT,
} from "../Constants/constants";
import "./style.css";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getExamByCode } from "../../actions/assessment";
import Swal from "sweetalert2";
import { db, storage } from "../Questions/config";

export const TestInstructions: React.FC = () => {
  const [img, setImg] = useState(null);

  const dispatch = useAppDispatch();
  const assessmentObj = useSelector((state: RootState) => state.assessment);

  const cameraEnabled =
    assessmentObj?.displayAssessmentExam?.candidateExam?.cameraEnabled;

  const tracker =
    assessmentObj?.displayAssessmentExam?.candidateExam?.activityTrackerEnabled;

  //
  let exam_candidate_id: number =
    assessmentObj?.displayAssessmentExam?.candidateExam?.id;
  const exam: any = assessmentObj?.displayAssessmentExam?.exam;

  useEffect(() => {
    if (assessmentObj.examCode) {
      dispatch(getExamByCode(assessmentObj.examCode));
    }
  }, []);
  const status = assessmentObj?.displayAssessmentExam?.candidateExam?.status;
  const user: any = localStorage.getItem("user");
  const userDetails = JSON.parse(user);

  const navigate = useNavigate();
  let subtitle: any;
  const [name, setName] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [imageCaptured, setImageCaptured] = useState(false);

  const [deviceUsed, setDevice] = useState<any>();
  const [latitude, setLatitude] = useState<any>();
  const [longitude, setLongitude] = useState<any>();
  const [webCamEnabled, setWebCamEnabled] = useState<boolean>(false);
  const [isCameraOn, setIsCameraOn] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isMobile = /Mobi|Android/i.test(userAgent);
    const isTablet = /Tablet|iPad/i.test(userAgent);

    if (isMobile) {
      setDevice("Mobile");
    } else if (isTablet) {
      setDevice("Tablet");
    } else {
      setDevice("Desktop");
    }
  }, []);

  const openModal = () => {
    // dispatch(
    //   createCandidateTrackerDetails(
    //     exam_candidate_id,
    //     deviceUsed,
    //     latitude,
    //     longitude,
    //     webCamEnabled,
    //     ipAddress,
    //     mouseMovedOutside,
    //     countMouseMovedOutside,
    //     switchingTab,
    //     countSwitchingTab,
    //     fullScreenMode,
    //   )
    // );
    setIsOpen(true);
  };

  const trackerData = {
    exam_candidate_id: exam_candidate_id,
    deviceUsed: deviceUsed,
    latitude: latitude,
    longitude: longitude,
    webCamEnabled: webCamEnabled,
    // ipAddress: ipAddress,
    // mouseMovedOutside: mouseMovedOutside,
    // countMouseMovedOutside: countMouseMovedOutside,
    // switchingTab: switchingTab,
    // countSwitchingTab: countSwitchingTab,
    // fullScreenMode: fullScreenMode,
  };

  //

  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };
  const element = document.documentElement;

  useEffect(() => {
    setName(userDetails.firstName);
  }, []);

  const closeModal = () => {
    element.requestFullscreen();

    if (
      (status === "NOT_STARTED" || status === "IN_PROGRESS") &&
      exam.questions[0].questionType === "CODE"
    ) {
      navigate("/code-editor", {
        state: {
          questions: exam.questions,
          trackerData: trackerData,
          firstImage: img,
        },
      });
    } else if (status === "NOT_STARTED" || status === "IN_PROGRESS") {
      navigate("/questions", {
        state: { trackerData: trackerData, firstImage: img },
      });
    } else {
      navigate("/candidate-dashboard-test-error", { state: status });
    }
  };

  // useEffect(() => {
  //   // Some synchronous code.
  //   componentDidMount();
  //   // (async () => {
  //   //   const result = await axios.get("https://api.ipify.org/?format=json");
  //   //   setIP(result.data.ip);
  //   //   setDevice(window.innerWidth <= 600 ? "Mobile" : "Desktop");
  //   //   setFullScreenMode(true);
  //   // })();
  //   return () => {
  //     // Component unmount code.
  //   };
  // }, []);

  //
  //Location fetching Latitude and Longitude
  useEffect(() => {
    const componentDidMount = () => {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLatitude(position.coords.latitude + " ");
        setLongitude(position.coords.longitude + " ");
      });
    };
    componentDidMount();
  }, []);

  const webcamRef: any = useRef(null);

  // const capture = useCallback(() => {
  //
  //   const imageSrc = webcamRef.current.getScreenshot();

  //   setImageCaptured(imageSrc);
  // }, [webcamRef]);
  const capture = useCallback(() => {
    setImageCaptured(true);
    const imageSrc = webcamRef.current.getScreenshot();

    setImg(imageSrc);
  }, [webcamRef]);

  const uploadImage = async () => {
    if (img) {
      const timestamp = Date.now();

      const imageName = `${timestamp}.jpg`;

      const storageRef = ref(
        storage,
        `images/${exam?.id}/${userDetails?.id}/${imageName}`
      );

      // try {
      //   // Upload image to Firebase Storage
      //   await uploadString(storageRef, img, "data_url");
      //
      //   const imageURL = await getDownloadURL(storageRef);
      //

      //   // Store metadata in Firestore
      //   // const docRef = await addDoc(collection(db, "images"), {
      //   //   userId: userDetails?.id,
      //   //   imageUrl: imageURL,
      //   //   createdAt: timestamp,
      //   // });
      //   const databaseURL =
      //     "https://bravenstoolimageupload-default-rtdb.firebaseio.com//users.json?auth=AIzaSyCi1tuVZ9XCt46GzUhbcqonI6XlAG-sSKo";
      //   // const endpoint = 'users.json'; // Replace 'data' with the appropriate path
      //   // https://bravens-2-default-rtdb.firebaseio.com/

      //   const requestBody = {
      //     userId: userDetails?.id,
      //     exam: exam.id,
      //     exam_candidate_ids: exam_candidate_id,
      //     images: img,
      //     videos: null,
      //   };

      //   const response = await fetch(`${databaseURL}`, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(requestBody),
      //   });

      //   if (response.ok) {
      //
      //
      //   } else {
      //
      //   }
      //   alert("Image uploaded and metadata saved successfully!");
      // } catch (error) {
      //
      // }
    }
  };

  // const getImagesByUserId = async (userDetails?.id) => {
  //   const q = query(collection(db, 'images'), where('userId', '==', userDetails));
  //   const querySnapshot = await getDocs(q);

  //   const images = [];
  //   querySnapshot.forEach((doc) => {
  //     images.push(doc.data());
  //   });

  //
  //   return images;
  // };

  // Usage example:
  // getImagesByUserId('exampleUserId').then((images) => {
  //   // Handle the images array, e.g., display them in your app
  // });
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "-30%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      height: "90vh",
    },
  };
  const handle = useFullScreenHandle();

  // chetan patil [9-6-2023] - checkCameraStatus() function returns true if the camera is enabled or not

  useEffect(() => {
    if (!cameraEnabled) {
      return;
    } else {
      checkCameraStatus();
    }
    async function checkCameraStatus() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setIsCameraOn(true);
        stream.getTracks().forEach((track) => track.stop());
        setWebCamEnabled(true);
      } catch (error) {
        Swal.fire(
          "WARNING!",
          "Please start your camera to start the test"
          // 'You changed tab more than 3 times, so test is ended',
        );
        setIsCameraOn(false);
        setWebCamEnabled(false);
      }
    }
  }, [cameraEnabled]);

  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    if (tracker) {
      const handleFullScreenChange = () => {
        setIsFullScreen(document.fullscreenElement !== null);
      };

      document.addEventListener("fullscreenchange", handleFullScreenChange);

      return () => {
        document.removeEventListener(
          "fullscreenchange",
          handleFullScreenChange
        );
      };
    } else {
      return;
    }
  }, [tracker]);

  return (
    <div className="home-test-taker">
      <div className="test-taker-home-container">
        <div className="welcome-test-taker">
          <>
            <p className="test-taker-heading">Hello {name},</p>
            <p className="welcome-text">{WELCOME_TEXT}</p>
            <p className="welcome-text">{WELCOME_SUB_HEADING_TEXT}</p>
            <p className="welcome-text">{GOOD_LUCK}</p>
          </>
        </div>
        <Button
          value={GET_STARTED}
          handleClick={openModal}
          className="button-test-taker"
        />
        {/* {ipAddress ? ( */}
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Camera Access Modal"
        >
          <h4>Welcome To The Assessment</h4>
          {cameraEnabled && tracker ? (
            <>
              <p className="welcome-modal-text">
                This is a <strong>Lockdown Assessment. </strong>
                Your recruiter has indicated that they want details on your
                camera and screen while you take the test. Upon taking this
                assessment you consent to having your webcam and screen activity
                monitored for the duration of the assessment and sent to your
                recruiter. You may be penalized for activities such as moving
                your mouse off the screen, switching tabs, and turning off your
                camera.
              </p>
              {img === null ? (
                <>
                  <Webcam
                    // audio={true}
                    mirrored={true}
                    height={250}
                    width={400}
                    style={{ alignItems: "center" }}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                  />
                  <Button
                    handleClick={capture}
                    className="button-capture-image"
                    value="Capture"
                  />
                </>
              ) : (
                <>
                  <img src={img} alt="screenshot" />
                  <Button
                    handleClick={() => setImg(null)}
                    className="button-capture-image"
                    value="Retake"
                  />

                  <Button
                    handleClick={uploadImage}
                    className="button-upload-image"
                    value="Upload"
                  />
                </>
              )}
            </>
          ) : cameraEnabled ? (
            <>
              <p className="welcome-modal-text">
                Your recruiter has indicated that they want you to turn on your
                webcam. Upon taking this assessment you consent to having your
                webcam monitored for the duration of the assessment and sent to
                your recruiter. You may be penalized for activities such turning
                off your camera or moving away from it.
              </p>
              {img === null ? (
                <>
                  <Webcam
                    audio={true}
                    mirrored={true}
                    height={250}
                    width={400}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                  />
                  <Button
                    handleClick={capture}
                    className="button-capture-image"
                    value="Capture"
                  />
                </>
              ) : (
                <>
                  <img src={img} alt="screenshot" />
                  <Button
                    handleClick={() => setImg(null)}
                    className="button-capture-image"
                    value="Retake"
                  />

                  <Button
                    handleClick={uploadImage}
                    className="button-upload-image"
                    value="Upload"
                  />
                </>
              )}
            </>
          ) : tracker ? (
            <>
              <p className="welcome-modal-text">
                Your recruiter has indicated that they want details on your
                screen activity.{" "}
              </p>
              <p className="welcome-modal-text">
                Your camera will not be monitored
              </p>
              <p className="welcome-modal-text">
                {" "}
                Upon taking this assessment you consent to having your screen
                activity tracked for the duration of the assessment and sent to
                your recruiter. You may be penalized for activities mousing your
                mouse outside the window, switching tabs, or not having the
                assessment window in fullscreen
              </p>
            </>
          ) : (
            <>
              <p className="welcome-text">
                Your recruiter has indicated that they do not want your webcam
              </p>
              <p className="welcome-text">or screen activity monitored</p>
              <p className="welcome-text">
                Please follow all guidelines of your organization when taking
                this assessment
              </p>
            </>
          )}

          <p className="welcome-text">Good luck on the Assessment!</p>

          <Button
            value="Continue"
            className="button-test-taker"
            handleClick={closeModal}
          />
        </Modal>
        {/* ) : (
          <DataLoading />       
        )} */}
      </div>
    </div>
  );
};
