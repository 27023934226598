//Sachin Dhalani - [18/4/2023]
import React from "react";
import { Home } from "./Home";
import { Navbar } from "../RecruitersDashboard/Navbar";
export const ExcelUpload: React.FC = () => {
  return (
    <div>
      <Navbar/>
      <Home />
    </div>
  );
};
