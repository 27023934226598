//Sachin Dhalani - To display sample test which recruiter can select and create assessment
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
//Internal Imports
import { Button } from "../../Common/Button/Button";
import { MultiStepContext } from "../../context/MultiStepContext";
import { ButtonImage } from "../../Common/ButtonImage/ButtonImage";
import MCQLogoImage from "../../Assets/mcq.svg";
import CodeLogoImage from "../../Assets/code.svg";
import UploadImage from "../../Assets/upload.svg";
import "./style.css";
import {
  addLocalQuestion,
  displayAllOrganizationAssessments,
  updateCreateExamStepper,
} from "../../actions/assessment";
import { useAppDispatch } from "../../hooks/app";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import jwt_decode from "jwt-decode";
export const SecondStep: React.FC = () => {
  //calling context to store & get values of text filed globally
  const dispatch = useAppDispatch();
  const token: any = localStorage.getItem("token");
  let decoded: any = jwt_decode(token);
  const displayAdmins = async () => {
    await dispatch(
      displayAllOrganizationAssessments(decoded?.organization?.id)
    );
  };
  useEffect(() => {
    displayAdmins();
  }, []);
  //function to navigate to third/next step
  const handleNextStep = () => {
    dispatch(addLocalQuestion());
    dispatch(updateCreateExamStepper(3));
    // setStep(3);
  };
  const handleTest = (id: any, name: any, data: any = null) => {
    // setSelectedTest(data);
    // setData(id);
    // setName(name);
    // // setOpenDetailModel(true);
    // setOpenModal(true);
  };

  return (
    <div>
      <div className="second-step-main-container">
        <p className="custom-questions-heading">Create Custom Questions </p>

        <div className="button-image-container">
          <div className="button-image">
            {/* button image to add mcq questions  */}
            <ButtonImage
              imageSrc={MCQLogoImage}
              imageAlt="MCQ image"
              value="Custom Questions"
              handleClick={handleNextStep}
            />
          </div>
          <div className="button-image">
            {/* button image to add questions using file upload */}
            <Link to="/file" className="link-style">
              <ButtonImage
                imageSrc={UploadImage}
                imageAlt="file upload image"
                value="File Upload"
                // handleClick={handleNextStep}
              />
            </Link>
          </div>
          {/* <div className="button-image">

            <Link to="/code-editor" className="link-style">
              <ButtonImage
                imageSrc={CodeLogoImage}
                imageAlt="Code image"
                value="Code"
              />
            </Link>
          </div> */}
        </div>
      </div>
      {/* <p className="partition">
        <span>OR</span>
      </p>
      <p className="custom-questions-heading">Select Sample Questions </p>
      <div className="item-container">
        {orgTest?.map((data: any, index: any) => (
          <div className="card" key={index}>
            <p className="primary-text"> {data.name}</p>

            <Button
              className="select-test-button"
              value="Select Test"
              handleClick={() => handleTest(data?.id, data?.name, data)}
            />
          </div>
        ))}
      </div> */}
    </div>
  );
};
