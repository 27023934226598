//Sachin Dhalani - [20/4/2023]
import { RootState } from "./../redux/store/index";
import {
  CREATE_ASSESSMENT_QUESTION,
  CREATE_ASSESSMENT_QUESTION_ERROR,
  CREATE_ASSESSMENT,
  CREATE_ASSESSMENT_ERROR,
  DISPLAY_ASSESSMENT,
  DISPLAY_ASSESSMENT_ERROR,
  DISPLAY_ASSESSMENT_EXAM,
  DISPLAY_ASSESSMENT_EXAM_ERROR,
  DISPLAY_ASSESSMENT_ORG,
  DISPLAY_ASSESSMENT_ORG_ERROR,
  START_LOADING,
  ADD_LOCAL_QUESTION_ASSESSMENT,
  UPDATE_QUESTIOn_INDEX,
  DELETE_QUESTION_STARTED,
  DELETE_QUESTION_COMPLETED,
  DELETE_QUESTION_FAILED,
  UPDATE_QUESTION_REQUEST,
  UPDATE_QUESTION_REQUEST_SUCCESS,
  UPDATE_QUESTION_REQUEST_FAILED,
  UPLOAD_QUESTION_REQUEST_SUCCESS,
  UPLOAD_QUESTION_REQUEST_FAILED,
  UPLOAD_QUESTION_REQUEST,
  UPDATE_CREATE_EXAM_STEPPER,
  RESET_CREATE_EXAM,
  RESET_CREATE_QUESTION,
  SET_EXAM_CODE,
  RESET_EXAM_CODE,
  SUBMIT_ANSWER_REQUEST,
  SUBMIT_ANSWER_REQUEST_SUCCESS,
  SUBMIT_ANSWER_REQUEST_FAILED,
  UPDATE_CANDIDATE_STATUS_SUCCESS,
  UPDATE_CANDIDATE_STATUS_FAILED,
  UPDATE_CANDIDATE_STATUS_REQUEST,
  DISPLAY_CANDIDATE_ASSESSMENT_SCORE_SUCCESS,
  DISPLAY_CANDIDATE_ASSESSMENT_SCORE_ERROR,
  DISPLAY_CANDIDATE_ASSESSMENT_SCORE_REQUEST,
  DISPLAY_CANDIDATE_BY_STATUS_SUCCESS,
  DISPLAY_CANDIDATE_BY_STATUS_ERROR,
  STOP_LOADING,
  CREATE_CANDIDATE_TRACKER_DETAIL_REQUEST_SUCCESS,
  CREATE_CANDIDATE_TRACKER_DETAIL_REQUEST_ERROR,
  CREATE_CANDIDATE_TRACKER_DETAIL_REQUEST,
  DISPLAY_CANDIDATE_TRACKER_DETAIL_PAGE_SUCCESS,
  DISPLAY_CANDIDATE_TRACKER_DETAIL_PAGE_ERROR,
} from "./Types";
import axios from "axios";
import { API_URL } from "./serverConnection";
import { AppDispatch } from "../redux/store";
import Swal from "sweetalert2";
//To fetch list of assessment
export const displayAllAssessments =
  () => async (dispatch: AppDispatch, getState: RootState) => {
    try {
      const { token } = getState().user;
      const result = await axios.get(`${API_URL}/exam`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({
        type: DISPLAY_ASSESSMENT,
        payload: result?.data,
      });
    } catch (err) {
      dispatch({
        type: DISPLAY_ASSESSMENT_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
    }
  };

//To fetch list of candidates by completed status
export const displayAllCandidatesByCompletedStatus =
  () => async (dispatch: AppDispatch, getState: RootState) => {
    try {
      const { token } = getState().user;
      const result = await axios.get(
        `${API_URL}/exam/candidate/completed-status`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch({
        type: DISPLAY_CANDIDATE_BY_STATUS_SUCCESS,
        payload: result?.data,
      });
    } catch (err) {
      dispatch({
        type: DISPLAY_CANDIDATE_BY_STATUS_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
    }
  };

//To fetch list of assessment
export const displayAllOrganizationAssessments =
  (orgId: number) => async (dispatch: AppDispatch, getState: RootState) => {
    try {
      const { token } = getState().user;
      const result = await axios.get(`${API_URL}/exam/${orgId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({
        type: DISPLAY_ASSESSMENT_ORG,
        payload: result?.data,
      });
    } catch (err) {
      dispatch({
        type: DISPLAY_ASSESSMENT_ORG_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
    }
  };
//To fetch list of assessment by exam id
export const displayAllAssessmentByExamId =
  (examId: number) => async (dispatch: AppDispatch, getState: RootState) => {
    try {
      const { token } = getState().user;
      const result = await axios.get(`${API_URL}/exam/exam-details/${examId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({
        type: DISPLAY_ASSESSMENT_EXAM,
        payload: result?.data,
      });
    } catch (err) {
      dispatch({
        type: DISPLAY_ASSESSMENT_EXAM_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
    }
  };
export const updateCandidateStatus =
  (
    candidateExamId: number,
    status: string,
    behaviourScore: number,
    feedback?: string
  ) =>
  async (dispatch: AppDispatch, getState: RootState) => {
    try {
      dispatch({ type: UPDATE_CANDIDATE_STATUS_REQUEST });
      const { token } = getState().user;
      const result = await axios.put(
        `${API_URL}/exam/update-can-exam`,
        {
          candidateExamId,
          status,
          behaviourScore,
          feedback,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      dispatch({
        type: UPDATE_CANDIDATE_STATUS_SUCCESS,
        payload: result?.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_CANDIDATE_STATUS_FAILED,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
      Swal.fire({
        icon: "error",
        title: "Error",
        text: (err as any).response?.data?.message,
      });
    }
  };
export const getExamByCode =
  (code: string) => async (dispatch: AppDispatch, getState: RootState) => {
    try {
      const { token } = getState().user;
      const result = await axios.get(`${API_URL}/exam/exam-by-code/${code}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({
        type: DISPLAY_ASSESSMENT_EXAM,
        payload: result?.data,
      });
    } catch (err) {
      dispatch({
        type: DISPLAY_ASSESSMENT_EXAM_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
    }
  };

export const displayAllAssessmentByExamIdForAdmin =
  (examId: number) => async (dispatch: AppDispatch, getState: RootState) => {
    try {
      const { token } = getState().user;
      const result = await axios.get(
        `${API_URL}/exam/exam-admin-details/${examId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch({
        type: DISPLAY_ASSESSMENT_EXAM,
        payload: result?.data,
      });
    } catch (err) {
      dispatch({
        type: DISPLAY_ASSESSMENT_EXAM_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
    }
  };

//To post the candidate details(Device used, location, IP address, mouse location full screen mode)
export const createCandidateTrackerDetails =
  (
    // exam_candidate_id:number,
    // deviceUsed: string,
    // location: string,
    // webCamEnabled: boolean,
    // ipAddress: string,
    // mouseMovedOutside: boolean,
    // fullScreenMode: boolean

    exam_candidate_id: number,
    deviceUsed: string,
    latitude: string,
    longitude: string,
    webCamEnabled: boolean,
    ipAddress: string,
    mouseMovedOutside: boolean,
    countMouseMovedOutside: number,
    switchingTab: boolean,
    countSwitchingTab: number,
    fullScreenMode: boolean,
    timeOutsideScreen:any
  ) =>
  async (dispatch: AppDispatch, getState: RootState) => {
    try {
      dispatch({ type: CREATE_CANDIDATE_TRACKER_DETAIL_REQUEST });
      const { token } = getState().user;
      const result = await axios.post(
        `${API_URL}/exam/setCandidate_exam_details`,
        {
          exam_candidate_id,
          deviceUsed,
          latitude,
          longitude,
          webCamEnabled,
          ipAddress,
          mouseMovedOutside,
          countMouseMovedOutside,
          switchingTab,
          countSwitchingTab,
          fullScreenMode,
          timeOutsideScreen
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      dispatch({
        type: CREATE_CANDIDATE_TRACKER_DETAIL_REQUEST_SUCCESS,
        payload: result?.data,
      });
    } catch (err) {
      dispatch({
        type: CREATE_CANDIDATE_TRACKER_DETAIL_REQUEST_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
    }
  };

//To get the candidate details(Device used, location, IP address, mouse location full screen mode)
export const displayCandidateTrackerDetails =
  (exam_candidate_id: any) =>
  async (dispatch: AppDispatch, getState: RootState) => {
    // console.log("exam_candidate_id from action: ", exam_candidate_id);
    try {
      const { token } = getState().user;

      const result = await axios.get(
        `${API_URL}/exam/getCandidate_exam_details/${exam_candidate_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("result from assessment action", result.data.candidateExam);
      dispatch({
        type: DISPLAY_CANDIDATE_TRACKER_DETAIL_PAGE_SUCCESS,
        payload: result?.data,
      });
    } catch (err) {
      dispatch({
        type: DISPLAY_CANDIDATE_TRACKER_DETAIL_PAGE_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
    }
  };

//To display assessment score
export const displayAssessmentSore =
  (id: any) => async (dispatch: AppDispatch, getState: RootState) => {
    try {
      dispatch({ type: DISPLAY_CANDIDATE_ASSESSMENT_SCORE_REQUEST });
      const { token } = getState().user;
      const result = await axios.get(
        `${API_URL}/exam/candidate_exam_details/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch({
        type: DISPLAY_CANDIDATE_ASSESSMENT_SCORE_SUCCESS,
        payload: result?.data,
      });
    } catch (err) {
      dispatch({
        type: DISPLAY_CANDIDATE_ASSESSMENT_SCORE_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
    }
  };

//To add assessment details
export const addAssessment =
  (
    name: string,
    type: string,
    technology: string,
    category: string,
    requiredTime: any,
    difficultyLevel: string,
    orgnizationId: any
  ) =>
  async (dispatch: AppDispatch, getState: RootState) => {
    try {
      dispatch({ type: START_LOADING });
      const { token } = getState().user;
      const result = await axios.post(
        `${API_URL}/exam`,
        {
          name,
          type,
          technology,
          category,
          requiredTime,
          difficultyLevel,
          orgnizationId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch({
        type: CREATE_ASSESSMENT,
        payload: result?.data,
      });
      Swal.fire({
        icon: "success",
        title: "Success",
        timer: 1500,
      });
    } catch (err) {
      dispatch({
        type: CREATE_ASSESSMENT_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
      Swal.fire({
        icon: "error",
        title: "Error",
        text: (err as any).response?.data?.message,
      });
    }
  };

//To add single questions
export const addQuestions =
  (body: any) => async (dispatch: AppDispatch, getState: RootState) => {
    try {
      dispatch({ type: START_LOADING });
      const { token } = getState().user;

      const result = await axios.post(
        `${API_URL}/exam/question/create-single-question`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch({
        type: CREATE_ASSESSMENT_QUESTION,
        payload: result?.data,
      });
    } catch (err) {
      dispatch({
        type: CREATE_ASSESSMENT_QUESTION_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
      Swal.fire({
        icon: "error",
        title: "Error",
        text: (err as any).response?.data?.message,
      });
    }
  };

export const updateQuestion =
  (body: any) => async (dispatch: AppDispatch, getState: RootState) => {
    try {
      dispatch({ type: UPDATE_QUESTION_REQUEST });
      const { token } = getState().user;

      const result = await axios.put(`${API_URL}/exam/update-question`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({
        type: UPDATE_QUESTION_REQUEST_SUCCESS,
        payload: result?.data,
      });

      Swal.fire({
        icon: "success",
        title: "Success",
        timer: 1500,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_QUESTION_REQUEST_FAILED,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
      Swal.fire({
        icon: "error",
        title: "Error",
        text: (err as any).response?.data?.message,
      });
    }
  };

export const deleteQuestion =
  (id: any, index: number) =>
  async (dispatch: AppDispatch, getState: RootState) => {
    try {
      dispatch({ type: DELETE_QUESTION_STARTED });
      const { token } = getState().user;
      const result = await axios.delete(
        `${API_URL}/exam/delete-question/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch(deleteQuestionLocally(index, result?.data));
    } catch (err) {
      dispatch({
        type: DELETE_QUESTION_FAILED,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
      Swal.fire({
        icon: "error",
        title: "Error",
        text: (err as any).response?.data?.message,
      });
    }
  };

export const addLocalQuestion = (index: number = 0) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: ADD_LOCAL_QUESTION_ASSESSMENT,
      payload: index,
    });
  };
};

export const uploadQuestionFile = (examId: any, file: any) => {
  return async (dispatch: AppDispatch, getState: RootState) => {
    try {
      dispatch({ type: START_LOADING });
      dispatch({
        type: UPLOAD_QUESTION_REQUEST,
      });
      const formData = new FormData();
      formData.append("examId", examId);
      formData.append("file", file);
      const { token } = getState().user;
      const result = await axios.post(
        `${API_URL}/exam/upload-exam-question-filE`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch({
        type: UPLOAD_QUESTION_REQUEST_SUCCESS,
        payload: result?.data,
      });
      dispatch({ type: STOP_LOADING });
      Swal.fire({
        icon: "success",
        title: "Questions uploaded successfully",
        timer: 1500,
      });
    } catch (err) {
      dispatch({
        type: UPLOAD_QUESTION_REQUEST_FAILED,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
      Swal.fire({
        icon: "error",
        title: "Error",
        text: (err as any).response?.data?.message,
      });
    }
  };
};

export const submitAnswer =
  (body: any) => async (dispatch: AppDispatch, getState: RootState) => {
    try {
      dispatch({ type: SUBMIT_ANSWER_REQUEST });
      const { token } = getState().user;

      const result = await axios.post(
        `${API_URL}/exam/answer/submit-answer`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch({
        type: SUBMIT_ANSWER_REQUEST_SUCCESS,
        payload: { answer: result?.data.answer, body },
      });
    } catch (err) {
      dispatch({
        type: SUBMIT_ANSWER_REQUEST_FAILED,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
      Swal.fire({
        icon: "error",
        title: "Error",
        text: (err as any).response?.data?.message,
      });
    }
  };

export const updateQuestionIndex = (index: number = 0) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: UPDATE_QUESTIOn_INDEX,
      payload: index,
    });
  };
};

export const resetCreateExam = () => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: RESET_CREATE_EXAM,
    });
  };
};

export const resetCreatedQuestion = () => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: RESET_CREATE_QUESTION,
    });
  };
};

export const deleteQuestionLocally = (
  index: number = 0,
  data: any = undefined
) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: DELETE_QUESTION_COMPLETED,
      payload: { data, index },
    });
  };
};

export const updateCreateExamStepper = (value: number = 1) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: UPDATE_CREATE_EXAM_STEPPER,
      payload: value,
    });
  };
};

export const setExamCode = (code: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: SET_EXAM_CODE,
      payload: code,
    });
  };
};

export const resetExamCode = () => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: RESET_EXAM_CODE,
    });
  };
};
