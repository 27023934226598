export const defaultCode: { [key: string]: string } = {
    java: `class UserInputDemo{
        public static void main(String[] args){
            //System.in is a standard input stream  
            Scanner sc= new Scanner(System.in);   
            System.out.print("Enter first number- ");  
            int a= sc.nextInt();  
            System.out.print("Enter second number- ");  
            int b= sc.nextInt();  
            System.out.print("Enter third number- ");  
            int c= sc.nextInt();  
            int d=a+b+c;  
            System.out.println("Total= " +d);  
        }
    
        public void method(){
            //enter code here
        }
    }`,
    kotlin:`import java.util.Scanner
    fun main(){
        val scanner = Scanner(System.in)
        print("Enter first number: ")
        val a = scanner.nextInt()
        print("Enter second number: ")
        val b = scanner.nextInt()
        print("Enter third number: ")
        val c = scanner.nextInt()
        val d = a + b + c
        println("Total = $d")
    }
    class UserInputDemo {
        fun method() {
            // Enter code here
        }
    }`,
    python: `# input
    num1 = int(input())
    num2 = int(input())
    #enter your code here
    # printing the sum in integer
    print(num1 + num2)
    `,
    javascript: `// Online Javascript Editor for free
    // Write, Edit and Run your Javascript code using JS Online Compiler
    // console.log("Welcome to Braven Tool!");
    'use strict';
    process.stdin.resume();
    process.stdin.setEncoding('utf-8');
    let inputString = '';
    let currentLine = 0;
    process.stdin.on('data', function(inputStdin) {
        inputString += inputStdin;
    });
    process.stdin.on('end', function() {
        inputString = inputString.split('\\n');
        main();
    });
    
    function readLine(){
        return inputString[currentLine++];
    }
    /*
    * Complete the 'plusMinus' function below.
    *
    * The function accepts INTEGER_ARRAY arr as parameter.
    */
    function plusMinus(n, arr){
        // Write your code here
        console.log(n, arr)
    }
    function main() {
        const n = parseInt(readLine().trim(), 10);
        const arr = readLine().replace(/s+$/g, '').split(' ').map(arrTemp => parseInt(arrTemp, 10));
        plusMinus(n, arr);
    }`,
    cpp: `#include <iostream>
    using namespace std;
    int main() {
        int x, y;
        int sum;
        cout << "Type a number: ";
        cin >> x;
        cout << "Type another number: ";
        cin >> y;
        sum = x + y;
        cout << "Sum is: " << sum;
        return 0;
    }`,
    csharp:`using System;
    class Program
    {
        static void Main()
        {
            int x, y;
            int sum;
            Console.Write("Type a number: ");
            x = int.Parse(Console.ReadLine());
            Console.Write("Type another number: ");
            y = int.Parse(Console.ReadLine());
            sum = x + y;
            Console.WriteLine("Sum is: " + sum);
        }
    }`,
    c: `#include <stdio.h>
    int main() {
      // Create an integer variable that will store the number we get from the user
      int myNum;
      // Ask the user to type a number
      printf("Type a number and press enter: ");
      // Get and save the number the user types
      scanf("%d", &myNum);
      // Print the number the user typed
      printf("Your number is: %d", myNum);
      return 0;
    }`,
    typescript: `function plusMinus(n: number, arr: number[]): void {
        // Write your code here
        console.log(n, arr);
    }
      
    function main() {
        const n: number = parseInt(prompt("Enter the value of n").trim(), 10);
        const arr: number[] = prompt("Enter the array elements (space-separated)")
        .split(' ').map(arrTemp => parseInt(arrTemp, 10));
        plusMinus(n, arr);
    }
    
    main();`,
    };
    