import React, { useState } from "react";
import { HiOutlineBars3 } from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { BsPower } from "react-icons/bs";
import { logout } from "../../actions/user";
import "./style.css";
import { useAppDispatch } from "../../hooks/app";
import PostAddIcon from "@mui/icons-material/PostAdd";

export const Navbar: React.FC = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  //Method to handle logout operation
  const handleLogout = () => {
    //Calls the logout action creator with the dispatch for userReducer
    navigate("/");
    dispatch(logout());
  };

  const menuOptions = [
    {
      text: "Home",
      icon: <HomeIcon />,
    },
    {
      text: "Create Assessment",
      icon: <PostAddIcon />,
    },
    {
      text: "Assessments",
      icon: <AssessmentIcon />,
    },

    {
      text: "Upgrade",
      icon: <PhoneRoundedIcon />,
    },
    {
      text: "Account",
      icon: <AccountCircleOutlinedIcon />,
    },
  ];
  return (
    <nav>
      <div className="nav-logo-container">
        <h2 className="hiring-logo">BravensTool</h2>
      </div>
      <div className="navbar-links-container">
        <Link to="/organization-dashboard">Home</Link>
        {/* <Link to="/admin-create-assessment">Create Assessments</Link> */}
        <Link to="/admin-test-questions">Assessment</Link>
        <Link to="/upgrade">Upgrade</Link>
        <Link to="" style={{ cursor: "pointer" }} onClick={handleLogout}>
          {/* <BsPower className="navbar-account-icon" /> */}Logout
        </Link>
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item, index) => (
              <ListItem key={item.text} disablePadding>
                {index === 0 ? (
                  <ListItemButton>
                    <a
                      href="organization-dashboard"
                      style={{
                        display: "flex",
                        textDecoration: "none",
                        color: "black",
                      }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </a>
                  </ListItemButton>
                ) : null}
                {index === 1 ? (
                  <ListItemButton>
                    <a
                      href="admin-create-assessment"
                      style={{
                        display: "flex",
                        textDecoration: "none",
                        color: "black",
                      }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </a>
                  </ListItemButton>
                ) : null}
                {index === 2 ? (
                  <ListItemButton>
                    <a
                      href="admin-test-questions"
                      style={{
                        display: "flex",
                        textDecoration: "none",
                        color: "black",
                      }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </a>
                  </ListItemButton>
                ) : null}
                {index === 3 ? (
                  <ListItemButton>
                    <a
                      href="upgrade"
                      style={{
                        display: "flex",
                        textDecoration: "none",
                        color: "black",
                      }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </a>
                  </ListItemButton>
                ) : null}
                {index === 4 ? (
                  <ListItemButton>
                    <a
                      className="logout-navbar-option"
                      onClick={handleLogout}
                      style={{ display: "flex", textDecoration: "none" }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={"Logout"} />
                    </a>
                  </ListItemButton>
                ) : null}
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </nav>
  );
};
