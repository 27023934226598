import "./App.css";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import { store } from "./redux/store";
import Router from "./routers";
import StepProvider from "./context/MultiStepContext";

// testing
function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <StepProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </StepProvider>
      </Provider>
    </div>
  );
}

export default App;
