import { RootState } from "./../redux/store/index";
import {
    GET_IMAGE_STRING_ARRAY,
    GET_IMAGE_STRING_ARRAY_ERROR,
    SEND_IMAGE_STRING_ARRAY,
    SEND_IMAGE_STRING_ARRAY_ERROR
} from "./Types";
import axios from "axios";
import { AppDispatch } from "../redux/store";
import { API_URL } from "./serverConnection";

export const sendImageArray =
    (imageArray?: string[],
        capturedVideos?: string[],
        examCandidateId?: number) => async (dispatch: AppDispatch, getState: RootState) => {
            try {
                let imageString = "";
                let imageAccumulator = [];
                let videoString = "";
                let videoAccumulator = [];
                if (imageArray) {
                    for (var i = 0; i < imageArray?.length; ++i) {
                        imageAccumulator.push(imageArray[i].substring(23));
                    }
                }
                if (capturedVideos) {
                    for (var i = 0; i < capturedVideos?.length; ++i) {
                        videoAccumulator.push(capturedVideos[i].substring(23));
                    }
                }
                imageString = imageAccumulator.join(" ");
                videoString = videoAccumulator.join(" ");

                const { token } = getState().user;
                const result = await axios.post(`${API_URL}/imagearray`, {
                    imageString, videoString, examCandidateId
                });
                dispatch({
                    type: SEND_IMAGE_STRING_ARRAY,
                    payload: result?.data,
                });
            } catch (err) {
                dispatch({
                    type: SEND_IMAGE_STRING_ARRAY_ERROR,
                    payload:
                        (err as any)?.response?.data?.message ||
                        "Unable to save record at the moment. Please Try Again.",
                });
            }
        };

export const getImageArray =
    (id: number) => async (dispatch: AppDispatch, getState: RootState) => {
        try {
            const token: any = localStorage.getItem("token");
            const result = await axios.get(`${API_URL}/imagearray/byid/` + id, {
                headers: { Authorization: `Bearer ${token}` },
            });
            dispatch({
                type: GET_IMAGE_STRING_ARRAY,
                payload: result?.data,
            });
        } catch (err) {
            dispatch({
                type: GET_IMAGE_STRING_ARRAY_ERROR,
                payload:
                    (err as any)?.response?.data?.message ||
                    "Unable to fetch records at the moment. Please Try Again.",
            });
        }
    };