import React from "react";
import "./style.css";
import { OutputDetails } from "./OutputDetails";

interface Props {
  outputDetails?: any;
}
export const OutputWindow: React.FC<Props> = ({ outputDetails }) => {
  const getOutput = () => {
    let statusId = outputDetails?.status?.id;
    let output;
    output = window.atob(outputDetails.stdout);
    // console.log(output)
    if (statusId === 6) {
      // compilation error
      return (
        <pre className="code-editor-output-window">
          {window.atob(outputDetails.stdout)}
        </pre>
      );
    } else if (statusId === 3) {
      return (
        <pre className="code-editor-output-window">
          {window.atob(outputDetails.stdout) !== null
            ? `${window.atob(outputDetails.stdout)}`
            : null}
        </pre>
      );
    } else if (statusId === 5) {
      return (
        <pre className="code-editor-output-window-error">
          {`Time Limit Exceeded`}
        </pre>
      );
    } else {
      return (
        <pre className="code-editor-output-window-error">
          {atob(outputDetails?.stderr)}
        </pre>
      );
    }
  };
  return (
    <>
      <div className="code-editor-output-window">
        {outputDetails ? <>{getOutput()}</> : null}
      </div>
    </>
  );
};
