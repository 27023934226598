import React, { useEffect, useState } from "react";
import { HiOutlineBars3 } from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Groups2Icon from "@mui/icons-material/Groups2";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { BsPower } from "react-icons/bs";
import { logout } from "../../actions/user";
import "./style.css";
import { Modal } from "../../Common/Modal/Modal";
import { useAppDispatch } from "../../hooks/app";
import { ORGANIZATION_NAME } from "../Constants/constants";
import jwt_decode from "jwt-decode";
import { CreateCandidate } from "./AddCandidate";
import HomeIcon from "@mui/icons-material/Home";
import PostAddIcon from "@mui/icons-material/PostAdd";

export const Navbar: React.FC = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [displayName, setDisplayName] = useState("");
  useEffect(() => {
    const token: any = localStorage.getItem("token");
    let decoded: any = jwt_decode(token);
    setDisplayName(decoded?.organization?.name);
  }, []);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  //Method to handle logout operation
  const handleLogout = () => {
    navigate("/");
    //Calls the logout action creator with the dispatch for userReducer
    dispatch(logout());
  };
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const menuOptions = [
    {
      text: "Home",
      icon: <HomeIcon />,
    },
    {
      text: "Candidates",
      icon: <Groups2Icon />,
    },
    {
      text: "Assessments",
      icon: <AssessmentIcon />,
    },
    {
      text: "Add Candidate",
      icon: <PostAddIcon />,
    },
    {
      text: "Account",
      icon: <AccountCircleOutlinedIcon />,
    },
  ];
  return (
    <nav>
      {/* <div className="recruiter-nav-parent" > */}
      <div className="nav-logo-container">
        <h2 className="hiring-logo">
          {displayName ? displayName : ORGANIZATION_NAME}
        </h2>
      </div>
      <div className="navbar-links-container">
        <Link to="/recruiter-dashboard">Home</Link>
        <Link to="/candidates">Reports</Link>
        <Link to="/test-questions">Assessment</Link>

        <button className="add-candidates-button" onClick={handleModal}>
          Add Candidates
        </button>
        <a style={{ cursor: "pointer" }} onClick={handleLogout}>
          {/* <BsPower className="navbar-account-icon" /> */}Logout
        </a>
      </div>
      {/* </div> */}
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item, index) => (
              <ListItem key={item.text} disablePadding>
                {index === 0 ? (
                  <ListItemButton>
                    <a
                      href="recruiter-dashboard"
                      style={{
                        display: "flex",
                        textDecoration: "none",
                        color: "black",
                      }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </a>
                  </ListItemButton>
                ) : null}
                {index === 1 ? (
                  <ListItemButton>
                    <a
                      href="candidates"
                      style={{
                        display: "flex",
                        textDecoration: "none",
                        color: "black",
                      }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </a>
                  </ListItemButton>
                ) : null}
                {index === 2 ? (
                  <ListItemButton>
                    <a
                      href="test-questions"
                      style={{
                        display: "flex",
                        textDecoration: "none",
                        color: "black",
                      }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </a>
                  </ListItemButton>
                ) : null}
                {index === 3 ? (
                  <ListItemButton>
                    <a
                      onClick={handleModal}
                      style={{
                        display: "flex",
                        textDecoration: "none",
                        color: "black",
                      }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </a>
                  </ListItemButton>
                ) : null}
                {index === 4 ? (
                  <ListItemButton>
                    <a
                      className="logout-navbar-option"
                      onClick={handleLogout}
                      style={{ display: "flex", textDecoration: "none" }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={"Logout"} />
                    </a>
                  </ListItemButton>
                ) : null}
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
      {showModal ? (
        <Modal
          children={<CreateCandidate onSubmit={() => setShowModal(false)} />}
          showModalHeader={true}
          modalHeader={"Add New Candidate"}
          isFlexible={true}
          topRightCloseButtonID={"x-  "}
          showModal={true}
          showBackButton={true}
          showBBPSLogo={true}
          handleBackClick={handleCloseModal}
        ></Modal>
      ) : null}
    </nav>
  );
};
