//Sachin Dhalani - [14/4/2023]
import {
  ADD_CANDIDATES,
  ADD_CANDIDATES_ERROR,
  CANDIDATE_INVITE,
  CANDIDATE_INVITE_ERROR,
  CANDIDATE_PREVIOUS_ASSESSMENT_ERROR,
  CANDIDATE_PREVIOUS_ASSESSMENT_SUCCESS,
  DISPLAY_CANDIDATE_SELECTED_OPTION,
  DISPLAY_CANDIDATE_SELECTED_OPTION_ERROR,
  FETCH_CANDIDATES,
  FETCH_CANDIDATES_ERROR,
  FETCH_CANDIDATE_FROM_ORGANIZATION,
  FETCH_CANDIDATE_FROM_ORGANIZATION_ERROR,
} from "../actions/Types";

//State type for defining the state of the reducer
interface Actions {
  payload: any;
  type: string;
}
//CandidateInterface to define the State type for the state of the reducer
interface CandidateInterface {
  candidateDetails: any;
  candidateDetailsError: string;
  candidateDetailsByOrganization:any
  candidateDetailsByOrganizationError:string
  addCandidate: any;
  addCandidateError: string;
  candidateInvitation: any;
  candidateInvitationError: string;
  candidatePreviousAssessment: any;
  candidatePreviousAssessmentError: string;
  candidateQuestionAnswer:any
  candidateQuestionAnswerError:string

}
//State type for defining the state of the reducer
export type State = CandidateInterface;
//Initial state of the reducer of type State
export const initialState: State = {
  candidateDetails: [],
  candidateDetailsError: "",
  candidateDetailsByOrganization:[],
  candidateDetailsByOrganizationError:"",
  addCandidate: [],
  addCandidateError: "",
  candidateInvitation: [],
  candidateInvitationError: "",
  candidatePreviousAssessment: [],
  candidatePreviousAssessmentError: "",
  candidateQuestionAnswer:[],
  candidateQuestionAnswerError:""
};
export const CandidateReducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case FETCH_CANDIDATES:
      return {
        ...state,
        candidateDetails: action.payload,
        candidateDetailsError: "",
      };
    case FETCH_CANDIDATES_ERROR:
      return {
        ...state,
        candidateDetails: [],
        candidateDetailsError: action.payload,
      };
    case ADD_CANDIDATES:
      return {
        ...state,
        addCandidate: action.payload,
        addCandidateError: "",
      };
    case ADD_CANDIDATES_ERROR:
      return {
        ...state,
        addCandidate: [],
        addCandidateError: action.payload,
      };
    case CANDIDATE_INVITE:
      return {
        ...state,
        candidateInvitation: action.payload,
        candidateInvitationError: "",
      };
    case CANDIDATE_INVITE_ERROR:
      return {
        ...state,
        candidateInvitation: "",
        candidateInvitationError: action.payload,
      };
    case CANDIDATE_PREVIOUS_ASSESSMENT_SUCCESS:
      return {
        ...state,
        candidatePreviousAssessment: action.payload,
        candidatePreviousAssessmentError: "",
      };
    case CANDIDATE_PREVIOUS_ASSESSMENT_ERROR:
      return {
        ...state,
        candidatePreviousAssessment: "",
        candidatePreviousAssessmentError: action.payload,
      };
      case FETCH_CANDIDATE_FROM_ORGANIZATION:
        return{
          ...state,
          candidateDetailsByOrganization:action.payload,
          candidateDetailsByOrganizationError:""   
        }
        case FETCH_CANDIDATE_FROM_ORGANIZATION_ERROR:
        return{
          ...state,
          candidateDetailsByOrganization:"",
          candidateDetailsByOrganizationError:action.payload   
        }
        case DISPLAY_CANDIDATE_SELECTED_OPTION:
        return{
          ...state,
          candidateQuestionAnswer:action.payload ,
          candidateQuestionAnswerError:""  
        }
        case DISPLAY_CANDIDATE_SELECTED_OPTION_ERROR:
          return{
            ...state,
            candidateQuestionAnswer:"",
            candidateQuestionAnswerError:action.payload   
          }
    //return state as it is if action is not of any of the aforementioned types
    default:
      return state;
  }
};
