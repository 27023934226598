import { updateOrganization } from "./../actions/organization";
//Sachin Dhalani - [13/4/2023]
import {
  ADD_ORGANIZATION,
  ADD_ORGANIZATION_ERROR,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_ERROR,
  DISPLAY_ORGANIZATION,
  DISPLAY_ORGANIZATION_ERROR,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_ERROR,
} from "../actions/Types";

//OrganizationInterface to define the State type for the state of the reducer
interface OrganizationInterface {
  organizationDetails: any;
  organizationDetailsError: string;
  addOrganization: any;
  addOrganizationError: string;
  deleteOrganization: any;
  deleteOrganizationError: string;
  updateOrganization: any;
  updateOrganizationError: string;
}
//State type for defining the state of the reducer
interface Actions {
  payload: any;
  type: string;
}
export type State = OrganizationInterface;
//Initial state of the reducer of type State
export const initialState: State = {
  organizationDetails: undefined,
  organizationDetailsError: "",
  addOrganization: undefined,
  addOrganizationError: "",
  deleteOrganization: undefined,
  deleteOrganizationError: "",
  updateOrganization: undefined,
  updateOrganizationError: "",
};
export const OrganizationReducer = (
  state: State = initialState,
  action: Actions
) => {
  switch (action.type) {
    case DISPLAY_ORGANIZATION:
      return {
        ...state,
        organizationDetails: action.payload,
        organizationDetailsError: "",
      };

    case DISPLAY_ORGANIZATION_ERROR:
      return {
        ...state,
        organizationDetails: [],
        organizationDetailsError: action.payload,
      };
    case ADD_ORGANIZATION:
      return {
        ...state,
        addOrganization: action.payload,
        addOrganizationError: "",
      };
    case ADD_ORGANIZATION_ERROR:
      return {
        ...state,
        addOrganization: [],
        addOrganizationError: action.payload,
      };

    case DELETE_ORGANIZATION:
      return {
        ...state,
        deleteOrganization: action.payload,
        deleteOrganizationError: "",
      };
    case DELETE_ORGANIZATION_ERROR:
      return {
        ...state,
        deleteOrganization: [],
        deleteOrganizationError: action.payload,
      };
    case UPDATE_ORGANIZATION:
      return {
        ...state,
        updateOrganization: action.payload,
        updateOrganizationError: "",
      };

    case UPDATE_ORGANIZATION_ERROR:
      return {
        ...state,
        updateOrganization: [],
        updateOrganizationError: action.payload,
      };
    //return state as it is if action is not of any of the aforementioned types
    default:
      return state;
  }
};
