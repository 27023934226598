//Sachin Dhalani - [12/4/2023] - Index page for organization dashboard
import React, { useEffect } from "react";
import { Home } from "./Home";
import { displayOrganization } from "../../actions/organization";
import { Navbar } from "./Navbar";
import { useAppDispatch } from "../../hooks/app";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
const OrganizationDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const organizations = useSelector(
    (state: RootState) => state.organization.organizationDetails
  );
  useEffect(() => {
    dispatch(displayOrganization());
  }, []);
  return (
    <div>
      <Navbar />
      <Home organizations={organizations} />
    </div>
  );
};

export default OrganizationDashboard;
