//Sachin Dhalani - [9/3/2023] - about us page with sample text and button to watch a video
import React from "react";
import AboutBackground from "../../Assets/about-background.png";
import { Button } from "../../Common/Button/Button";
import {
  ABOUT_HEADING,
  LEARN_MORE,
  SAMPLE_TEXT,
  SAMPLE_TEXT1,
  WATCH_VIDEO,
} from "../Constants/constants";
import "./style.css";
export const About: React.FC = () => {
  const handleLearnMore = () => {};
  const handleWatchVideo = () => {};
  return (
    <div className="about-section-container">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="about-background" />
      </div>
      <div className="about-section-image-container"></div>
      <div className="about-section-text-container">
        <p className="primary-subheading">About</p>
        <h1 className="primary-heading">{ABOUT_HEADING}</h1>
        <p className="primary-text">
          Analytics and data is making hiring super easy for you resulting in
          reduced management efforts & attitude based hiring
        </p>
        <p className="primary-text">{SAMPLE_TEXT1}</p>
        <div className="about-buttons-container">
          <Button
            className="secondary-buttons"
            value={LEARN_MORE}
            handleClick={handleLearnMore}
          />
          <Button
            className="watch-video-button"
            value={WATCH_VIDEO}
            handleClick={handleWatchVideo}
          />
        </div>
      </div>
    </div>
  );
};
