import SorryImage from "../../Assets/sorry.jpg";
import ThumbImage from "../../Assets/animated-success.gif";
import "./style.css";
import { Button } from "../../Common/Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
export const TestError: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const status = location.state;
  const backToDashboard = () => {
    navigate("/");
  };
  return (
    <>
      <div className="test-withdrawn">
        {status == "ABORTED" ? (
          <div className="status-images">
            <img src={SorryImage} />
            <h1>your test is {status}</h1>
          </div>
        ) : status == "EXPIRED" ? (
          <div className="status-images">
            <img src={SorryImage} />
            <h1>Sorry your test is Expired</h1>
          </div>
        ) : status == "Not Started" ? (
          <div className="status-images">
            <img src={SorryImage} />
            <h1>Sorry your test is not yet started by the admin</h1>
          </div>
        ) : status == "COMPLETED" ? (
          <div className="status-images">
            <img src={ThumbImage} />
            <p className="test-error-message">
              {" "}
              Your Test is already Completed
            </p>
          </div>
        ) : null}
      </div>
      <center>
        <Button
          className="go-back-btn"
          value="Go Back"
          handleClick={backToDashboard}
        />
      </center>
    </>
  );
};
