//Sachin Dhalani - [12/4/2023] - Index page for admin dashboard
import React, { useEffect } from "react";
import { Home } from "./Home";
import { displayAdmin, displayCandidate, displayCandidateByOrganization } from "../../actions/admin";
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../hooks/app";
import { RootState } from "../../redux/store";
import { AdminNavbar } from "./Navbar";

export const AdminDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const admins = useSelector((state: RootState) => state.admin.adminDetails);
  const candidates = useAppSelector(
    (state: RootState) => state.admin.adminDetails
    );
    // console.log('candidates from admin dashboard: ', candidates);
  const token: any = localStorage.getItem("token");
  let decoded: any = jwt_decode(token);
  const displayAdmins = async () => {
    await dispatch(displayAdmin(decoded?.organization?.id));
  };
  const user = useSelector((state: RootState) => state.user.userDetails);
  const displayAdminsByOrganization = async () => {
    await dispatch(displayCandidateByOrganization(user?.orgnizationId));
  };
  const OrganizationCandidate = useSelector((state: RootState)=>state.candidate?.candidateDetailsByOrganization)
  // console.log('OrganizationCandidate: ', OrganizationCandidate);

  const candidatesScore = useSelector(
    (state: RootState) => state.assessment.displayCandidateCompletedStatus
    );
    console.log('candidatesScore: ', candidatesScore);

  useEffect(() => {
    displayAdmins();
    displayAdminsByOrganization();
  }, []);
  return (
    <div>
      <AdminNavbar />
      <Home 
      admins={admins}
      candidates = {candidates}
      OrganizationCandidate = {OrganizationCandidate}
      />
    </div>
  );
};
