//Sachin Dhalani - [31/3/2023]
import { START_LOADING, STOP_LOADING } from "../actions/Types";

//UserInterface to define the State type for the state of the reducer

interface AppInterface {
  loading: boolean;
}

interface Actions {
  payload: any;
  type: string;
  token: string;
}
//State type for defining the state of the reducer
export type State = AppInterface;

//Initial state of the reducer of type State
export const initialState: State = {
  loading: false,
};
export const AppReducers = (state: State = initialState, action: Actions) => {
  //switch between action.type
  switch (action.type) {
    //if action is of type SIGN_IN or SIGN_UP return the state by setting token to the payload
    case START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
