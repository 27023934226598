import React from "react";
import Select from "react-select";
import "./style.css"
import { languageOptions } from "../../constants/languageOptions";

interface Props {
  onSelectChange?: any;
}
export const LanguagesDropdown: React.FC<Props> = ({ onSelectChange }) => {
  return (
    <Select
      placeholder={`Filter By Category`}
      options={languageOptions}
      // styles={customStyles}
      defaultValue={languageOptions[0]}
      onChange={(selectedOption) => {
        onSelectChange(selectedOption);
      }}
      className="language-drop-down"
    />
  );
};
