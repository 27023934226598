import React, { FC } from 'react'; 


interface SettingsProps {
    title: string;
  }
const SettingsScreen: FC<SettingsProps> = (props) => {
    return (
      <>
        <h1>Settings Page</h1>
        <h2>Sub Title</h2>
      </>
    );
  };
  
  export default SettingsScreen;