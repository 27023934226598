//Sachin Dhalani - [13/4/2023] - Action for organization
import axios from "axios";
import { API_URL } from "./serverConnection";
import {
  ADD_ORGANIZATION,
  ADD_ORGANIZATION_ERROR,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_ERROR,
  DISPLAY_ORGANIZATION,
  DISPLAY_ORGANIZATION_ERROR,
  START_LOADING,
  STOP_LOADING,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_ERROR,
} from "./Types";
import Swal from "sweetalert2";
import { AppDispatch } from "../redux/store";
//To fetch list of organization
export const displayOrganization = () => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const result = await axios.get(`${API_URL}/organization-group`);
    dispatch({
      type: DISPLAY_ORGANIZATION,
      payload: result?.data,
    });
    dispatch({ type: STOP_LOADING });
  } catch (err) {
    dispatch({
      type: DISPLAY_ORGANIZATION_ERROR,
      payload:
        (err as any)?.response?.data?.message ||
        "Unable to fetch records at the moment. Please Try Again.",
    });
  }
};
export const updateOrganization =
  (
    id: number,
    name: string,
    firstName: string,
    middleName:string,
    lastName: string,
    contact: string,
    selectedPlan: string,
    numberOfUsers: any,
    userName: string
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await axios.patch(`${API_URL}/organization-group`, {
        id,
        name,
        firstName,
        middleName,
        lastName,
        contact,
        selectedPlan,
        numberOfUsers,
        userName,
      });

      dispatch({
        type: UPDATE_ORGANIZATION,
        payload: result?.data,
      });

      Swal.fire({
        icon: "success",
        text: `Organization ${name} Updated Successfully`,
        timer: 2000,
      });
      dispatch(displayOrganization());
      dispatch({ type: STOP_LOADING });
    } catch (err) {
      dispatch({
        type: UPDATE_ORGANIZATION_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
      Swal.fire({
        icon: "error",
        title: "Error",
        text: (err as any).response?.data?.message,
      });
    }
  };

export const deleteOrganization =
  (id: number) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await axios.delete(
        `${API_URL}/organization-group/${id}`,
        {}
      );
      dispatch({
        type: DELETE_ORGANIZATION,

        payload: result?.data,
      }); 
      dispatch(displayOrganization());
      dispatch({ type: STOP_LOADING });
    } catch (err) {
      dispatch({
        type: DELETE_ORGANIZATION_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
      Swal.fire({
        icon: "error",
        title: "Error",
        text: (err as any).response?.data?.message,
      });
    }
  };
//To add new organization
export const addOrganization =
  (
    name: string,
    firstName: string,
    middleName:string,
    lastName: string,
    email: string,
    contact: string,
    contact_country_code: string,
    selectedPlan: string,
    numberOfUsers: string,
    uniqId: string,
    regNo: string
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await axios.post(`${API_URL}/organization-group`, {
        name,
        firstName,
        middleName,
        lastName,
        email,
        contact,
        contact_country_code,
        selectedPlan,
        numberOfUsers,
        uniqId,
        regNo,
      });
      dispatch({
        type: ADD_ORGANIZATION,
        payload: result?.data,
      });
      dispatch(displayOrganization());
      Swal.fire({
        icon: "success",
        text: `Organization ${name} Added Successfully`,
        timer: 2000,
      });
      dispatch({ type: STOP_LOADING });
    } catch (err) {
      dispatch({
        type: ADD_ORGANIZATION_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
      Swal.fire({
        icon: "error",
        title: "Error",
        text: (err as any).response?.data?.message,
      });
    }
  };
