//Sachin Dhalani - [9/3/2023] - banner page with sample text and button
//Chetan Patil - [13/3/2023] - banner page with exam animated image
import React from "react";
import BannerBackground from "../../Assets/home-banner-background.png";
import BannerImage from "../../Assets/background.png";
import FrontImage from "../../Assets/Test_Setup.png";
import { Button } from "../../Common/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import {
  GET_STARTED,
  HIRING_HEADING,
  HIRING_SUB_HEADING,
} from "../Constants/constants";
import "./style.css";
export const Home: React.FC = () => {
  const navigate = useNavigate();
  const handleGetStarted = (e: any) => {
    navigate("/sign-in");
  };
  return (
    <div className="home-container">
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="banner-background" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">{HIRING_HEADING}</h1>
          <p className="primary-text">
            Bravens provides you with the best tools to screen your candidates
            so you can make better, faster, and easier hiring decisions.
          </p>
          <Link to="/sign-in">
            <Button
              className="secondary-buttons"
              handleClick={handleGetStarted}
              value={GET_STARTED}
            />
          </Link>
        </div>

        <div className="home-image-section">
          <img className="home-image-banner" src={BannerImage} alt="banner" />
        </div>
      </div>
      <div className="front-image-section">
        <img className="front-image" src={FrontImage} alt="front" />
      </div>
    </div>
  );
};
