//Ajay Bagul - [12/5/2023] - Subscription formate
import React, { useEffect, useState } from "react";
import "./style.css";
import { BiDollar, BiEuro, BiRupee } from "react-icons/bi";
import { ImCheckmark, ImCross } from "react-icons/im";
import { Button } from "../../Common/Button/Button";
import { Navbar } from "../Navbar/Navbar";
import jwt_decode from "jwt-decode";
import { AdminNavbar } from "../AdminDashboard/Navbar";
import { useNavigate } from "react-router-dom";

export const Upgrade: React.FC = () => {
  const [rupeesPrice, setRupeesPrice] = useState<number>(500);
  const [premiumPrice, setPremiumPrice] = useState<number>(1000);
  const [planBasicPrice, setPlanBasicPrice] = useState<number>(500);
  const [planPremiumPrice, setPlanPremiumPrice] = useState<number>(1000);
  const [dollarPrice, setDollar] = useState<number>(82);
  const [euroPrice, setEuroPrice] = useState<number>(89);
  const navigate = useNavigate();
  const handleSubscribe = () => {
    navigate("/sign-in");
  };
  //icon currency converter handle..
  const handleChange = (currency: any) => {
    if (currency == "BasicRupees") {
      setPlanBasicPrice(rupeesPrice);
    } else if (currency == "BasicDollar") {
      setPlanBasicPrice(rupeesPrice / dollarPrice);
    } else if (currency == "BasicEuro") {
      setPlanBasicPrice(rupeesPrice / euroPrice);
    } else if (currency == "PremiumRupees") {
      setPlanPremiumPrice(premiumPrice);
    } else if (currency == "PremiumDollar") {
      setPlanPremiumPrice(premiumPrice / dollarPrice);
    } else {
      setPlanPremiumPrice(premiumPrice / euroPrice);
    }
  };
  return (
    <div>
      <Navbar />
      <div className="upgrade-container">
        {/* For free subscription */}
        <div className="pricing-colum">
          <h4 style={{ fontStyle: "italic" }}>Free</h4>
          <h4 style={{ marginTop: "10px", paddingBottom: "18px" }}>
            (Free Forever)
          </h4>
          <div className="content">
            <div className="column-content-1">
              <span className="subscription-plans">
                <ImCheckmark className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;allowed in free plan
              </span>
              <span>
                <ImCheckmark className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;allowed in free plan
              </span>

              <span>
                <ImCheckmark className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;allowed in free plan
              </span>

              <span>
                <ImCheckmark className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;allowed in free plan
              </span>

              <span style={{ marginLeft: "30px" }}>
                <ImCross className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;Not allowed in free plan
              </span>

              <span style={{ marginLeft: "30px" }}>
                <ImCross className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;Not allowed in free plan
              </span>

              <span style={{ marginLeft: "30px" }}>
                <ImCross className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;Not allowed in free plan
              </span>

              <span style={{ marginLeft: "30px" }}>
                <ImCross className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;Not allowed in free plan
              </span>
            </div>
          </div>
        </div>
        {/* Column of Basic subscription and pricing */}
        <div className="pricing-colum">
          <h4 style={{ fontStyle: "italic" }}>Basic</h4>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h3>{planBasicPrice?.toFixed(2)}</h3>
            <h6 style={{ marginTop: "9px" }}>/month</h6>
          </div>
          <div className="Price_Icons">
            <div className="icons">
              <p
                className="pricing-icons"
                id="1"
                onClick={() => handleChange("BasicRupees")}
              >
                <BiRupee />
              </p>
              <p
                className="pricing-icons"
                id="2"
                onClick={() => handleChange("BasicDollar")}
              >
                <BiDollar />
              </p>
              <p
                className="pricing-icons"
                id="3"
                onClick={() => handleChange("BasicEuro")}
              >
                <BiEuro />
              </p>
            </div>
          </div>
          <div className="content">
            <div className="column-content-2">
              <span className="subscription-plans">
                <ImCheckmark className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;allowed in basic plan
              </span>

              <span>
                <ImCheckmark className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;allowed in basic plan
              </span>

              <span>
                <ImCheckmark className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;allowed in basic plan
              </span>

              <span>
                <ImCheckmark className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;allowed in basic plan
              </span>

              <span>
                <ImCheckmark className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;allowed in basic plan
              </span>

              <span>
                <ImCheckmark className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;allowed in basic plan
              </span>

              <span style={{ marginLeft: "30px" }}>
                <ImCross className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;Not allowed in basic plan
              </span>

              <span style={{ marginLeft: "30px" }}>
                <ImCross className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;Not allowed in basic plan
              </span>
            </div>
          </div>
          <Button
            className="subscribe-btn"
            value="Subscribe"
            handleClick={() => handleSubscribe()}
          />
        </div>
        {/* Column of premium subscription and pricing */}
        <div className="pricing-colum">
          <h4 style={{ fontStyle: "italic" }}>Premium</h4>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h4>{planPremiumPrice?.toFixed(2)}</h4>
            <h6 style={{ marginTop: "5px" }}>/month</h6>
          </div>
          <div className="Price_Icons">
            <div className="icons">
              <p
                className="pricing-icons"
                id="1"
                onClick={() => handleChange("PremiumRupees")}
              >
                <BiRupee />
              </p>
              <p
                className="pricing-icons"
                id="2"
                onClick={() => handleChange("PremiumDollar")}
              >
                <BiDollar />
              </p>
              <p
                className="pricing-icons"
                id="3"
                onClick={() => handleChange("PremiumEuro")}
              >
                <BiEuro />
              </p>
            </div>
          </div>
          <div className="content">
            <div className="column-content-3">
              <span className="subscription-plans">
                <ImCheckmark className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;allowed in premium plan
              </span>

              <span>
                <ImCheckmark className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;allowed in premium plan
              </span>

              <span>
                <ImCheckmark className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;allowed in premium plan
              </span>

              <span>
                <ImCheckmark className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;allowed in premium plan
              </span>
              <span>
                <ImCheckmark className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;allowed in premium plan
              </span>

              <span>
                <ImCheckmark className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;allowed in premium plan
              </span>

              <span>
                <ImCheckmark className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;allowed in premium plan
              </span>
              <span>
                <ImCheckmark className="check-uncheck" />
                &nbsp;&nbsp;&nbsp;allowed in premium plan
              </span>
            </div>
          </div>
          <Button
            className="subscribe-btn"
            value="Subscribe"
            handleClick={() => handleSubscribe()}
            // handleClick={function (
            //   event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            // ): void {
            //   throw new Error("Function not implemented.");
            // }}
          />
        </div>
      </div>
    </div>
  );
};
