//Sachin Dhalani - [9/3/2023] - banner page with sample text and button
//hetan Patil - [13/3/2023] - banner page with animated gif of success
import React from "react";
import Developers from "../../Assets/developers.png";
import ChooseImage from "../../Assets/choose-image.png";
import Gif from "../../Assets/employee-gif.gif"
import Users from "../../Assets/users.png";
import { SAMPLE_TEXT, WORK_HEADING } from "../Constants/constants";
import "./style.css";
export const Work: React.FC = () => {
  const workInfoData = [
    {
      image: Developers,
      title: "10K Developers",
      text: "Lorem ipsum dolor sit amet consectetur. Maecenas orci et sagittis duis elementum interdum facilisi bibendum.",
    },
    {
      image: ChooseImage,
      title: "4K Agencies",
      text: "Lorem ipsum dolor sit amet consectetur. Maecenas orci et ",
    },
    {
      image: Users,
      title: "80K+ Users",
      text: "Lorem ipsum dolor sit amet consectetur. Maecenas orci et lorem ipsum",
    },
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Work</p>
        <h1 className="primary-heading">{WORK_HEADING}</h1>
        <p className="primary-text">{SAMPLE_TEXT}</p>
      </div>
      {/* <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="work-icons" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div> */}
      <div className="gif-div">
        <img className="gif" src={Gif} alt="Gif"/>
      </div>
    </div>
  );
};
