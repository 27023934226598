import axios from "axios";
import { API_URL } from "./serverConnection";
import {
  CANDIDATE_INVITE,
  CANDIDATE_INVITE_ERROR,
  CANDIDATE_PREVIOUS_ASSESSMENT_ERROR,
  CANDIDATE_PREVIOUS_ASSESSMENT_SUCCESS,
  DISPLAY_CANDIDATE_SELECTED_OPTION,
  DISPLAY_CANDIDATE_SELECTED_OPTION_ERROR,
  START_LOADING,
  STOP_LOADING,
} from "./Types";
import Swal from "sweetalert2";
import { AppDispatch, RootState } from "../redux/store";
export const sendInvitation =
  (body: any) => async (dispatch: AppDispatch, getState: RootState) => {
    const { token } = getState().user;

    try {
      dispatch({ type: START_LOADING });
      const result = await axios.post(
        `${API_URL}/exam/exam/candidate-invitation`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch({
        type: CANDIDATE_INVITE,
        payload: result?.data,
      });
      Swal.fire({
        icon: "success",
        title: "Exam Sent",
        timer: 1500,
      });

      dispatch({ type: STOP_LOADING });
    } catch (err) {
      dispatch({
        type: CANDIDATE_INVITE_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
      Swal.fire({
        icon: "error",
        title: "Oops! Something went wrong",
        text: (err as any).response?.data?.message,
      });
    }
  };


  export const displayCandidatesPreviousAssessments =
  () => async (dispatch: AppDispatch, getState: RootState) => {
    try {
      const { token } = getState().user;
      const result = await axios.get(
        `${API_URL}/exam/candidate/exams`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch({
        type: CANDIDATE_PREVIOUS_ASSESSMENT_SUCCESS,
        payload: result?.data,
      });
    } catch (err) {
      dispatch({
        type: CANDIDATE_PREVIOUS_ASSESSMENT_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
    }
  };

  export const displayCandidatesSelectedOption =
  (id:any) => async (dispatch: AppDispatch, getState: RootState) => {
    try {
      const { token } = getState().user;
      const result = await axios.get(
        `${API_URL}/exam/getCandidateAnswer/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
        );
        dispatch({
          type: DISPLAY_CANDIDATE_SELECTED_OPTION,
          payload: result?.data,
        });

    } catch (err) {
      dispatch({
        type: DISPLAY_CANDIDATE_SELECTED_OPTION_ERROR,
        payload:
          (err as any)?.response?.data?.message ||
          "Unable to fetch records at the moment. Please Try Again.",
      });
    }
  };



